﻿/// <reference path="../entries/main">
.advertisement-block {
    padding: $gap-sm;

    @include media-min(large) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: $gap;
        padding: $gap $gap-sm;
    }
    @include media(small) {
        padding: $space;
    }

    &:has(.advertisement-block-advertisement + .advertisement-block-advertisement) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: $gap;

        @include media(medium) {
            gap: $gap-sm;
        }
    }

    &-advertisement {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        p {
            width: 100%;
        }

        > div {
            max-width: 100%;
        }

        iframe {
            max-width: 100%;
        }

        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }
}
.sidebar-ad-slot {
    > div {
        margin-inline: auto;
        display: flex;
        flex-flow: row wrap;
        gap: $gap-sm;
        padding-block: $gap-sm;

        @include media(medium) {
            justify-content: center;
        }
    }
}