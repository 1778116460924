@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html,
body {
  height: 100%;
  font-size: 100%;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style-type: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select {
  vertical-align: middle;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

div#lhnHocButton div.lhnHocChatTxtCont, .header-search-button .search-button-text, .visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@media (max-width: 640px) {
  .visually-hidden-mobile {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }
}

.header + * {
  margin-top: 1em;
}

.rich-text .preheader + * {
  margin-top: 0.325em;
}

.ad-carousel-container-block .swiper-navigation-container, .media-carousel-container-block .swiper-navigation-container, .main-content > .row > .wysiwyg-block, .main-content > .row > form, .tabs-panel > .wysiwyg-block, .tabs-panel > form, .content-detail-page-main-content > .wysiwyg-block, .content-detail-page-main-content > form, .event-detail-top-content-area > .wysiwyg-block, .event-detail-top-content-area > form, .event-detail-bottom-content-area > .wysiwyg-block, .event-detail-bottom-content-area > form, .article-detail-content-bottom > .row > .wysiwyg-block, .article-detail-content-bottom > .row > form, .article-detail-content-top > .row > .wysiwyg-block, .article-detail-content-top > .row > form, .directory-top-content > .wysiwyg-block, .directory-top-content > form, .directory-bottom-content > .wysiwyg-block, .directory-bottom-content > form, .content-container, .header-mega-nav-block-content-wrapper, .header-mega-nav, .sister-nav-links, .header-main {
  padding-inline: 7.5rem;
}
@media screen and (max-width: 1280px) {
  .ad-carousel-container-block .swiper-navigation-container, .media-carousel-container-block .swiper-navigation-container, .main-content > .row > .wysiwyg-block, .main-content > .row > form, .tabs-panel > .wysiwyg-block, .tabs-panel > form, .content-detail-page-main-content > .wysiwyg-block, .content-detail-page-main-content > form, .event-detail-top-content-area > .wysiwyg-block, .event-detail-top-content-area > form, .event-detail-bottom-content-area > .wysiwyg-block, .event-detail-bottom-content-area > form, .article-detail-content-bottom > .row > .wysiwyg-block, .article-detail-content-bottom > .row > form, .article-detail-content-top > .row > .wysiwyg-block, .article-detail-content-top > .row > form, .directory-top-content > .wysiwyg-block, .directory-top-content > form, .directory-bottom-content > .wysiwyg-block, .directory-bottom-content > form, .content-container, .header-mega-nav-block-content-wrapper, .header-mega-nav, .sister-nav-links, .header-main {
    padding-inline: 4rem;
  }
}
@media screen and (max-width: 1024px) {
  .ad-carousel-container-block .swiper-navigation-container, .media-carousel-container-block .swiper-navigation-container, .main-content > .row > .wysiwyg-block, .main-content > .row > form, .tabs-panel > .wysiwyg-block, .tabs-panel > form, .content-detail-page-main-content > .wysiwyg-block, .content-detail-page-main-content > form, .event-detail-top-content-area > .wysiwyg-block, .event-detail-top-content-area > form, .event-detail-bottom-content-area > .wysiwyg-block, .event-detail-bottom-content-area > form, .article-detail-content-bottom > .row > .wysiwyg-block, .article-detail-content-bottom > .row > form, .article-detail-content-top > .row > .wysiwyg-block, .article-detail-content-top > .row > form, .directory-top-content > .wysiwyg-block, .directory-top-content > form, .directory-bottom-content > .wysiwyg-block, .directory-bottom-content > form, .content-container, .header-mega-nav-block-content-wrapper, .header-mega-nav, .sister-nav-links, .header-main {
    padding-inline: 2.5rem;
  }
}
@media screen and (max-width: 640px) {
  .ad-carousel-container-block .swiper-navigation-container, .media-carousel-container-block .swiper-navigation-container, .main-content > .row > .wysiwyg-block, .main-content > .row > form, .tabs-panel > .wysiwyg-block, .tabs-panel > form, .content-detail-page-main-content > .wysiwyg-block, .content-detail-page-main-content > form, .event-detail-top-content-area > .wysiwyg-block, .event-detail-top-content-area > form, .event-detail-bottom-content-area > .wysiwyg-block, .event-detail-bottom-content-area > form, .article-detail-content-bottom > .row > .wysiwyg-block, .article-detail-content-bottom > .row > form, .article-detail-content-top > .row > .wysiwyg-block, .article-detail-content-top > .row > form, .directory-top-content > .wysiwyg-block, .directory-top-content > form, .directory-bottom-content > .wysiwyg-block, .directory-bottom-content > form, .content-container, .header-mega-nav-block-content-wrapper, .header-mega-nav, .sister-nav-links, .header-main {
    padding-inline: 1rem;
  }
}

.ad-carousel-container-block .swiper-navigation-container, .media-carousel-container-block .swiper-navigation-container, .main-content > .row > .wysiwyg-block, .main-content > .row > form, .tabs-panel > .wysiwyg-block, .tabs-panel > form, .content-detail-page-main-content > .wysiwyg-block, .content-detail-page-main-content > form, .event-detail-top-content-area > .wysiwyg-block, .event-detail-top-content-area > form, .event-detail-bottom-content-area > .wysiwyg-block, .event-detail-bottom-content-area > form, .article-detail-content-bottom > .row > .wysiwyg-block, .article-detail-content-bottom > .row > form, .article-detail-content-top > .row > .wysiwyg-block, .article-detail-content-top > .row > form, .directory-top-content > .wysiwyg-block, .directory-top-content > form, .directory-bottom-content > .wysiwyg-block, .directory-bottom-content > form, .content-container, .header-mega-nav-block-content-wrapper, .header-mega-nav, .sister-nav-links, .header-main {
  max-width: 122.5rem;
  margin-inline: auto;
}

.btn-remove, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a, .media-heading-text-links .secondary a, .btn-light, .btn-link, .btn-outline, .event-calendar-cta a, .event-detail-subnav-cta a, .title-block-cta a, .interstitial-block .modal-close:not(.icon-link), table a, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a, .media-heading-text-links .primary a, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh, .EPiServerForms .Form__Element[type=reset], .EPiServerForms .Form__Element[type=submit], submit, input[type=submit], .btn-light, .btn,
.btn-primary {
  font-size: 1.125rem;
  line-height: 1.22222;
  font-size: var(--button-text);
  line-height: var(--button-text-height);
  display: inline-block;
  padding: 0.875rem 1.125rem 0.75rem;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  border-style: solid;
  border: none;
}
[disabled].btn-remove, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled], .media-heading-text-links .secondary a[disabled], [disabled].btn-light, [disabled].btn-link, [disabled].btn-outline, .event-calendar-cta a[disabled], .event-detail-subnav-cta a[disabled], .title-block-cta a[disabled], .interstitial-block [disabled].modal-close:not(.icon-link), table a[disabled], .hero-banner-block[data-image-hero] .media-heading-text-links .primary a[disabled], .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a[disabled], .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a[disabled], .media-heading-text-links .primary a[disabled], .EPiServerForms .FormRange .FormRange__Wrapper [disabled].FormRange__Slider__Button, .EPiServerForms nav.Form__NavigationBar [disabled].Form__NavigationBar__Action, .EPiServerForms .Form__Element .FormCaptcha [disabled].FormCaptcha__Refresh, .EPiServerForms [disabled].Form__Element[type=reset], .EPiServerForms [disabled].Form__Element[type=submit], submit[disabled], input[disabled][type=submit], [disabled].btn,
[disabled].btn-primary, .disabled.btn-remove, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled, .media-heading-text-links .secondary a.disabled, .disabled.btn-light, .disabled.btn-link, .disabled.btn-outline, .event-calendar-cta a.disabled, .event-detail-subnav-cta a.disabled, .title-block-cta a.disabled, .interstitial-block .disabled.modal-close:not(.icon-link), table a.disabled, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a.disabled, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a.disabled, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a.disabled, .media-heading-text-links .primary a.disabled, .EPiServerForms .FormRange .FormRange__Wrapper .disabled.FormRange__Slider__Button, .EPiServerForms nav.Form__NavigationBar .disabled.Form__NavigationBar__Action, .EPiServerForms .Form__Element .FormCaptcha .disabled.FormCaptcha__Refresh, .EPiServerForms .disabled.Form__Element[type=reset], .EPiServerForms .disabled.Form__Element[type=submit], submit.disabled, input.disabled[type=submit], .disabled.btn,
.disabled.btn-primary {
  cursor: not-allowed;
}
.btn-remove .svg-icon, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a .svg-icon, .media-heading-text-links .secondary a .svg-icon, .btn-light .svg-icon, .btn-link .svg-icon, .btn-outline .svg-icon, .event-calendar-cta a .svg-icon, .event-detail-subnav-cta a .svg-icon, .title-block-cta a .svg-icon, .interstitial-block .modal-close:not(.icon-link) .svg-icon, table a .svg-icon, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a .svg-icon, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a .svg-icon, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a .svg-icon, .media-heading-text-links .primary a .svg-icon, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button .svg-icon, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action .svg-icon, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh .svg-icon, .EPiServerForms .Form__Element[type=reset] .svg-icon, .EPiServerForms .Form__Element[type=submit] .svg-icon, submit .svg-icon, input[type=submit] .svg-icon, .btn .svg-icon,
.btn-primary .svg-icon {
  margin-left: 0.5rem;
}

.event-calendar-cta a, .event-detail-subnav-cta a, .title-block-cta a, .interstitial-block .modal-close:not(.icon-link), table a, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a, .media-heading-text-links .primary a, .media-heading-text-links .secondary a, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh, .EPiServerForms .Form__Element[type=reset], .EPiServerForms .Form__Element[type=submit], submit, input[type=submit], .btn-light, .btn,
.btn-primary {
  font-weight: 700;
  background-color: var(--button-primary-background);
  color: var(--button-primary-foreground);
  position: relative;
  z-index: 1;
}
.event-calendar-cta a:after, .event-detail-subnav-cta a:after, .title-block-cta a:after, .interstitial-block .modal-close:not(.icon-link):after, table a:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:after, .media-heading-text-links .primary a:after, .media-heading-text-links .secondary a:after, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:after, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:after, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:after, .EPiServerForms .Form__Element[type=reset]:after, .EPiServerForms .Form__Element[type=submit]:after, submit:after, input[type=submit]:after, .btn-light:after, .btn:after,
.btn-primary:after {
  background-color: var(--button-primary-background);
}
.event-calendar-cta a svg, .event-detail-subnav-cta a svg, .title-block-cta a svg, .interstitial-block .modal-close:not(.icon-link) svg, table a svg, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a svg, .media-heading-text-links .primary a svg, .media-heading-text-links .secondary a svg, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button svg, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action svg, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh svg, .EPiServerForms .Form__Element[type=reset] svg, .EPiServerForms .Form__Element[type=submit] svg, submit svg, input[type=submit] svg, .btn-light svg, .btn svg,
.btn-primary svg {
  fill: var(--button-primary-foreground);
}
.event-calendar-cta a:hover, .event-detail-subnav-cta a:hover, .title-block-cta a:hover, .interstitial-block .modal-close:hover:not(.icon-link), table a:hover, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:hover, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:hover, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:hover, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:hover, .media-heading-text-links .primary a:hover, .media-heading-text-links .secondary a:hover, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:hover, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:hover, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:hover, .EPiServerForms .Form__Element[type=reset]:hover, .EPiServerForms .Form__Element[type=submit]:hover, submit:hover, input[type=submit]:hover, .btn-light:hover, .btn:hover,
.btn-primary:hover, .event-calendar-cta a:active, .event-detail-subnav-cta a:active, .title-block-cta a:active, .interstitial-block .modal-close:active:not(.icon-link), table a:active, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:active, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:active, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:active, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:active, .media-heading-text-links .primary a:active, .media-heading-text-links .secondary a:active, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:active, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:active, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:active, .EPiServerForms .Form__Element[type=reset]:active, .EPiServerForms .Form__Element[type=submit]:active, submit:active, input[type=submit]:active, .btn-light:active, .btn:active,
.btn-primary:active {
  background-color: var(--button-primary-background);
  color: var(--button-primary-foreground);
}
.event-calendar-cta a:hover svg, .event-detail-subnav-cta a:hover svg, .title-block-cta a:hover svg, .interstitial-block .modal-close:hover:not(.icon-link) svg, table a:hover svg, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:hover svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:hover svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:hover svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:hover svg, .media-heading-text-links .primary a:hover svg, .media-heading-text-links .secondary a:hover svg, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:hover svg, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:hover svg, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:hover svg, .EPiServerForms .Form__Element[type=reset]:hover svg, .EPiServerForms .Form__Element[type=submit]:hover svg, submit:hover svg, input[type=submit]:hover svg, .btn-light:hover svg, .btn:hover svg,
.btn-primary:hover svg, .event-calendar-cta a:active svg, .event-detail-subnav-cta a:active svg, .title-block-cta a:active svg, .interstitial-block .modal-close:active:not(.icon-link) svg, table a:active svg, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:active svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:active svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:active svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:active svg, .media-heading-text-links .primary a:active svg, .media-heading-text-links .secondary a:active svg, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:active svg, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:active svg, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:active svg, .EPiServerForms .Form__Element[type=reset]:active svg, .EPiServerForms .Form__Element[type=submit]:active svg, submit:active svg, input[type=submit]:active svg, .btn-light:active svg, .btn:active svg,
.btn-primary:active svg {
  fill: var(--button-primary-foreground);
}
.event-calendar-cta a:focus, .event-detail-subnav-cta a:focus, .title-block-cta a:focus, .interstitial-block .modal-close:focus:not(.icon-link), table a:focus, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:focus, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:focus, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:focus, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:focus, .media-heading-text-links .primary a:focus, .media-heading-text-links .secondary a:focus, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:focus, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:focus, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:focus, .EPiServerForms .Form__Element[type=reset]:focus, .EPiServerForms .Form__Element[type=submit]:focus, submit:focus, input[type=submit]:focus, .btn-light:focus, .btn:focus,
.btn-primary:focus {
  background-color: var(--button-primary-background);
  color: var(--button-primary-foreground);
}
.event-calendar-cta a:focus svg, .event-detail-subnav-cta a:focus svg, .title-block-cta a:focus svg, .interstitial-block .modal-close:focus:not(.icon-link) svg, table a:focus svg, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:focus svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:focus svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:focus svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:focus svg, .media-heading-text-links .primary a:focus svg, .media-heading-text-links .secondary a:focus svg, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:focus svg, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:focus svg, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:focus svg, .EPiServerForms .Form__Element[type=reset]:focus svg, .EPiServerForms .Form__Element[type=submit]:focus svg, submit:focus svg, input[type=submit]:focus svg, .btn-light:focus svg, .btn:focus svg,
.btn-primary:focus svg {
  fill: var(--button-primary-foreground);
}
.event-calendar-cta a[disabled], .event-detail-subnav-cta a[disabled], .title-block-cta a[disabled], .interstitial-block [disabled].modal-close:not(.icon-link), table a[disabled], .hero-banner-block[data-image-hero] .media-heading-text-links .primary a[disabled], .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled], .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a[disabled], .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a[disabled], .media-heading-text-links .primary a[disabled], .media-heading-text-links .secondary a[disabled], .EPiServerForms .FormRange .FormRange__Wrapper [disabled].FormRange__Slider__Button, .EPiServerForms nav.Form__NavigationBar [disabled].Form__NavigationBar__Action, .EPiServerForms .Form__Element .FormCaptcha [disabled].FormCaptcha__Refresh, .EPiServerForms [disabled].Form__Element[type=reset], .EPiServerForms [disabled].Form__Element[type=submit], submit[disabled], input[disabled][type=submit], [disabled].btn-light, [disabled].btn,
[disabled].btn-primary, .event-calendar-cta a.disabled, .event-detail-subnav-cta a.disabled, .title-block-cta a.disabled, .interstitial-block .disabled.modal-close:not(.icon-link), table a.disabled, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a.disabled, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a.disabled, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a.disabled, .media-heading-text-links .primary a.disabled, .media-heading-text-links .secondary a.disabled, .EPiServerForms .FormRange .FormRange__Wrapper .disabled.FormRange__Slider__Button, .EPiServerForms nav.Form__NavigationBar .disabled.Form__NavigationBar__Action, .EPiServerForms .Form__Element .FormCaptcha .disabled.FormCaptcha__Refresh, .EPiServerForms .disabled.Form__Element[type=reset], .EPiServerForms .disabled.Form__Element[type=submit], submit.disabled, input.disabled[type=submit], .disabled.btn-light, .disabled.btn,
.disabled.btn-primary {
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-foreground);
  box-shadow: none;
}
.event-calendar-cta a[disabled] svg, .event-detail-subnav-cta a[disabled] svg, .title-block-cta a[disabled] svg, .interstitial-block [disabled].modal-close:not(.icon-link) svg, table a[disabled] svg, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a[disabled] svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled] svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a[disabled] svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a[disabled] svg, .media-heading-text-links .primary a[disabled] svg, .media-heading-text-links .secondary a[disabled] svg, .EPiServerForms .FormRange .FormRange__Wrapper [disabled].FormRange__Slider__Button svg, .EPiServerForms nav.Form__NavigationBar [disabled].Form__NavigationBar__Action svg, .EPiServerForms .Form__Element .FormCaptcha [disabled].FormCaptcha__Refresh svg, .EPiServerForms [disabled].Form__Element[type=reset] svg, .EPiServerForms [disabled].Form__Element[type=submit] svg, submit[disabled] svg, input[disabled][type=submit] svg, [disabled].btn-light svg, [disabled].btn svg,
[disabled].btn-primary svg, .event-calendar-cta a.disabled svg, .event-detail-subnav-cta a.disabled svg, .title-block-cta a.disabled svg, .interstitial-block .disabled.modal-close:not(.icon-link) svg, table a.disabled svg, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a.disabled svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a.disabled svg, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a.disabled svg, .media-heading-text-links .primary a.disabled svg, .media-heading-text-links .secondary a.disabled svg, .EPiServerForms .FormRange .FormRange__Wrapper .disabled.FormRange__Slider__Button svg, .EPiServerForms nav.Form__NavigationBar .disabled.Form__NavigationBar__Action svg, .EPiServerForms .Form__Element .FormCaptcha .disabled.FormCaptcha__Refresh svg, .EPiServerForms .disabled.Form__Element[type=reset] svg, .EPiServerForms .disabled.Form__Element[type=submit] svg, submit.disabled svg, input.disabled[type=submit] svg, .disabled.btn-light svg, .disabled.btn svg,
.disabled.btn-primary svg {
  fill: var(--button-disabled-foreground);
}
.event-calendar-cta a[disabled]:hover, .event-detail-subnav-cta a[disabled]:hover, .title-block-cta a[disabled]:hover, .interstitial-block [disabled].modal-close:hover:not(.icon-link), table a[disabled]:hover, .media-heading-text-links .primary a[disabled]:hover, .media-heading-text-links .secondary a[disabled]:hover, .EPiServerForms .FormRange .FormRange__Wrapper [disabled].FormRange__Slider__Button:hover, .EPiServerForms nav.Form__NavigationBar [disabled].Form__NavigationBar__Action:hover, .EPiServerForms .Form__Element .FormCaptcha [disabled].FormCaptcha__Refresh:hover, .EPiServerForms [disabled].Form__Element[type=reset]:hover, .EPiServerForms [disabled].Form__Element[type=submit]:hover, submit[disabled]:hover, input[disabled][type=submit]:hover, [disabled].btn-light:hover, [disabled].btn:hover,
[disabled].btn-primary:hover, .event-calendar-cta a.disabled:hover, .event-detail-subnav-cta a.disabled:hover, .title-block-cta a.disabled:hover, .interstitial-block .disabled.modal-close:hover:not(.icon-link), table a.disabled:hover, .media-heading-text-links .primary a.disabled:hover, .media-heading-text-links .secondary a.disabled:hover, .EPiServerForms .FormRange .FormRange__Wrapper .disabled.FormRange__Slider__Button:hover, .EPiServerForms nav.Form__NavigationBar .disabled.Form__NavigationBar__Action:hover, .EPiServerForms .Form__Element .FormCaptcha .disabled.FormCaptcha__Refresh:hover, .EPiServerForms .disabled.Form__Element[type=reset]:hover, .EPiServerForms .disabled.Form__Element[type=submit]:hover, submit.disabled:hover, input.disabled[type=submit]:hover, .disabled.btn-light:hover, .disabled.btn:hover,
.disabled.btn-primary:hover {
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-foreground);
  cursor: not-allowed;
}
.event-calendar-cta a[disabled]:after, .event-detail-subnav-cta a[disabled]:after, .title-block-cta a[disabled]:after, .interstitial-block [disabled].modal-close:not(.icon-link):after, table a[disabled]:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a[disabled]:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled]:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a[disabled]:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a[disabled]:after, .media-heading-text-links .primary a[disabled]:after, .media-heading-text-links .secondary a[disabled]:after, .EPiServerForms .FormRange .FormRange__Wrapper [disabled].FormRange__Slider__Button:after, .EPiServerForms nav.Form__NavigationBar [disabled].Form__NavigationBar__Action:after, .EPiServerForms .Form__Element .FormCaptcha [disabled].FormCaptcha__Refresh:after, .EPiServerForms [disabled].Form__Element[type=reset]:after, .EPiServerForms [disabled].Form__Element[type=submit]:after, submit[disabled]:after, input[disabled][type=submit]:after, [disabled].btn-light:after, [disabled].btn:after,
[disabled].btn-primary:after, .event-calendar-cta a.disabled:after, .event-detail-subnav-cta a.disabled:after, .title-block-cta a.disabled:after, .interstitial-block .disabled.modal-close:not(.icon-link):after, table a.disabled:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a.disabled:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a.disabled:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a.disabled:after, .media-heading-text-links .primary a.disabled:after, .media-heading-text-links .secondary a.disabled:after, .EPiServerForms .FormRange .FormRange__Wrapper .disabled.FormRange__Slider__Button:after, .EPiServerForms nav.Form__NavigationBar .disabled.Form__NavigationBar__Action:after, .EPiServerForms .Form__Element .FormCaptcha .disabled.FormCaptcha__Refresh:after, .EPiServerForms .disabled.Form__Element[type=reset]:after, .EPiServerForms .disabled.Form__Element[type=submit]:after, submit.disabled:after, input.disabled[type=submit]:after, .disabled.btn-light:after, .disabled.btn:after,
.disabled.btn-primary:after {
  background-color: var(--button-disabled-background);
}
.event-calendar-cta a:after, .event-detail-subnav-cta a:after, .title-block-cta a:after, .interstitial-block .modal-close:not(.icon-link):after, table a:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:after, .media-heading-text-links .primary a:after, .media-heading-text-links .secondary a:after, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:after, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:after, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:after, .EPiServerForms .Form__Element[type=reset]:after, .EPiServerForms .Form__Element[type=submit]:after, submit:after, input[type=submit]:after, .btn-light:after, .btn:after,
.btn-primary:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 0.15s ease;
}
.event-calendar-cta a:hover:after, .event-detail-subnav-cta a:hover:after, .title-block-cta a:hover:after, .interstitial-block .modal-close:hover:not(.icon-link):after, table a:hover:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:hover:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:hover:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:hover:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:hover:after, .media-heading-text-links .primary a:hover:after, .media-heading-text-links .secondary a:hover:after, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:hover:after, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:hover:after, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:hover:after, .EPiServerForms .Form__Element[type=reset]:hover:after, .EPiServerForms .Form__Element[type=submit]:hover:after, submit:hover:after, input[type=submit]:hover:after, .btn-light:hover:after, .btn:hover:after,
.btn-primary:hover:after, .event-calendar-cta a:active:after, .event-detail-subnav-cta a:active:after, .title-block-cta a:active:after, .interstitial-block .modal-close:active:not(.icon-link):after, table a:active:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a:active:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:active:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a:active:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a:active:after, .media-heading-text-links .primary a:active:after, .media-heading-text-links .secondary a:active:after, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button:active:after, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action:active:after, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh:active:after, .EPiServerForms .Form__Element[type=reset]:active:after, .EPiServerForms .Form__Element[type=submit]:active:after, submit:active:after, input[type=submit]:active:after, .btn-light:active:after, .btn:active:after,
.btn-primary:active:after {
  top: -0.12rem;
  left: -0.25rem;
  right: -0.25rem;
  bottom: -0.12rem;
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.24rem);
}
.event-calendar-cta a[disabled]:after, .event-detail-subnav-cta a[disabled]:after, .title-block-cta a[disabled]:after, .interstitial-block [disabled].modal-close:not(.icon-link):after, table a[disabled]:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a[disabled]:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled]:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a[disabled]:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a[disabled]:after, .media-heading-text-links .primary a[disabled]:after, .media-heading-text-links .secondary a[disabled]:after, .EPiServerForms .FormRange .FormRange__Wrapper [disabled].FormRange__Slider__Button:after, .EPiServerForms nav.Form__NavigationBar [disabled].Form__NavigationBar__Action:after, .EPiServerForms .Form__Element .FormCaptcha [disabled].FormCaptcha__Refresh:after, .EPiServerForms [disabled].Form__Element[type=reset]:after, .EPiServerForms [disabled].Form__Element[type=submit]:after, submit[disabled]:after, input[disabled][type=submit]:after, [disabled].btn-light:after, [disabled].btn:after,
[disabled].btn-primary:after, .event-calendar-cta a.disabled:after, .event-detail-subnav-cta a.disabled:after, .title-block-cta a.disabled:after, .interstitial-block .disabled.modal-close:not(.icon-link):after, table a.disabled:after, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a.disabled:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a.disabled:after, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a.disabled:after, .media-heading-text-links .primary a.disabled:after, .media-heading-text-links .secondary a.disabled:after, .EPiServerForms .FormRange .FormRange__Wrapper .disabled.FormRange__Slider__Button:after, .EPiServerForms nav.Form__NavigationBar .disabled.Form__NavigationBar__Action:after, .EPiServerForms .Form__Element .FormCaptcha .disabled.FormCaptcha__Refresh:after, .EPiServerForms .disabled.Form__Element[type=reset]:after, .EPiServerForms .disabled.Form__Element[type=submit]:after, submit.disabled:after, input.disabled[type=submit]:after, .disabled.btn-light:after, .disabled.btn:after,
.disabled.btn-primary:after {
  display: none;
}
.btn-primary-highlight {
  background-color: var(--button-highlight-background);
  color: var(--button-highlight-foreground);
}
.btn-primary-highlight:after {
  background-color: var(--button-highlight-background);
}
.btn-primary-highlight svg {
  fill: var(--button-highlight-foreground);
}
.btn-primary-highlight:hover, .btn-primary-highlight:active {
  background-color: var(--button-highlight-background);
  color: var(--button-highlight-foreground);
}
.btn-primary-highlight:hover svg, .btn-primary-highlight:active svg {
  fill: var(--button-highlight-foreground);
}
.btn-primary-highlight:focus {
  background-color: var(--button-highlight-background);
  color: var(--button-highlight-foreground);
}
.btn-primary-highlight:focus svg {
  fill: var(--button-highlight-foreground);
}
[disabled].btn-primary-highlight, .disabled.btn-primary-highlight {
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-foreground);
  box-shadow: none;
}
[disabled].btn-primary-highlight svg, .disabled.btn-primary-highlight svg {
  fill: var(--button-disabled-foreground);
}
[disabled].btn-primary-highlight:hover, .disabled.btn-primary-highlight:hover {
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-foreground);
  cursor: not-allowed;
}
[disabled].btn-primary-highlight:after, .disabled.btn-primary-highlight:after {
  background-color: var(--button-disabled-background);
}
.event-calendar-cta a .ph-arrow-right, .event-detail-subnav-cta a .ph-arrow-right, .title-block-cta a .ph-arrow-right, .interstitial-block .modal-close:not(.icon-link) .ph-arrow-right, table a .ph-arrow-right, .hero-banner-block[data-image-hero] .media-heading-text-links .primary a .ph-arrow-right, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a .ph-arrow-right, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a .ph-arrow-right, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a .ph-arrow-right, .media-heading-text-links .primary a .ph-arrow-right, .media-heading-text-links .secondary a .ph-arrow-right, .EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button .ph-arrow-right, .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action .ph-arrow-right, .EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh .ph-arrow-right, .EPiServerForms .Form__Element[type=reset] .ph-arrow-right, .EPiServerForms .Form__Element[type=submit] .ph-arrow-right, submit .ph-arrow-right, input[type=submit] .ph-arrow-right, .btn-light .ph-arrow-right, .btn .ph-arrow-right,
.btn-primary .ph-arrow-right {
  top: 2px;
  position: relative;
}

.btn-outline {
  border-width: 1px;
  border-style: solid;
  border-color: var(--foreground);
  background-color: var(--background-color);
  color: var(--foreground);
}
.btn-outline:hover, .btn-outline:active, .btn-outline:focus {
  background-color: var(--background-color);
  border-color: var(--button-primary-background);
  color: var(--foreground);
}
[disabled].btn-outline, .disabled.btn-outline {
  border-color: var(--button-disabled-background);
  color: var(--button-disabled-foreground);
  box-shadow: none;
}
[disabled].btn-outline svg, .disabled.btn-outline svg {
  fill: var(--button-disabled-foreground);
}
[disabled].btn-outline:hover, .disabled.btn-outline:hover {
  cursor: not-allowed;
}

.btn-small {
  font-size: 1rem;
  line-height: 1.22222;
  font-size: var(--small-button-text);
  line-height: var(--small-button-text-height);
  padding: 0.625rem 0.875rem;
}

.btn-link {
  background-color: transparent;
}

.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a, .media-heading-text-links .secondary a, .btn-light {
  background-color: var(--button-tertiary-background);
  color: var(--button-tertiary-foreground);
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:after, .media-heading-text-links .secondary a:after, .btn-light:after {
  background-color: var(--button-tertiary-background);
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a svg, .media-heading-text-links .secondary a svg, .btn-light svg {
  fill: var(--button-tertiary-foreground);
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:hover, .media-heading-text-links .secondary a:hover, .btn-light:hover, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:active, .media-heading-text-links .secondary a:active, .btn-light:active {
  background-color: var(--button-tertiary-background);
  color: var(--button-tertiary-foreground);
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:hover svg, .media-heading-text-links .secondary a:hover svg, .btn-light:hover svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:active svg, .media-heading-text-links .secondary a:active svg, .btn-light:active svg {
  fill: var(--button-tertiary-foreground);
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:focus, .media-heading-text-links .secondary a:focus, .btn-light:focus {
  background-color: var(--button-tertiary-background);
  color: var(--button-tertiary-foreground);
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a:focus svg, .media-heading-text-links .secondary a:focus svg, .btn-light:focus svg {
  fill: var(--button-tertiary-foreground);
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled], .media-heading-text-links .secondary a[disabled], [disabled].btn-light, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled, .media-heading-text-links .secondary a.disabled, .disabled.btn-light {
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-foreground);
  box-shadow: none;
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled] svg, .media-heading-text-links .secondary a[disabled] svg, [disabled].btn-light svg, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled svg, .media-heading-text-links .secondary a.disabled svg, .disabled.btn-light svg {
  fill: var(--button-disabled-foreground);
}
.media-heading-text-links .secondary a[disabled]:hover, [disabled].btn-light:hover, .media-heading-text-links .secondary a.disabled:hover, .disabled.btn-light:hover {
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-foreground);
  cursor: not-allowed;
}
.hero-banner-block[data-image-hero] .media-heading-text-links .secondary a[disabled]:after, .media-heading-text-links .secondary a[disabled]:after, [disabled].btn-light:after, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a.disabled:after, .media-heading-text-links .secondary a.disabled:after, .disabled.btn-light:after {
  background-color: var(--button-disabled-background);
}

@media (max-width: 640px) {
  .btn-remove {
    border-radius: 9999em;
    width: var(--square-button-size);
    height: var(--square-button-size);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-remove:focus {
    box-shadow: #fff 0 0 0 2px, #000711 0 0 0 4px;
    box-shadow: var(--background-color, #fff) 0 0 0 2px, var(--focus-ring-color, #000711) 0 0 0 4px;
    outline-color: transparent;
  }
  .btn-remove span {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }
  .btn-remove .svg-icon {
    margin: 0;
  }
}

.article-detail-social a, .login-page-error-close, .tabs-list-tab, .accordion-trigger, .tour-element button, .un-button, .hero-banner-vimeo-controls button, .audio-player-container button {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline;
  text-align: left;
}
.article-detail-social a:hover, .login-page-error-close:hover, .tabs-list-tab:hover, .accordion-trigger:hover, .tour-element button:hover, .un-button:hover, .hero-banner-vimeo-controls button:hover, .audio-player-container button:hover, .article-detail-social a:focus, .login-page-error-close:focus, .tabs-list-tab:focus, .accordion-trigger:focus, .tour-element button:focus, .un-button:focus, .hero-banner-vimeo-controls button:focus, .audio-player-container button:focus {
  background-color: transparent;
}
.article-detail-social a[disabled], [disabled].login-page-error-close, [disabled].tabs-list-tab, [disabled].accordion-trigger, .tour-element button[disabled], [disabled].un-button, .hero-banner-vimeo-controls button[disabled], .audio-player-container button[disabled], .article-detail-social a[aria-disabled=true], [aria-disabled=true].login-page-error-close, [aria-disabled=true].tabs-list-tab, [aria-disabled=true].accordion-trigger, .tour-element button[aria-disabled=true], [aria-disabled=true].un-button, .hero-banner-vimeo-controls button[aria-disabled=true], .audio-player-container button[aria-disabled=true] {
  background-color: transparent;
  color: var(--square-button-size);
  cursor: not-allowed;
}

.my-news-category-card-header > a:not(:has(i)):after, .modal-inner-survey .interstitial-block-close .modal-close:not(:has(i)):after, .download-block-button a:not(:has(i)):after, .content-highlight-block-item-link a:not(:has(i)):after, .icon-link:not(:has(i)):after, .btn-icon:not(:has(i)):after, .btn-secondary:not(:has(i)):after, .my-news-category-card-header > a i, .modal-inner-survey .interstitial-block-close .modal-close i, .download-block-button a i, .content-highlight-block-item-link a i, .icon-link i, .btn-icon i, .btn-secondary i {
  background-color: var(--background);
  border: none;
  color: var(--foreground);
  border-style: none;
  font-size: 1.375rem;
  vertical-align: middle;
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  transition: all 0.1s ease;
  position: absolute;
  right: 0.25rem;
  bottom: 0;
  font-weight: 400;
}
.my-news-category-card-header > a i:before, .modal-inner-survey .interstitial-block-close .modal-close i:before, .download-block-button a i:before, .content-highlight-block-item-link a i:before, .icon-link i:before, .btn-icon i:before, .btn-secondary i:before {
  display: inline-block;
  text-decoration: none;
}

.my-news-category-card-header > a:hover i, .modal-inner-survey .interstitial-block-close .modal-close:hover i, .download-block-button a:hover i, .content-highlight-block-item-link a:hover i, .icon-link:hover i, .btn-icon:hover i, .btn-secondary:hover i, .my-news-category-card-header > a:focus i, .modal-inner-survey .interstitial-block-close .modal-close:focus i, .download-block-button a:focus i, .content-highlight-block-item-link a:focus i, .icon-link:focus i, .btn-icon:focus i, .btn-secondary:focus i, .my-news-category-card-header > a:not(:has(i)):hover:after, .modal-inner-survey .interstitial-block-close .modal-close:not(:has(i)):hover:after, .download-block-button a:not(:has(i)):hover:after, .content-highlight-block-item-link a:not(:has(i)):hover:after, .icon-link:not(:has(i)):hover:after, .btn-icon:not(:has(i)):hover:after, .btn-secondary:not(:has(i)):hover:after, .my-news-category-card-header > a:not(:has(i)):focus:after, .modal-inner-survey .interstitial-block-close .modal-close:not(:has(i)):focus:after, .download-block-button a:not(:has(i)):focus:after, .content-highlight-block-item-link a:not(:has(i)):focus:after, .icon-link:not(:has(i)):focus:after, .btn-icon:not(:has(i)):focus:after, .btn-secondary:not(:has(i)):focus:after {
  right: 0.125rem;
  bottom: 0;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
}

.my-news-category-card-header > a, .modal-inner-survey .interstitial-block-close .modal-close, .download-block-button a, .content-highlight-block-item-link a, .icon-link, .btn-icon, .btn-secondary {
  --background: var(--button-secondary-background);
  --foreground: var(--button-secondary-icon);
  gap: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.22222;
  font-size: var(--button-text);
  line-height: var(--button-text-height);
  font-weight: 700;
  border: none;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 4px;
  text-decoration-color: var(--background);
  position: relative;
  padding-right: 2.5rem;
  display: inline-block;
  margin-top: 0.5rem;
}
.my-news-category-card-header > a:not(:has(i)):after, .modal-inner-survey .interstitial-block-close .modal-close:not(:has(i)):after, .download-block-button a:not(:has(i)):after, .content-highlight-block-item-link a:not(:has(i)):after, .icon-link:not(:has(i)):after, .btn-icon:not(:has(i)):after, .btn-secondary:not(:has(i)):after {
  font-family: "Phosphor" !important;
  content: "\e092";
}
.my-news-category-card-header > a:hover, .modal-inner-survey .interstitial-block-close .modal-close:hover, .download-block-button a:hover, .content-highlight-block-item-link a:hover, .icon-link:hover, .btn-icon:hover, .btn-secondary:hover, .my-news-category-card-header > a:focus, .modal-inner-survey .interstitial-block-close .modal-close:focus, .download-block-button a:focus, .content-highlight-block-item-link a:focus, .icon-link:focus, .btn-icon:focus, .btn-secondary:focus {
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 4px;
  text-decoration-color: var(--background);
}
.my-news-category-card-header > a[disabled], .modal-inner-survey .interstitial-block-close [disabled].modal-close, .download-block-button a[disabled], .content-highlight-block-item-link a[disabled], [disabled].icon-link, [disabled].btn-icon, [disabled].btn-secondary, .my-news-category-card-header > a[aria-disabled=true], .modal-inner-survey .interstitial-block-close [aria-disabled=true].modal-close, .download-block-button a[aria-disabled=true], .content-highlight-block-item-link a[aria-disabled=true], [aria-disabled=true].icon-link, [aria-disabled=true].btn-icon, [aria-disabled=true].btn-secondary {
  background-color: transparent;
  cursor: not-allowed;
}

body, .article-detail-social a, .login-page-error-close, .tabs-list-tab, .accordion-trigger, .tour-element button, .un-button, .hero-banner-vimeo-controls button, .audio-player-container button {
  font-size: 1rem;
  line-height: 1.375;
  font-size: var(--body-text);
  line-height: var(--body-text-height);
  font-family: "AcherusGrotesque", Arial, sans-serif;
  font-weight: 500;
  color: var(--foreground);
}
body ::selection, .article-detail-social a ::selection, .login-page-error-close ::selection, .tabs-list-tab ::selection, .accordion-trigger ::selection, .tour-element button ::selection, .un-button ::selection, .hero-banner-vimeo-controls button ::selection, .audio-player-container button ::selection {
  color: var(--foreground);
}
body ::-moz-selection, .article-detail-social a ::-moz-selection, .login-page-error-close ::-moz-selection, .tabs-list-tab ::-moz-selection, .accordion-trigger ::-moz-selection, .tour-element button ::-moz-selection, .un-button ::-moz-selection, .hero-banner-vimeo-controls button ::-moz-selection, .audio-player-container button ::-moz-selection {
  color: #fff;
}

h1,
.h1-style,
.counter-container-1 .counter-item .counter-block-value,
.counter-container-4 .highlighted .counter-block-value, h2,
.h2-style, h3,
.h3-style, .quote-block-item-text, h4,
.h4-style,
.survey-form-question, .article-detail-related-links a, h5,
.h5-style {
  font-family: "Jubilat", Arial, sans-serif;
}

.my-news-item-title, .my-news-preferences-page .alert-message, h6,
.h6-style, .survey-form-answer, .sister-nav-links, .header-search input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]), .large, .label-hint, .form-element-description, .small, body, .article-detail-social a, .login-page-error-close, .tabs-list-tab, .accordion-trigger, .tour-element button, .un-button, .hero-banner-vimeo-controls button, .audio-player-container button, .quick-nav-button, .mega-nav-highlight-block-cta, .utility-nav-link, .regular, .header-mega-nav-block-top-link, .header-mega-nav-block-link-item a, .sister-nav-link, .nav-link {
  font-family: "AcherusGrotesque", Arial, sans-serif;
}

h1,
.h1-style,
.counter-container-1 .counter-item .counter-block-value,
.counter-container-4 .highlighted .counter-block-value {
  font-size: 5.125rem;
  line-height: 0.97561;
  font-size: var(--x-large-text);
  line-height: var(--x-large-text-height);
  font-weight: 445;
}

h2,
.h2-style {
  font-size: 4.1875rem;
  line-height: 0.9207;
  font-size: var(--large-text);
  line-height: var(--large-text-height);
  font-weight: 445;
}

h3,
.h3-style {
  font-size: 3.1875rem;
  line-height: 1.0293;
  font-size: var(--medium-large-text);
  line-height: var(--medium-large-text-height);
  font-weight: 445;
}

.quote-block-item-text, h4,
.h4-style,
.survey-form-question {
  font-size: 2.375rem;
  line-height: 1.16095;
  font-size: var(--medium-text);
  line-height: var(--medium-text-height);
  font-weight: 355;
}

.article-detail-related-links a, h5,
.h5-style {
  font-size: 1.75rem;
  line-height: 1.05522;
  font-size: var(--regular-text);
  line-height: var(--regular-text-height);
  font-weight: 355;
}

.my-news-item-title, .my-news-preferences-page .alert-message, h6,
.h6-style {
  font-size: 1.3125rem;
  line-height: 1.12518;
  font-size: var(--small-text);
  line-height: var(--small-text-height);
  font-weight: 700;
}

blockquote {
  font-size: 1.5rem;
  line-height: 1.25;
  font-size: var(--quote-text);
  line-height: var(--quote-text-height);
  font-family: "Jubilat", Arial, sans-serif;
  font-weight: 355;
}

.survey-form-answer, .sister-nav-links, .header-search input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]), .large {
  font-size: 1.125rem;
  line-height: 1.33333;
  font-size: var(--large-regular-text);
  line-height: var(--large-regular-text-height);
}

.quick-nav-button, .mega-nav-highlight-block-cta, .utility-nav-link, .regular, .header-mega-nav-block-top-link, .header-mega-nav-block-link-item a, .sister-nav-link, .nav-link {
  font-size: 1rem;
  line-height: 1.375;
  font-size: var(--body-text);
  line-height: var(--body-text-height);
}

.label-hint, .form-element-description, .small {
  font-size: 0.875rem;
  line-height: 1.42857;
  font-size: var(--x-small-text);
  line-height: var(--x-small-text-height);
}

.hero-banner-block:has(.hero-banner-video-background) .chip-subtitle, .hero-banner-block:has(.hero-banner-image-background) .chip-subtitle, .subtitle {
  font-size: 0.875rem;
  line-height: 1.42857;
  font-size: var(--x-small-text);
  line-height: var(--x-small-text-height);
  font-family: "AcherusGrotesque", Arial, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}

.accent {
  font-size: 1rem;
  line-height: 1.375;
  font-size: var(--label-text);
  line-height: var(--label-text-height);
  font-family: "Jubilat", Arial, sans-serif;
}

.mega-nav-highlight-block-header, .header-mega-nav-link-set-title {
  font-family: "AcherusGrotesque", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  font-size: var(--body-text);
  line-height: var(--body-text-height);
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.68);
}

a,
.hyperlink, .btn-link {
  text-decoration: underline;
  color: currentColor;
}
a:hover,
.hyperlink:hover, .btn-link:hover, a:focus,
.hyperlink:focus, .btn-link:focus {
  text-decoration: none;
}

.my-news-item-content > a, .article-category-pill a, .footer-column-links a, .header-mega-nav-link-set-item a, .header-mega-nav-block-title > a, .link-reverse {
  text-decoration: none;
  color: currentColor;
}
.my-news-item-content > a:hover, .article-category-pill a:hover, .footer-column-links a:hover, .header-mega-nav-link-set-item a:hover, .header-mega-nav-block-title > a:hover, .my-news-item-content > a:focus, .article-category-pill a:focus, .footer-column-links a:focus, .header-mega-nav-link-set-item a:focus, .header-mega-nav-block-title > a:focus, .link-reverse:hover, .link-reverse:focus {
  text-decoration: underline;
}

.header-mega-nav-block-top-link, .header-mega-nav-block-link-item a, .sister-nav-link, .nav-link, .unlink {
  color: inherit;
  text-decoration: none;
}

.header-mega-nav-block-top-link, .header-mega-nav-block-link-item a, .sister-nav-link, .nav-link {
  font-weight: 700;
}
@media (min-width: 1500.1px) {
  .header-mega-nav-block-top-link, .header-mega-nav-block-link-item a, .sister-nav-link, .nav-link {
    font-size: 1.125rem;
    line-height: 1.33333;
    font-size: var(--large-regular-text);
    line-height: var(--large-regular-text-height);
  }
}
.header-mega-nav-block-top-link:hover, .header-mega-nav-block-link-item a:hover, .sister-nav-link:hover, .nav-link:hover {
  text-decoration: underline;
}
.header-mega-nav-block-top-link i, .header-mega-nav-block-link-item a i, .sister-nav-link i, .nav-link i {
  text-decoration: none;
}

.component-header-subtitle, .chip-subtitle {
  background: var(--chip-background);
  padding: 0.25rem 0.75rem;
  border-radius: 0px 10px 0px 0px;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  font-weight: 800;
}
.component-header-subtitle:hover, .chip-subtitle:hover {
  background: var(--chip-background);
}

.listing-item-category, .chip-outline, .category, .tag {
  padding: 0.25rem 0.75rem;
  border: solid 1px var(--chip-outline-border);
  background: var(--chip-outline-background);
}

.chip-themed {
  padding: 0.5rem 0.75rem;
  background: var(--button-primary-background);
  color: var(--button-primary-foreground);
  display: flex;
  flex-flow: row;
  gap: 0.5rem;
}
.chip-themed svg {
  fill: currentColor;
}

.chip-themed-light {
  background: #F8F5F3;
  color: #000711;
  display: flex;
  flex-flow: row;
  gap: 0.5rem;
}
.chip-themed-light svg {
  fill: currentColor;
}

.counter-gradient, .hero-banner-gradient {
  background: var(--background-gradient);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.counter-image-background, .hero-banner-image-background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.counter-image-background img, .hero-banner-image-background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-position: var(--focal-point-h) var(--focal-point-v);
}

@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-BoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-UltraLightItalic.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-ExtraBoldItalic.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AcherusGrotesque";
  src: url("/wwwroot/Static/fonts/AcherusGrotesque-UltraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Jubilat";
  src: url("/wwwroot/Static/fonts/Jubilat.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 25% 151%;
  font-style: normal;
}
@font-face {
  font-family: "Jubilat";
  src: url("/wwwroot/Static/fonts/Jubilat-Italic.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 25% 151%;
  font-style: italic;
}
body {
  font-size: 100%;
  font-feature-settings: "pnum" on, "lnum" on, "cpsp" on, "zero" on, "cv10" on;
}

.unlink {
  text-decoration: none;
}

b,
strong,
.bold {
  font-weight: 700;
}

li::marker {
  font-size: small;
}

.uppercase {
  text-transform: uppercase;
}

.medium {
  font-weight: 500;
}

sup {
  vertical-align: super;
  font-size: 0.875em;
}

sub {
  vertical-align: sub;
  font-size: 0.875em;
}

s {
  text-decoration: line-through;
}

em,
i,
.italic {
  font-style: italic;
}

.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}

.light {
  color: rgba(0, 7, 17, 0.72);
}

.tag {
  font-weight: 700;
  cursor: auto;
}
.taga:hover, .taga:active, .taga:focus {
  background: var(--button-primary-background);
  color: var(--button-primary-foreground);
}

.category {
  font-family: "AcherusGrotesque", Arial, sans-serif;
  font-weight: 400;
  border-width: 1px;
  cursor: default;
}
.categorya:hover, .categorya:active, .categorya:focus {
  background: var(--button-primary-background);
  color: var(--button-primary-foreground);
}

blockquote {
  font-style: italic;
}

::selection {
  background-color: var(--foreground) !important;
  color: var(--background-color) !important;
}

.my-news-category-card-header > a::selection, .modal-inner-survey .interstitial-block-close .modal-close::selection, .download-block-button a::selection, .content-highlight-block-item-link a::selection, .icon-link::selection, .btn-icon::selection, .btn-secondary::selection {
  background-color: var(--background) !important;
  color: var(--foreground) !important;
}

button {
  font-family: "AcherusGrotesque", Arial, sans-serif;
}

/*  Apply silent classes to heading elements and equivalent classes at each level
    This equates to:
    h3, .h3-style { @extend %h3 } but without having to apply it to each level manually.
*/
body {
  --x-large-text: 5.125rem;
  --x-large-text-height: 0.97561;
  --large-text: 4.1875rem;
  --large-text-height: 0.9207;
  --medium-large-text: 3.1875rem;
  --medium-large-text-height: 1.0293;
  --medium-text: 2.375rem;
  --medium-text-height: 1.16095;
  --large-regular-text: 1.125rem;
  --large-regular-text-height: 1.33333;
  --regular-text: 1.75rem;
  --regular-text-height: 1.05522;
  --small-text: 1.3125rem;
  --small-text-height: 1.12518;
  --x-small-text: 0.875rem;
  --x-small-text-height: 1.42857;
  --label-text: 1rem;
  --label-text-height: 1.375;
  --small-button-text: 1rem;
  --small-button-text-height: 1.22222;
  --button-text: 1.125rem;
  --button-text-height: 1.22222;
  --body-text: 1rem;
  --body-text-height: 1.375;
  --sub-text: 1rem;
  --sub-text-height: 1.125;
  --quote-text: 1.5rem;
  --quote-text-height: 1.25;
  --x-large-magazine-text: 5.125rem;
  --x-large-magazine-text-height: 1.1295;
  --large-magazine-text: 3.375rem;
  --large-magazine-text-height: 1.14815;
  --medium-large-magazine-text: 2.375rem;
  --medium-large-magazine-text-height: 1.05263;
  --medium-magazine-text: 2.36875rem;
  --medium-magazine-text-height: 1.16095;
  --regular-magazine-text: 2.125rem;
  --regular-magazine-text-height: 1.29412;
  --small-magazine-text: 1.77rem;
  --small-magazine-text-height: 1.02518;
}
@media (max-width: default) {
  body {
    --x-large-text: 5.125rem;
    --x-large-text-height: 0.97561;
  }
}
@media (max-width: 640px) {
  body {
    --x-large-text: 3.375rem;
    --x-large-text-height: 1.14815;
  }
}
@media (max-width: default) {
  body {
    --large-text: 4.1875rem;
    --large-text-height: 0.9207;
  }
}
@media (max-width: 640px) {
  body {
    --large-text: 2.75rem;
    --large-text-height: 1.22727;
  }
}
@media (max-width: default) {
  body {
    --medium-large-text: 3.1875rem;
    --medium-large-text-height: 1.0293;
  }
}
@media (max-width: 640px) {
  body {
    --medium-large-text: 2.375rem;
    --medium-large-text-height: 1.05263;
  }
}
@media (max-width: default) {
  body {
    --medium-text: 2.375rem;
    --medium-text-height: 1.16095;
  }
}
@media (max-width: 640px) {
  body {
    --medium-text: 1.875rem;
    --medium-text-height: 1.18421;
  }
}
@media (max-width: default) {
  body {
    --large-regular-text: 1.125rem;
    --large-regular-text-height: 1.33333;
  }
}
@media (max-width: 640px) {
  body {
    --large-regular-text: 1.125rem;
    --large-regular-text-height: 1;
  }
}
@media (max-width: default) {
  body {
    --regular-text: 1.75rem;
    --regular-text-height: 1.05522;
  }
}
@media (max-width: 640px) {
  body {
    --regular-text: 1.375rem;
    --regular-text-height: 1.18182;
  }
}
@media (max-width: default) {
  body {
    --small-text: 1.3125rem;
    --small-text-height: 1.12518;
  }
}
@media (max-width: 640px) {
  body {
    --small-text: 1.25rem;
    --small-text-height: 1.2;
  }
}
@media (max-width: default) {
  body {
    --x-small-text: 0.875rem;
    --x-small-text-height: 1.42857;
  }
}
@media (max-width: 640px) {
  body {
    --x-small-text: 0.875rem;
    --x-small-text-height: 1;
  }
}
@media (max-width: default) {
  body {
    --label-text: 1rem;
    --label-text-height: 1.375;
  }
}
@media (max-width: 640px) {
  body {
    --label-text: 0.875rem;
    --label-text-height: 1;
  }
}
@media (max-width: default) {
  body {
    --small-button-text: 1rem;
    --small-button-text-height: 1.22222;
  }
}
@media (max-width: 640px) {
  body {
    --small-button-text: 1rem;
    --small-button-text-height: 1.22222;
  }
}
@media (max-width: default) {
  body {
    --button-text: 1.125rem;
    --button-text-height: 1.22222;
  }
}
@media (max-width: 640px) {
  body {
    --button-text: 1rem;
    --button-text-height: 1.375;
  }
}
@media (max-width: default) {
  body {
    --body-text: 1rem;
    --body-text-height: 1.375;
  }
}
@media (max-width: 640px) {
  body {
    --body-text: 1rem;
    --body-text-height: 1;
  }
}
@media (max-width: default) {
  body {
    --sub-text: 1rem;
    --sub-text-height: 1.125;
  }
}
@media (max-width: 640px) {
  body {
    --sub-text: 0.875rem;
    --sub-text-height: 1;
  }
}
@media (max-width: default) {
  body {
    --quote-text: 1.5rem;
    --quote-text-height: 1.25;
  }
}
@media (max-width: 640px) {
  body {
    --quote-text: 1.25rem;
    --quote-text-height: 1.2;
  }
}
@media (max-width: default) {
  body {
    --x-large-magazine-text: 5.125rem;
    --x-large-magazine-text-height: 1.1295;
  }
}
@media (max-width: 1024px) {
  body {
    --x-large-magazine-text: 3.5rem;
    --x-large-magazine-text-height: 0.92;
  }
}
@media (max-width: 640px) {
  body {
    --x-large-magazine-text: 2.5rem;
    --x-large-magazine-text-height: 1;
  }
}
@media (max-width: default) {
  body {
    --large-magazine-text: 3.375rem;
    --large-magazine-text-height: 1.14815;
  }
}
@media (max-width: 640px) {
  body {
    --large-magazine-text: 2rem;
    --large-magazine-text-height: 1.6;
  }
}
@media (max-width: default) {
  body {
    --medium-large-magazine-text: 2.375rem;
    --medium-large-magazine-text-height: 1.05263;
  }
}
@media (max-width: 640px) {
  body {
    --medium-large-magazine-text: 2.375rem;
    --medium-large-magazine-text-height: 1.05263;
  }
}
@media (max-width: default) {
  body {
    --medium-magazine-text: 2.36875rem;
    --medium-magazine-text-height: 1.16095;
  }
}
@media (max-width: 640px) {
  body {
    --medium-magazine-text: 1.5rem;
    --medium-magazine-text-height: 1.16;
  }
}
@media (max-width: default) {
  body {
    --regular-magazine-text: 2.125rem;
    --regular-magazine-text-height: 1.29412;
  }
}
@media (max-width: 1024px) {
  body {
    --regular-magazine-text: 1.625rem;
    --regular-magazine-text-height: 1.11538;
  }
}
@media (max-width: 640px) {
  body {
    --regular-magazine-text: 1.625rem;
    --regular-magazine-text-height: 1.11538;
  }
}
@media (max-width: default) {
  body {
    --small-magazine-text: 1.77rem;
    --small-magazine-text-height: 1.02518;
  }
}
@media (max-width: 640px) {
  body {
    --small-magazine-text: 1.5rem;
    --small-magazine-text-height: 1.75;
  }
}

.rich-text {
  max-width: 50rem;
  word-wrap: break-word;
}
.rich-text * + * {
  margin-top: 0.75rem;
}
.rich-text * + h1,
.rich-text * + h2,
.rich-text * + h3 {
  margin-top: 2rem;
}
.rich-text * + h4,
.rich-text * + h5 {
  margin-top: 1.375rem;
}
@media screen and (max-width: 640px) {
  .rich-text * + h4,
  .rich-text * + h5 {
    margin-top: 1rem;
  }
}
.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5 {
  clear: both;
}
.rich-text ul,
.rich-text ol {
  padding-left: calc(0.66em + 6px);
}
.rich-text ul.no-bullets,
.rich-text ol.no-bullets {
  list-style-type: none;
  padding-left: 0;
}
.rich-text ol {
  list-style-type: decimal;
}
.rich-text ul {
  list-style-type: disc;
}
.rich-text:after {
  content: "";
  display: block;
  clear: both;
}
.rich-text img[style*=float] {
  margin-right: 1.375rem;
  padding: 0.5rem;
  max-width: 50%;
}
.rich-text img[style*="float: left;"],
.rich-text img[style*="float:left;"] {
  margin-right: 1.375rem;
  padding: 0.5rem;
}
.rich-text img[style*="float: right;"],
.rich-text img[style*="float:right;"] {
  margin-left: 1.375rem;
  margin-right: 0;
  padding: 0.5rem;
}
.rich-text a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)) {
  color: var(--link-color);
}
.rich-text a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)):hover,
.rich-text a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)):focus {
  color: var(--link-color-active);
}
.rich-text a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)):visited {
  color: var(--link-color-visited);
}
.rich-text table a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)) {
  color: var(--button-primary-foreground);
}
.rich-text table a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)):hover,
.rich-text table a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)):focus {
  color: var(--button-primary-foreground);
}
.rich-text table a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)):visited {
  color: var(--link-color-visited);
}

.theme-ntsa, .theme-psca, .theme-napa, .theme-asea, .theme-asppa, .theme-ara, .theme-default {
  --dark-primary: #000F3A;
  --RGB_dark-primary: 0, 15, 58;
  --dark-secondary: #610113;
  --RGB_dark-secondary: 97, 1, 19;
  --dark-tertiary: #1F1D1B;
  --RGB_dark-tertiary: 31, 29, 27;
  --classic-primary: #003589;
  --RGB_classic-primary: 0, 53, 137;
  --classic-secondary: #D00027;
  --RGB_classic-secondary: 208, 0, 39;
  --classic-tertiary: #5B534D;
  --RGB_classic-tertiary: 91, 83, 77;
  --jewel-primary: #258AFE;
  --RGB_jewel-primary: 37, 138, 254;
  --jewel-secondary: #FE254E;
  --RGB_jewel-secondary: 254, 37, 78;
  --jewel-tertiary: #9E8E80;
  --RGB_jewel-tertiary: 158, 142, 128;
  --light-primary: #BEDBFF;
  --RGB_light-primary: 190, 219, 255;
  --light-secondary: #EEB1B4;
  --RGB_light-secondary: 238, 177, 180;
  --light-tertiary: #F8F5F2;
  --RGB_light-tertiary: 248, 245, 242;
  --black: #000711;
  --RGB_black: 0, 7, 17;
  --white: #fff;
  --RGB_white: 255, 255, 255;
  --background: #fff;
  --RGB_background: 255, 255, 255;
  --secondary-background: #F8F5F2;
  --RGB_secondary-background: 248, 245, 242;
  --foreground: #000711;
  --RGB_foreground: 0, 7, 17;
  --alert-green: #31CB65;
  --RGB_alert-green: 49, 203, 101;
  --alert-yellow: #FFDB5C;
  --RGB_alert-yellow: 255, 219, 92;
  --alert-red: #FF1818;
  --RGB_alert-red: 255, 24, 24;
  --alert-bright-red: #F0294D;
  --RGB_alert-bright-red: 240, 41, 77;
  --alert-grey: #F3EEE9;
  --RGB_alert-grey: 243, 238, 233;
  --button-primary-background: #000F3A;
  --RGB_button-primary-background: 0, 15, 58;
  --button-primary-foreground: #000F3A;
  --RGB_button-primary-foreground: 0, 15, 58;
  --button-secondary-background: #000F3A;
  --RGB_button-secondary-background: 0, 15, 58;
  --button-secondary-foreground: #000F3A;
  --RGB_button-secondary-foreground: 0, 15, 58;
}

.theme-ara {
  --dark-primary: #000F3A;
  --RGB_dark-primary: 0, 15, 58;
  --dark-secondary: #610113;
  --RGB_dark-secondary: 97, 1, 19;
  --classic-primary: #003589;
  --RGB_classic-primary: 0, 53, 137;
  --classic-secondary: #D00027;
  --RGB_classic-secondary: 208, 0, 39;
  --jewel-primary: #258AFE;
  --RGB_jewel-primary: 37, 138, 254;
  --jewel-secondary: #FE254E;
  --RGB_jewel-secondary: 254, 37, 78;
  --light-primary: #BEDBFF;
  --RGB_light-primary: 190, 219, 255;
  --light-secondary: #EEB1B4;
  --RGB_light-secondary: 238, 177, 180;
}

.theme-asea {
  --dark-primary: #380041;
  --RGB_dark-primary: 56, 0, 65;
  --dark-secondary: #0B3544;
  --RGB_dark-secondary: 11, 53, 68;
  --classic-primary: #721281;
  --RGB_classic-primary: 114, 18, 129;
  --classic-secondary: #21678F;
  --RGB_classic-secondary: 33, 103, 143;
  --jewel-primary: #DA6AEC;
  --RGB_jewel-primary: 218, 106, 236;
  --jewel-secondary: #3EB6FA;
  --RGB_jewel-secondary: 62, 182, 250;
  --light-primary: #EDCFF1;
  --RGB_light-primary: 237, 207, 241;
  --light-secondary: #CAE5EF;
  --RGB_light-secondary: 202, 229, 239;
}

.theme-ntsa {
  --dark-primary: #003033;
  --RGB_dark-primary: 0, 48, 51;
  --dark-secondary: #2B4200;
  --RGB_dark-secondary: 43, 66, 0;
  --classic-primary: #01595F;
  --RGB_classic-primary: 1, 89, 95;
  --classic-secondary: #527E00;
  --RGB_classic-secondary: 82, 126, 0;
  --jewel-primary: #2FBDC6;
  --RGB_jewel-primary: 47, 189, 198;
  --jewel-secondary: #9BD42F;
  --RGB_jewel-secondary: 155, 212, 47;
  --light-primary: #C0ECF0;
  --RGB_light-primary: 192, 236, 240;
  --light-secondary: #DBEABF;
  --RGB_light-secondary: 219, 234, 191;
}

.theme-asppa {
  --dark-primary: #1A4500;
  --RGB_dark-primary: 26, 69, 0;
  --dark-secondary: #02314D;
  --RGB_dark-secondary: 2, 49, 77;
  --classic-primary: #338005;
  --RGB_classic-primary: 51, 128, 5;
  --classic-secondary: #094D75;
  --RGB_classic-secondary: 9, 77, 117;
  --jewel-primary: #66CA2B;
  --RGB_jewel-primary: 102, 202, 43;
  --jewel-secondary: #0391E5;
  --RGB_jewel-secondary: 3, 145, 229;
  --light-primary: #CBEDB7;
  --RGB_light-primary: 203, 237, 183;
  --light-secondary: #CCF3FC;
  --RGB_light-secondary: 204, 243, 252;
}

.theme-napa {
  --dark-primary: #001A43;
  --RGB_dark-primary: 0, 26, 67;
  --dark-secondary: #0C2B35;
  --RGB_dark-secondary: 12, 43, 53;
  --classic-primary: #164285;
  --RGB_classic-primary: 22, 66, 133;
  --classic-secondary: #00687E;
  --RGB_classic-secondary: 0, 104, 126;
  --jewel-primary: #4786EB;
  --RGB_jewel-primary: 71, 134, 235;
  --jewel-secondary: #00C6E1;
  --RGB_jewel-secondary: 0, 198, 225;
  --light-primary: #CEDEF6;
  --RGB_light-primary: 206, 222, 246;
  --light-secondary: #C8EAEF;
  --RGB_light-secondary: 200, 234, 239;
}

.theme-psca {
  --dark-primary: #58000C;
  --RGB_dark-primary: 88, 0, 12;
  --dark-secondary: #191537;
  --RGB_dark-secondary: 25, 21, 55;
  --classic-primary: #AF0019;
  --RGB_classic-primary: 175, 0, 25;
  --classic-secondary: #2E2766;
  --RGB_classic-secondary: 46, 39, 102;
  --jewel-primary: #FF2845;
  --RGB_jewel-primary: 255, 40, 69;
  --jewel-secondary: #685ADA;
  --RGB_jewel-secondary: 104, 90, 218;
  --light-primary: #FFD8DD;
  --RGB_light-primary: 255, 216, 221;
  --light-secondary: #CECEE8;
  --RGB_light-secondary: 206, 206, 232;
}

.theme-dark, .theme-light, .article-detail-advertisement .advertisement-block {
  --dark-tertiary: #1F1D1B;
  --classic-tertiary: #5B534D;
  --jewel-tertiary: #9E8E80;
  --light-tertiary: #F8F5F2;
  --black: #000711;
  --white: #fff;
  color: var(--foreground);
  --alert-green: #31CB65;
  --alert-yellow: #FFDB5C;
  --alert-red: #FF1818;
  --alert-bright-red: #F0294D;
  --alert-grey: #F3EEE9;
}

.theme-light, .article-detail-advertisement .advertisement-block {
  --background: #fff;
  --background-color: #fff;
  --secondary-background: #F8F5F2;
  --foreground: #000711;
  --button-primary-background: var(--dark-primary);
  --button-primary-foreground: #F8F5F3;
  --button-disabled-background: rgba(0, 7, 17, 0.65);
  --button-disabled-foreground: rgba(248, 245, 243, 0.22);
  --button-highlight-background: var(--jewel-primary);
  --button-highlight-foreground: #000711;
  --button-secondary-background: var(--jewel-primary);
  --button-secondary-foreground: #000711;
  --button-tertiary-foreground: #000711;
  --button-tertiary-background: var(--jewel-primary);
  --button-secondary-icon: #fff;
  --button-selected: rgba(0, 7, 17, 0.18);
  --accordion-background: rgba(243, 238, 233, 0.32);
  --accordion-background-active: rgba(243, 238, 233, 0.64);
  --accordion-border: rgba(243, 238, 233, 0.82);
  --accordion-border-active: #F8F5F3;
  --accordion-icon-background: #000711;
  --accordion-icon-color: #fff;
  --chip-background: rgba(0, 7, 17, 0.08);
  --chip-outline-background: rgba(243, 238, 233, 0.22);
  --chip-outline-border: rgba(0, 7, 17, 0.42);
  --tab-background: rgba(243, 238, 233, 0.82);
  --tab-background-active: rgba(243, 238, 233, 0.82);
  --tab-border: rgba(0, 7, 17, 0.72);
  --card-background: rgba(243, 238, 233, 0.22);
  --card-border: rgba(243, 238, 233, 0.82);
  --carousel-background: rgba(0, 7, 17, 0.72);
  --swiper-theme-color: #000711;
  --scrollbar-background: rgba(0, 7, 17, 0.18);
  --scrollbar-foreground: $black;
  --table-background: rgba(0, 7, 17, 0.04);
  --inactive-button: rgba(0, 7, 17, 0.82);
  --download-card: rgba(0, 7, 17, 0.08);
  --counter-background: rgba(243, 238, 233, 0.72);
  --input-background: rgba(0, 7, 17, 0.08);
  --input-color: rgba(0, 7, 17, 0.72);
  --input-background-disabled: rgba(0, 7, 17, 0.04);
  --input-color-disabled: rgba(0, 7, 17, 0.72);
  --registration-header: var(--dark-primary);
  --registration-body: #F8F5F2;
  --link-color: var(--classic-primary);
  --link-color-active: var(--jewel-primary);
  --link-color-visited: var(--dark-primary);
  --calendar-range-color: rgba(0, 7, 17, 0.42);
  --calendar-range-bg-color: rgba(0, 7, 17, 0.04);
  --my-news-border: rgba(0, 7, 17, 0.42);
}

.theme-dark {
  --foreground: #F8F5F3;
  --button-primary-background: #F3EEE9;
  --button-primary-foreground: #000711;
  --button-disabled-background: rgba(230, 225, 221, 0.55);
  --button-disabled-foreground: rgba(0, 7, 17, 0.42);
  --button-secondary-background: #F3EEE9;
  --button-secondary-foreground: #F3EEE9;
  --button-tertiary-foreground: #000711;
  --button-tertiary-background: #F3EEE9;
  --button-secondary-icon: #000711;
  --button-selected: rgba(0, 7, 17, 0.18);
  --accordion-background: rgba(0, 0, 0, 0.32);
  --accordion-background-active: rgba(0, 0, 0, 0.64);
  --accordion-border: rgba(#000711, .22);
  --accordion-border-active: rgba(248, 245, 243, 0.14);
  --accordion-icon-background: #F8F5F3;
  --accordion-icon-color: #000711;
  --chip-background: rgba(248, 245, 243, 0.14);
  --chip-outline-background: rgba(243, 238, 233, 0.2);
  --chip-outline-border: rgba(248, 245, 243, 0.42);
  --tab-background: var(--background-color);
  --tab-background-active: rgba(248, 245, 243, 0.14);
  --tab-border: rgba(248, 245, 243, 0.42);
  --card-background: rgba(0, 0, 0, 0.22);
  --card-border: rgba(0, 0, 0, 0.82);
  --carousel-background: rgba(248, 245, 243, 0.64);
  --swiper-theme-color: #F8F5F3;
  --scrollbar-background: rgba(0, 7, 17, 0.18);
  --scrollbar-foreground: $black;
  --table-background: rgba(0, 7, 17, 0.04);
  --inactive-button: rgba(248, 245, 243, 0.82);
  --download-card: rgba(243, 238, 233, 0.22);
  --input-background: rgba(248, 245, 243, 0.14);
  --input-color: #F8F5F3;
  --input-background-disabled: rgba(248, 245, 243, 0.04);
  --input-color-disabled: rgba(248, 245, 243, 0.22);
  --registration-header: rgba(114, 114, 114, 0.92);
  --registration-body: rgba(114, 114, 114, 0.62);
  --link-color: var(--light-primary);
  --link-color-active: var(--jewel-primary);
  --link-color-visited: var(--light-secondary);
  --my-news-border: rgba(248, 245, 243, 0.42);
  --counter-background: rgba(0, 7, 17, 0.72);
}

.theme-dark-primary {
  --background-color: var(--dark-primary);
  background-color: var(--dark-primary);
  --svg-fill-primary: var(--jewel-primary);
  --svg-fill-secondary: var(--classic-primary);
  --gradient-color-top: rgba(var(--RGB_dark-primary), 0.8);
  --gradient-color-bottom: rgba(var(--RGB_classic-primary), 0.8);
  --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}

.theme-dark-secondary {
  --background-color: var(--dark-secondary);
  background-color: var(--dark-secondary);
  --svg-fill-primary: var(--jewel-secondary);
  --svg-fill-secondary: var(--classic-secondary);
  --gradient-color-top: rgba(var(--RGB_dark-secondary), 0.8);
  --gradient-color-bottom: rgba(var(--RGB_classic-secondary), 0.8);
  --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}

.theme-dark-tertiary {
  --background-color: var(--dark-tertiary);
  background-color: var(--dark-tertiary);
  --svg-fill-primary: var(--jewel-tertiary);
  --svg-fill-secondary: var(--classic-tertiary);
  --gradient-color-top: rgba(var(--RGB_dark-tertiary), 0.8);
  --gradient-color-bottom: rgba(var(--RGB_classic-tertiary), 0.8);
  --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}

.theme-classic-primary {
  --background-color: var(--classic-primary);
  background-color: var(--classic-primary);
  --svg-fill-primary: var(--jewel-primary);
  --svg-fill-secondary: var(--jewel-primary);
  --gradient-color-top: rgba(var(--RGB_classic-primary), 0.8);
  --gradient-color-bottom: rgba(var(--RGB_jewel-primary), 0.8);
  --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}

.theme-classic-secondary {
  --background-color: var(--classic-secondary);
  background-color: var(--classic-secondary);
  --svg-fill-primary: var(--jewel-secondary);
  --svg-fill-secondary: var(--jewel-secondary);
  --gradient-color-top: rgba(var(--RGB_classic-secondary), 0.8);
  --gradient-color-bottom: rgba(var(--RGB_jewel-secondary), 0.8);
  --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}

.theme-classic-tertiary {
  --background-color: var(--classic-tertiary);
  background-color: var(--classic-tertiary);
  --svg-fill-primary: var(--jewel-tertiary);
  --svg-fill-secondary: var(--jewel-tertiary);
  --gradient-color-top: rgba(var(--RGB_classic-tertiary), 0.8);
  --gradient-color-bottom: rgba(var(--RGB_jewel-tertiary), 0.8);
  --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}

.theme-light-primary, .article-detail-advertisement .advertisement-block {
  --background-color: #fff;
  background-color: #fff;
  --svg-fill-primary: var(--light-primary);
  --svg-fill-secondary: var(--jewel-primary);
  --background-gradient: rgba(248, 245, 242, 0.8);
}

.theme-light-secondary {
  --background-color: #fff;
  background-color: #fff;
  --svg-fill-primary: var(--light-secondary);
  --svg-fill-secondary: var(--jewel-secondary);
  --background-gradient: rgba(248, 245, 242, 0.8);
}

.theme-light-tertiary {
  --background-color: #fff;
  background-color: #fff;
  --svg-fill-primary: var(--light-tertiary);
  --svg-fill-secondary: var(--jewel-tertiary);
  --background-gradient:rgba(248, 245, 242, 0.8);
}

.svg-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.svg-background-svg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -500px;
  left: 40%;
  overflow: visible;
}
.svg-background.svg-two-tone-swoop .svg-background-svg {
  top: -500px;
}
.svg-background.svg-egg .svg-background-svg {
  top: 0;
}

.btn-full-width {
  width: 100%;
  max-width: 100%;
}

.header-toggle-search, .header-toggle-button, .header-toggle-button-tablet, .header-search-button {
  background-color: var(--dark-primary);
  border: none;
  color: #fff;
  border-style: none;
  padding: 0.875rem 2.5rem 0.75rem 1.125rem;
  margin-top: 0;
  transition: all 0.1s ease;
}
.header-toggle-search:hover i, .header-toggle-search:active i, .header-toggle-button:hover i, .header-toggle-button:active i, .header-toggle-button-tablet:hover i, .header-toggle-button-tablet:active i, .header-search-button:hover i, .header-search-button:active i {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  bottom: calc(50% - 0.75rem);
  right: calc(50% - 0.75rem);
  --background: var(--dark-primary);
}

@media (max-width: 640px) {
  .header-toggle-search, .header-toggle-button {
    padding-inline: 1.375rem;
    padding-block: 1.25rem;
    height: fit-content;
  }
  .header-toggle-search i, .header-toggle-button i {
    --background: var(--dark-primary);
  }
}

.header-toggle-button i, .header-toggle-button-tablet i, .header-toggle-search i, .header-search-button i {
  font-size: 1.5rem;
  line-height: 1.5rem;
  fill: currentColor;
  bottom: calc(50% - 0.75rem);
  right: calc(50% - 0.75rem);
}

.header-toggle-search:focus i {
  width: 1.5rem;
  height: 1.5rem;
  bottom: calc(50% - 0.75rem);
  right: calc(50% - 0.75rem);
}

.header-toggle-button-tablet:hover i, .header-toggle-button-tablet:active i {
  right: 0.75rem;
}
.header-toggle-button-tablet i {
  right: 0.75rem;
  --background: var(--dark-primary);
}

.header-toggle-search span {
  line-height: 1.375rem;
  vertical-align: middle;
  font-size: 1rem;
  display: none;
}

.header-main {
  padding-block: 1rem;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr);
  grid-template-rows: auto auto;
  gap: 0.5rem;
}
@media (max-width: 640px) {
  .header-main {
    grid-template-columns: auto auto;
    z-index: 2;
    position: relative;
    background: #fff;
  }
}
.header-main.logo-large {
  grid-template-columns: repeat(2, minmax(100px, 1fr));
}
.header-sister-nav {
  background: #F8F5F2;
  position: relative;
  z-index: 2;
}
.header-sister-nav[aria-hidden=true] {
  display: none;
}
.header-toggle {
  grid-row: 1/span 1;
  grid-column: 2/span 1;
  justify-self: flex-end;
  display: flex;
  flex-flow: row;
  gap: 0.375rem;
}
@media (min-width: 640.1px) {
  .header-toggle {
    display: none;
  }
}
@media (max-width: 640px) {
  .header-toggle-button-tablet {
    display: none;
  }
}
@media (min-width: 1350.1px) {
  .header-toggle-button-tablet {
    display: none;
  }
}
.header-search {
  grid-row: 2/span 1;
  grid-column: 2/span 1;
  justify-self: flex-end;
  gap: 0.375rem;
  display: flex;
  flex-flow: row;
  width: 100%;
}
@media (max-width: 1350px) {
  .header-search {
    grid-column: 1/span 2;
    width: 100%;
  }
}
@media (max-width: 640px) {
  .header-search {
    display: none;
  }
  .header-search[aria-hidden=false] {
    display: block;
  }
}
.header-search-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  flex-grow: 1;
  position: relative;
}
.header-search.logo-large input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  max-height: 4.375rem;
  font-size: 1.3125rem;
  line-height: 1.12518;
  font-size: var(--small-text);
  line-height: var(--small-text-height);
}
@media (max-width: 1024px) {
  .header-search.logo-large input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
    width: 100%;
    min-width: auto;
  }
}
@media (min-width: 1280.1px) {
  .header-search.logo-large input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
    width: 100%;
    max-width: 55.875rem;
  }
}
@media (min-width: 1280.1px) {
  .header-search.logo-large .search-autocomplete-results {
    width: 100%;
    max-width: 55.875rem;
  }
}
.header-search input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  border: none;
  background-color: #f6f6f6;
  padding: 0.5375rem 1.6875rem;
  min-width: 20.125rem;
  max-width: 47.125rem;
}
@media (max-width: 1024px) {
  .header-search input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
    width: 100%;
    min-width: auto;
  }
}
.header-search .search-autocomplete-results {
  width: 100%;
  max-width: 47.125rem;
}
.header-search-button {
  background-color: var(--jewel-primary);
  padding: 0.9375rem 1.3125rem;
  color: #000711;
  max-height: 4.375rem;
}
.header-search-button:after {
  display: none;
}
.header-search-button:hover i {
  color: #fff;
}
.header-search-button:hover i:before {
  color: #fff;
}
.header-search-button:focus i {
  bottom: calc(50% - 0.75rem);
  right: calc(50% - 0.75rem);
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  --background: var(--dark-primary);
  color: #fff;
}
.header-search-button:focus i:before {
  color: #fff;
}
.header-search-button i {
  color: #000711;
}
.header-search-button i:before {
  color: #000711;
}
.header-utility-nav-desktop {
  grid-row-start: 1;
  grid-column-start: 2;
  justify-self: flex-end;
  margin-bottom: 0.5rem;
}
@media (max-width: 640px) {
  .header-utility-nav-desktop {
    display: none;
  }
}
.header-utility-nav-desktop .utility-nav-links {
  justify-content: flex-end;
}
.header-utility-nav-mobile {
  display: none;
  flex-flow: row wrap;
  row-gap: 0.75rem;
}
@media (max-width: 640px) {
  .header-utility-nav-mobile {
    display: flex;
    margin-inline: 1rem;
    margin-bottom: 1.375rem;
  }
}
.header-logo {
  grid-column-start: 1;
  grid-row: 1/span 2;
  align-self: center;
}
@media (max-width: 1350px) {
  .header-logo {
    grid-row: 1/span 1;
  }
}
.header-logo a {
  display: inline-block;
}
.header-logo.logo-small img {
  min-height: 54px;
  height: 54px;
  max-width: 203px;
}
@media (max-width: 400px) {
  .header-logo.logo-small img {
    max-width: 170px;
    min-height: auto;
    height: auto;
  }
}
.header-logo.logo-large img {
  min-height: 54px;
  max-width: 203px;
}
@media (max-width: 400px) {
  .header-logo.logo-large img {
    max-width: 170px;
    min-height: auto;
    height: auto;
  }
}
@media (min-width: 1280.1px) {
  .header-logo.logo-large img {
    min-height: 181px;
    width: 554px;
    max-width: 100%;
  }
}

.sister-nav-links {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
@media (max-width: 640px) {
  .sister-nav-links {
    column-gap: 0.5rem;
  }
}
.sister-nav-link {
  padding-inline: 0.75rem;
  padding-block: 0.5rem;
  border-top: solid 4px;
  border-color: transparent;
  display: block;
}
.sister-nav-link-item.active a, .sister-nav-link-item:hover a {
  background: #fff;
  border-color: var(--jewel-primary);
}
@media (max-width: 1024px) {
  .sister-nav-link {
    font-size: 1rem;
    line-height: 1.375;
    font-size: var(--label-text);
    line-height: var(--label-text-height);
  }
}
@media (max-width: 400px) {
  .sister-nav-link {
    padding-inline: 0;
  }
}

@media (min-width: 640.1px) {
  .hamburger--spin {
    padding-right: 1.75rem;
  }
}
@media (max-width: 640px) {
  .hamburger--spin {
    padding: 0.55rem 0.5rem;
  }
}
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin[aria-expanded=true] .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin[aria-expanded=true] .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin[aria-expanded=true] .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin:after {
  display: none;
  content: none;
}

.hamburger--spin[aria-expanded=true] .hamburger-inner,
.hamburger--spin[aria-expanded=true] .hamburger-inner::before,
.hamburger--spin[aria-expanded=true] .hamburger-inner::after {
  background-color: #fff;
}

.hamburger-box {
  width: 1.75rem;
  height: 1rem;
  display: inline-block;
  position: relative;
}
@media (min-width: 640.1px) {
  .hamburger-box {
    padding-left: 0.5rem;
  }
}

.hamburger-inner {
  display: block;
  top: calc(50% + 3px);
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 1.75rem;
  height: 2px;
  background-color: #fff;
  position: absolute;
  border-radius: 2px;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

.quick-nav-button, .utility-nav-link-sister-mobile button, .utility-nav-link-sister-tablet button, .utility-nav-link-sister button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-inline: 0.25rem;
}
[aria-expanded=true].quick-nav-button i, .utility-nav-link-sister-mobile button[aria-expanded=true] i, .utility-nav-link-sister-tablet button[aria-expanded=true] i, .utility-nav-link-sister button[aria-expanded=true] i {
  transform: rotate(180deg);
}

.utility-nav-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
}
@media (max-width: 1024px) {
  .utility-nav-links .quick-nav {
    display: none;
  }
}
.utility-nav-tablet-container {
  display: none;
  position: relative;
}
@media (min-width: 640.1px) and (max-width: 1024px) {
  .utility-nav-tablet-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }
}
.utility-nav-link {
  text-decoration: none;
  font-weight: 700;
  padding-block: 0.25rem;
  display: flex;
  flex-flow: row;
  gap: 0.25rem;
  align-items: center;
}
.utility-nav-link > svg {
  max-width: 20px;
  max-height: 20px;
}
.utility-nav-link-sister {
  color: var(--dark-primary);
  position: relative;
  margin-left: 0.5rem;
}
.utility-nav-link-sister button {
  color: var(--dark-primary);
}
@media (max-width: 1024px) {
  .utility-nav-link-sister {
    display: none;
  }
}
.utility-nav-link-sister-tablet {
  display: none;
  color: var(--dark-primary);
}
@media (min-width: 640.1px) and (max-width: 1024px) {
  .utility-nav-link-sister-tablet {
    display: flex;
    justify-content: flex-end;
  }
}
.utility-nav-link-sister-tablet button {
  color: var(--dark-primary);
}
.utility-nav-link-sister-mobile {
  display: none;
  color: var(--jewel-primary);
}
@media (max-width: 640px) {
  .utility-nav-link-sister-mobile {
    display: block;
  }
}
.utility-nav-link-sister-mobile button {
  color: var(--jewel-primary);
}

.header-mega-nav-wrapper {
  background-color: var(--dark-primary);
  color: #fff;
  position: relative;
  border-bottom: solid 1px var(--jewel-primary);
}
@media (max-width: 1350px) {
  .header-mega-nav-wrapper {
    display: none;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-wrapper[aria-hidden=false] {
    display: block;
    z-index: 999992;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-wrapper {
    background: var(--dark-primary);
    color: #fff;
    padding-block: 1.375rem;
  }
}
.header-mega-nav-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
}
@media (min-width: 1500.1px) {
  .header-mega-nav-links {
    gap: 1.375rem;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-links {
    display: block;
  }
}
.header-mega-nav-block-title {
  display: flex;
  flex-flow: row wrap;
  column-gap: 2rem;
  margin-bottom: 0;
  padding-right: 2rem;
  flex-flow: column;
  color: white;
}
@media screen and (max-width: 1024px) {
  .header-mega-nav-block-title {
    column-gap: 1.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .header-mega-nav-block-title {
    padding-right: 1.5rem;
  }
}
.header-mega-nav-block-title > * {
  overflow-wrap: break-word;
}
@media (min-width: 1350.1px) {
  .header-mega-nav-block-title {
    background: var(--classic-primary);
    width: 25%;
    padding-block: 1.75rem;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-title {
    width: 100%;
  }
  .header-mega-nav-block-title h3 {
    display: none;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-description {
    display: none;
  }
}
.header-mega-nav-block-link-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  column-gap: 2rem;
}
@media screen and (max-width: 640px) {
  .header-mega-nav-block-link-list {
    column-gap: 1.25rem;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-link-list {
    display: block;
  }
}
.header-mega-nav-block-link-item a {
  font-size: 1.3125rem;
  line-height: 1.12518;
  font-size: var(--small-text);
  line-height: var(--small-text-height);
  padding-block: 0.625rem;
  display: block;
}
@media (max-width: 1350px) {
  .header-mega-nav-block-link-item a {
    font-size: 1rem;
    line-height: 1.375;
    font-size: var(--body-text);
    line-height: var(--body-text-height);
    padding-block: 0.375rem;
    font-weight: 500;
  }
}
.header-mega-nav-block-links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}
@media screen and (max-width: 640px) {
  .header-mega-nav-block-links {
    gap: 1.25rem;
  }
}
@media (min-width: 1350.1px) {
  .header-mega-nav-block-links {
    margin-left: 2rem;
    padding-block: 1.75rem;
    width: 75%;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-links {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}
@media (max-width: 640px) {
  .header-mega-nav-block-links {
    grid-template-columns: 1fr;
  }
}
.header-mega-nav-block-top-link {
  padding-block: 0.625rem;
  display: flex;
  background: none;
  border: none;
  color: #fff;
  gap: 0.5rem;
  cursor: pointer;
}
@media (max-width: 1350px) {
  .header-mega-nav-block-top-link {
    font-weight: 700;
    width: 100%;
    color: #fff;
    justify-content: space-between;
    padding-inline: 0;
  }
  .header-mega-nav-block-top-link i {
    background: #fff;
    color: #000711;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-top-link + .header-mega-nav-block-top-link {
    padding-left: 1.5rem;
  }
}
.header-mega-nav-block-top-link:hover {
  text-decoration: none;
}
.header-mega-nav-block-top-link i {
  transition: all 0.1s ease;
}
.header-mega-nav-block-top-link i:before {
  vertical-align: middle;
}
.header-mega-nav-block-content {
  background-color: var(--dark-primary);
  position: absolute;
  z-index: 999;
  right: 0;
  width: 100%;
  color: black;
  top: auto;
  height: auto;
  overflow: hidden;
  transition: transform 0.3s ease;
  transform: scaleY(0);
  transform-origin: top;
}
.header-mega-nav-block-content:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: 1px;
  background-color: var(--jewel-primary);
  z-index: 2;
}
.header-mega-nav-block-content .header-mega-nav-block-content-wrapper {
  transform: scaleY(2);
  transition: transform 0.3s ease;
}
@media (max-width: 1350px) {
  .header-mega-nav-block-content {
    position: relative;
    transform: scaleY(0);
    padding-inline: 0;
    height: 0;
    transition: all 0.3s ease;
  }
  .header-mega-nav-block-content:before {
    display: none;
  }
  .header-mega-nav-block-content[aria-expanded=true] {
    transform: scaleY(1);
  }
  .header-mega-nav-block-content[aria-expanded=true] .header-mega-nav-block-content-wrapper {
    transform: scaleY(1);
    opacity: 1;
  }
  .header-mega-nav-block-content[aria-expanded=false] .header-mega-nav-block-content-wrapper {
    opacity: 0;
  }
}
@media (min-width: 1350.1px) {
  .header-mega-nav-block-content-wrapper {
    display: flex;
    flex-flow: row;
    gap: 2rem;
  }
}
@media screen and (min-width: 1350.1px) and (max-width: 767px) {
  .header-mega-nav-block-content-wrapper {
    gap: 1.375rem;
  }
}
@media (min-width: 1350.1px) {
  .header-mega-nav-block-content-wrapper:after {
    content: "";
    position: absolute;
    background: var(--classic-primary);
    width: 100%;
    height: 100%;
    right: 75%;
    z-index: 1;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-content-wrapper {
    padding-inline: 0;
  }
}
.header-mega-nav-block-content-item {
  display: flex;
  flex-flow: row;
  z-index: 2;
  width: 100%;
}
@media (max-width: 1350px) {
  .header-mega-nav-block-content-item {
    flex-flow: column;
    width: 100%;
  }
}
.header-mega-nav-block-cta .btn-primary {
  background-color: var(--jewel-primary);
  color: #000711;
}
@media (min-width: 1350.1px) {
  .header-mega-nav-block-cta .btn-primary {
    margin-top: 0.75rem;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-cta .btn-primary {
    margin-bottom: 0.75rem;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-block-cta .ph-arrow-right {
    display: none;
  }
}
@media (max-width: 1350px) {
  .header-mega-nav-link-item {
    border-bottom: solid 1px rgba(248, 245, 243, 0.14);
  }
}
.header-mega-nav-link-item .header-mega-nav-block-top-link i {
  transition: transform 0.3s;
}
@media (pointer: fine) {
  .header-mega-nav-link-item:hover .header-mega-nav-block-top-link i {
    transform: rotate(180deg);
  }
}
@media (pointer: fine) and (min-width: 1024.1px) {
  .header-mega-nav-link-item:hover .header-mega-nav-block-content {
    transform: scaleY(1);
  }
  .header-mega-nav-link-item:hover .header-mega-nav-block-content .header-mega-nav-block-content-wrapper {
    transform: scaleY(1);
  }
}
@media (pointer: fine) {
  .header-mega-nav-link-item:has(.tour-wrapper:hover) .header-mega-nav-block-top-link i {
    transform: rotate(0);
  }
}
@media (pointer: fine) and (min-width: 1350.1px) {
  .header-mega-nav-link-item:has(.tour-wrapper:hover) .header-mega-nav-block-content {
    transform: scaleY(0);
  }
  .header-mega-nav-link-item:has(.tour-wrapper:hover) .header-mega-nav-block-content .header-mega-nav-block-content-wrapper {
    transform: scaleY(0);
  }
}
.header-mega-nav-link-item:has(.trigger-enter-active) .header-mega-nav-block-top-link i {
  transform: rotate(180deg);
}
@media (max-width: 1350px) {
  .header-mega-nav-link-item:has(.trigger-enter-active) .header-mega-nav-block-content {
    height: auto;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
@media (min-width: 1350.1px) {
  .header-mega-nav-link-item:has(.trigger-enter-active) .header-mega-nav-block-content {
    transform: scaleY(1);
  }
  .header-mega-nav-link-item:has(.trigger-enter-active) .header-mega-nav-block-content .header-mega-nav-block-content-wrapper {
    transform: scaleY(1);
  }
}
.header-mega-nav-link-set-block {
  color: white;
}
.header-mega-nav-link-set-block + * {
  margin-top: 1rem;
}
.header-mega-nav-link-set-list {
  display: flex;
  flex-flow: column;
  gap: 0.375rem;
}
.header-mega-nav-link-set-title {
  margin-bottom: 0.375rem;
  display: block;
}
.mega-nav-highlight-block + * {
  margin-top: 1rem;
}
.mega-nav-highlight-block-header {
  margin-bottom: 0.375rem;
  display: block;
}
.mega-nav-highlight-block-cta {
  color: white;
  z-index: 3;
  text-align: left;
}
.mega-nav-highlight-block-description {
  color: white;
}
@media (max-width: 1350px) {
  .mega-nav-highlight-block-description {
    display: none;
  }
}
.mega-nav-highlight-block-image {
  margin-bottom: 0.25rem;
}
.mega-nav-highlight-block-content {
  gap: 0.25rem;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
@media (max-width: 1350px) {
  .mega-nav-highlight-block-content {
    max-width: 19.5rem;
  }
}
.mega-nav-highlight-block-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
@media (max-width: 640px) {
  .mega-nav-highlight-block-cta {
    width: 100%;
  }
  .mega-nav-highlight-block-cta .btn-primary {
    width: 100%;
  }
}
.mega-nav-highlight-block-cta .icon-link i {
  background-color: var(--jewel-primary);
  color: #fff;
}
@media (max-width: 640px) {
  .mega-nav-highlight-block-cta .icon-link {
    justify-content: space-between;
  }
}
.mega-nav-highlight-block-flex {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  z-index: 3;
  position: relative;
}
@media (max-width: 1350px) {
  .mega-nav-highlight-block-flex {
    width: 100%;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 7, 17, 0.18);
}

::-webkit-scrollbar-thumb {
  background-color: #000711;
  border-radius: 50px;
}

.footer {
  position: relative;
  z-index: 7;
}
.footer-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-template-rows: repeat(3, auto);
  gap: 2rem;
}
@media (max-width: 1024px) {
  .footer-container {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: repeat(4, auto);
  }
}
@media (max-width: 640px) {
  .footer-container {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-template-rows: repeat(6, auto);
  }
}
@media screen and (max-width: 1024px) {
  .footer-container {
    gap: 1.5rem;
  }
}
.footer-logo {
  max-width: 11.5rem;
}
.footer-column-links {
  margin-top: 1rem;
}
.footer-column-links ul {
  display: flex;
  flex-flow: column;
  gap: 0.75rem;
}
.footer-column-content {
  display: flex;
  flex-flow: column;
  gap: 0.75rem;
  margin-top: 1rem;
}
.footer-column-content .btn-secondary {
  display: inline-block;
  max-width: fit-content;
  --background: var(--jewel-primary);
}
.footer-column-address {
  display: grid;
  grid-template-columns: 20px auto;
}
.footer-column-address p {
  grid-column: 2;
}
.footer-socials {
  width: 100%;
  border-bottom: solid 1px var(--tab-border);
  padding-bottom: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  align-items: center;
}
.footer-social-link {
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
}
.footer-social-link i {
  display: block;
  padding: 2px;
  color: var(--dark-primary);
}
.footer-social-link svg {
  display: block;
  width: 20px;
  height: 20px;
}
.footer-social-link.facebook svg, .footer-social-link .linkedin svg {
  width: 26px;
  height: 26px;
}
.footer-logo, .footer-bottom {
  grid-column: 1/span 4;
}
@media (max-width: 1024px) {
  .footer-logo, .footer-bottom {
    grid-column: 1/span 2;
  }
}
@media (max-width: 640px) {
  .footer-logo, .footer-bottom {
    grid-column: 1;
  }
}
.footer .footer-bottom {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 0.5rem;
}
.footer .footer-bottom-links ul {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
@media (min-width: 640.1px) {
  .footer .footer-bottom-copyright {
    max-width: 50%;
  }
}

.breadcrumbs .content-container {
  padding-block: 1rem;
}
@media screen and (max-width: 640px) {
  .breadcrumbs .content-container {
    padding-block: 0.5rem;
  }
}
.breadcrumbs-divider {
  margin-inline: 0.25rem;
  line-height: 1.125;
}

.breadcrumb-page-name {
  line-height: 1.125;
}

.home-page .breadcrumbs {
  display: none;
}

@media print {
  /* All your print styles go here */
  div#lhnHocButton {
    display: none !important;
  }
  .atlwdg-trigger {
    display: none !important;
  }
  .article-detail-social, .article-detail-recommendations, .advertisement-block {
    display: none !important;
  }
  body h1 {
    font-size: 2.375rem;
    line-height: 1.16095;
    font-size: var(--medium-text);
    line-height: var(--medium-text-height);
  }
  body h2 {
    font-size: 2.375rem;
    line-height: 1.16095;
    font-size: var(--medium-text);
    line-height: var(--medium-text-height);
  }
  body h3 {
    font-size: 2.375rem;
    line-height: 1.16095;
    font-size: var(--medium-text);
    line-height: var(--medium-text-height);
  }
}
.quick-nav {
  position: relative;
  border-left-color: var(--input-background);
  border-left-style: solid;
  border-left-width: 1px;
  padding-left: 1rem;
}
.quick-nav.utility-nav-link-quick-tablet {
  border-left: 0;
  padding-left: 0;
}
.quick-nav:hover .quick-nav-links {
  display: block;
}
.quick-nav:hover .quick-nav-collapsed {
  display: none;
}
.quick-nav-button {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 0.25rem;
  color: var(--dark-primary);
  text-decoration: none;
  font-weight: 700;
}
.quick-nav-link {
  font-size: 0.875rem;
  line-height: 1.42857;
  font-size: var(--x-small-text);
  line-height: var(--x-small-text-height);
  font-family: "AcherusGrotesque", Arial, sans-serif;
  font-weight: 600;
}
.quick-nav-link-item + .quick-nav-link-item {
  margin-top: 0.5rem;
}
.quick-nav-links {
  display: none;
  position: absolute;
  z-index: 1000;
  background: var(--classic-primary);
  padding: 1rem;
  min-width: 200px;
  right: 0;
  color: var(--white);
  border-top: solid 0.5rem;
}
.quick-nav-links.quick-nav-expanded {
  display: block;
}
.quick-nav-links svg {
  position: absolute;
  right: 1rem;
  bottom: 100%;
  fill: var(--classic-primary);
}
.quick-nav-links-mobile {
  display: none;
  padding-inline: 1rem;
  padding-block: 1.375rem;
  background-color: var(--classic-primary);
  flex-flow: column;
  gap: 0.75rem;
  color: var(--white);
}
@media (max-width: 640px) {
  .quick-nav-links-mobile.quick-nav-expanded {
    display: flex;
  }
}
.quick-nav-container-mobile {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 0.75rem;
}
.quick-nav-container-mobile:has(.utility-nav-link-sister-mobile) {
  width: 100%;
}
.quick-nav-container-mobile .quick-nav {
  border-left: 0;
  padding-left: 0;
}
.quick-nav-container-mobile .quick-nav-button {
  color: var(--jewel-primary);
}

*:not(label) > input[type=checkbox],
*:not(label) > input[type=radio] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
*:not(label) > input[type=checkbox]:focus,
*:not(label) > input[type=radio]:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

input[type=checkbox]:focus + label, input[type=checkbox]:focus + input[type=hidden] + label,
input[type=radio]:focus + label,
input[type=radio]:focus + input[type=hidden] + label {
  box-shadow: #fff 0 0 0 2px, #000711 0 0 0 4px;
  box-shadow: var(--background-color, #fff) 0 0 0 2px, var(--focus-ring-color, #000711) 0 0 0 4px;
}
input[type=checkbox] + label, input[type=checkbox] + input[type=hidden] + label,
input[type=radio] + label,
input[type=radio] + input[type=hidden] + label {
  outline: none;
  transition: 0.2s all;
  position: relative;
  display: inline-block;
  margin-left: 0;
}
input[type=checkbox] + label:focus, input[type=checkbox] + input[type=hidden] + label:focus,
input[type=radio] + label:focus,
input[type=radio] + input[type=hidden] + label:focus {
  box-shadow: #fff 0 0 0 2px, #000711 0 0 0 4px;
  box-shadow: var(--background-color, #fff) 0 0 0 2px, var(--focus-ring-color, #000711) 0 0 0 4px;
}
input[type=checkbox] + label::before, input[type=checkbox] + input[type=hidden] + label::before,
input[type=radio] + label::before,
input[type=radio] + input[type=hidden] + label::before {
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.05em;
}
input[type=checkbox]:checked + label::after, input[type=checkbox]:checked + input[type=hidden] + label::after,
input[type=radio]:checked + label::after,
input[type=radio]:checked + input[type=hidden] + label::after {
  position: absolute;
  display: inline-block;
}
input[type=checkbox]:hover + label::before, input[type=checkbox]:hover + input[type=hidden] + label::before, input[type=checkbox]:focus + label::before, input[type=checkbox]:focus + input[type=hidden] + label::before, input[type=checkbox]:checked + label::before, input[type=checkbox]:checked + input[type=hidden] + label::before,
input[type=radio]:hover + label::before,
input[type=radio]:hover + input[type=hidden] + label::before,
input[type=radio]:focus + label::before,
input[type=radio]:focus + input[type=hidden] + label::before,
input[type=radio]:checked + label::before,
input[type=radio]:checked + input[type=hidden] + label::before {
  color: var(--foreground);
}
input[type=checkbox]:hover + label, input[type=checkbox]:hover + input[type=hidden] + label, input[type=checkbox]:focus + label, input[type=checkbox]:focus + input[type=hidden] + label,
input[type=radio]:hover + label,
input[type=radio]:hover + input[type=hidden] + label,
input[type=radio]:focus + label,
input[type=radio]:focus + input[type=hidden] + label {
  color: var(--foreground);
}
input[type=checkbox][disabled] + label, input[type=checkbox][disabled] + input[type=hidden] + label,
input[type=radio][disabled] + label,
input[type=radio][disabled] + input[type=hidden] + label {
  color: var(--button-disabled-foreground);
}
input[type=checkbox][disabled] + label::before, input[type=checkbox][disabled] + input[type=hidden] + label::before,
input[type=radio][disabled] + label::before,
input[type=radio][disabled] + input[type=hidden] + label::before {
  border-color: var(--button-disabled-foreground);
}

input[type=radio] + label, input[type=radio] + input[type=hidden] + label,
input[type=checkbox] + label,
input[type=checkbox] + input[type=hidden] + label {
  padding-left: 2rem;
}
input[type=radio] + label::before, input[type=radio] + input[type=hidden] + label::before,
input[type=checkbox] + label::before,
input[type=checkbox] + input[type=hidden] + label::before {
  font-size: 1.5rem;
  font-family: "Phosphor-Light" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
input[type=radio]:checked + label::before, input[type=radio]:checked + input[type=hidden] + label::before,
input[type=checkbox]:checked + label::before,
input[type=checkbox]:checked + input[type=hidden] + label::before {
  color: var(--foreground);
}

input[type=checkbox] + label::before, input[type=checkbox] + input[type=hidden] + label::before {
  content: "\e45e";
}
input[type=checkbox]:checked + label::before, input[type=checkbox]:checked + input[type=hidden] + label::before {
  content: "\e186";
}

input[type=radio] + label::before, input[type=radio] + input[type=hidden] + label::before {
  content: "\e18a";
}
input[type=radio]:checked + label::before, input[type=radio]:checked + input[type=hidden] + label::before {
  content: "\e184";
}

.radiocheck-offset {
  margin-left: -0.05em;
}

.EPiServerForms .Form__Element select, .EPiServerForms .Form__Element textarea, .EPiServerForms .Form__Element input:not([type=checkbox]):not([type=radio]):not([type=reset]), select, textarea, input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  font-size: 1rem;
  line-height: 1.125;
  padding: 0.625rem;
  width: 100%;
  max-width: 39.5rem;
  border: none;
  border-radius: 0;
  font-family: "AcherusGrotesque", Arial, sans-serif;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0);
  color: var(--input-color);
  background: var(--input-background);
  outline-offset: 0;
  outline: none;
  scroll-margin-top: 2rem;
}
.EPiServerForms .Form__Element select:focus, .EPiServerForms .Form__Element textarea:focus, .EPiServerForms .Form__Element input:focus:not([type=checkbox]):not([type=radio]):not([type=reset]), select:focus, textarea:focus, input:focus:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  border-color: var(--input-background);
  outline: none;
}
.EPiServerForms .Form__Element select[disabled], .EPiServerForms .Form__Element textarea[disabled], .EPiServerForms .Form__Element input[disabled]:not([type=checkbox]):not([type=radio]):not([type=reset]), select[disabled], textarea[disabled], input[disabled]:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  cursor: not-allowed;
  background-color: var(--input-background-disabled);
  color: var(--input-disabled);
}
.EPiServerForms .Form__Element select[disabled]:hover, .EPiServerForms .Form__Element textarea[disabled]:hover, .EPiServerForms .Form__Element input[disabled]:hover:not([type=checkbox]):not([type=radio]):not([type=reset]), select[disabled]:hover, textarea[disabled]:hover, input[disabled]:hover:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  border-color: var(--input-background-disabled);
}
.EPiServerForms .Form__Element select[aria-invalid=true], .EPiServerForms .Form__Element textarea[aria-invalid=true], .EPiServerForms .Form__Element input[aria-invalid=true]:not([type=checkbox]):not([type=radio]):not([type=reset]), select[aria-invalid=true], textarea[aria-invalid=true], input[aria-invalid=true]:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  border-color: var(--input-color-disabled);
}

.form-element-description {
  padding-left: 2rem;
  margin-top: 0.5rem;
}

::placeholder {
  color: var(--input-color-disabled);
  font-style: italic;
}

submit, input[type=submit] {
  font-family: "AcherusGrotesque", Arial, sans-serif;
}

.label-hint {
  font-style: italic;
}

.required {
  color: var(--input-color);
  position: relative;
  vertical-align: baseline;
  top: 0.025em;
  left: 0.125em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

label {
  display: block;
}

input[type=checkbox] + label,
input[type=radio] + label {
  display: inline-block;
  line-height: 1.375;
}

.EPiServerForms .Form__Element[type=submit] {
  vertical-align: middle;
}
.EPiServerForms .Form__Element[type=reset] {
  vertical-align: middle;
  margin-right: 1rem;
}
.EPiServerForms .Form__Element .FormChoice .FormChoice__Input:focus + .FormChoice__Label {
  border: none;
}
.EPiServerForms .Form__Element .FormChoice fieldset .choice-fieldset .input-container > div {
  margin-block: 1rem;
}
.EPiServerForms .Form__Element .FormFileUpload input {
  background-color: transparent !important;
}
.EPiServerForms .Form__Element .FormChoice--Image .FormChoice--Image__Item {
  max-width: 100%;
}
.EPiServerForms .Form__Element .FormChoice--Image .FormChoice--Image__Item label {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  overflow-wrap: anywhere;
}
.EPiServerForms .Form__Element .FormChoice--Image .input-container {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
.EPiServerForms .Form__Element .FormChoice--Image .input-container img {
  max-width: 250px;
}
@media (max-width: 400px) {
  .EPiServerForms .Form__Element .FormChoice--Image .input-container img {
    max-width: 100%;
  }
}
.EPiServerForms .Form__Element:not([type=submit]):not([type=reset]):not(.FormStep), .EPiServerForms .Form__Element > div[lang=en] {
  display: grid;
  width: 100%;
}
.EPiServerForms .Form__Element label:not(.FormChoice__Label), .EPiServerForms .Form__Element legend {
  font-weight: 700;
}
.EPiServerForms .Form__Element option {
  background-color: var(--background-color);
}
.EPiServerForms .Form__Element option:hover, .EPiServerForms .Form__Element option :active {
  background-color: var(--input-background);
}
.EPiServerForms .Form__Element .FormCaptcha {
  display: flex !important;
  flex-flow: column;
  gap: 1rem;
}
.EPiServerForms .Form__Element .FormCaptcha input {
  order: 1;
}
.EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Refresh {
  order: 3;
  max-width: 39.5rem;
}
.EPiServerForms .Form__Element .FormCaptcha .FormCaptcha__Image {
  order: 2;
  max-width: 39.5rem;
}
.EPiServerForms .Form__Element .Form__Element__Caption:has(+ .form-tooltip) {
  min-width: auto;
}
.EPiServerForms .FormStep .FormStep__Description, .EPiServerForms .Form__Description {
  margin-top: 0.5rem;
}
.EPiServerForms .ValidationRequired .Form__Element__Caption:after {
  content: " *" !important;
  color: var(--alert-red);
}
.EPiServerForms .ValidationFail {
  color: var(--input-color);
}
.EPiServerForms .ValidationFail input {
  color: var(--input-color);
}
.EPiServerForms .ValidationFail .Form__Element__Caption:after {
  color: var(--alert-red);
}
.EPiServerForms .ValidationFail .Form__Element__ValidationError {
  color: var(--alert-red);
}
.EPiServerForms .ValidationFail:not(.FormChoice) label {
  color: var(--input-color);
}
.EPiServerForms .ValidationFail.FormChoice legend {
  color: var(--input-color);
}
.EPiServerForms .ValidationFail.FormChoice label {
  color: var(--input-color);
}
.EPiServerForms .Form__Success .Form__Success__Message, .EPiServerForms div.Form__Status .Form__Success__Message {
  background-color: var(--background-color);
  color: var(--foreground);
}
.EPiServerForms nav.Form__NavigationBar {
  max-width: 39.5rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1024px) {
  .EPiServerForms nav.Form__NavigationBar {
    margin-top: 1.5rem;
  }
}
@media (max-width: 640px) {
  .EPiServerForms nav.Form__NavigationBar {
    justify-content: space-between;
  }
}
.EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__ProgressBar {
  border: 1px solid var(--input-background);
  background-color: var(--input-background);
  flex-grow: 1;
}
@media (max-width: 640px) {
  .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__ProgressBar {
    width: 100%;
    order: 1;
  }
}
.EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Progress {
  background-color: var(--button-primary-background);
}
.EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action {
  background-image: none;
  border-radius: 0;
  height: auto;
  width: auto;
  max-width: 100%;
}
@media (max-width: 640px) {
  .EPiServerForms nav.Form__NavigationBar .Form__NavigationBar__Action {
    order: 2;
  }
}
.EPiServerForms div.Form__Status .Form__Warning__Message {
  background-color: var(--secondary-background);
  color: var(--alert-red);
}
.EPiServerForms .Form__Title {
  margin-bottom: 1.375rem;
}

.form-tooltip {
  display: inline-block;
  position: relative;
}
.form-tooltip-content {
  display: none;
  position: absolute;
  background: #fff;
  color: var(--black);
  z-index: 999;
  padding: 0.5rem 1rem;
  bottom: 0rem;
  left: 2rem;
  width: max-content;
  max-width: 50vw;
  box-shadow: -1px 1px 4px 0px var(--jewel-tertiary);
}
.form-tooltip:has(.form-tooltip-icon:hover) .form-tooltip-content, .form-tooltip:has(.form-tooltip-icon:active) .form-tooltip-content {
  display: block;
}
.form-tooltip-icon {
  color: var(--button-primary-background);
}

.choice-fieldset {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}

.input-container {
  width: 100%;
}

.EPiServerForms section.Form__Element > .block.displaymode-two-thirds select, .EPiServerForms section.Form__Element > .block.displaymode-three-quarters select, .EPiServerForms section.Form__Element > .block.displaymode-one-quarter select, .EPiServerForms section.Form__Element > .block.displaymode-one-third select, .EPiServerForms section.Form__Element > .block.displaymode-half select, .EPiServerForms section.Form__Element > .block.displaymode-full select, .EPiServerForms section.Form__Element > .block.displaymode-two-thirds textarea, .EPiServerForms section.Form__Element > .block.displaymode-three-quarters textarea, .EPiServerForms section.Form__Element > .block.displaymode-one-quarter textarea, .EPiServerForms section.Form__Element > .block.displaymode-one-third textarea, .EPiServerForms section.Form__Element > .block.displaymode-half textarea, .EPiServerForms section.Form__Element > .block.displaymode-full textarea, .EPiServerForms section.Form__Element > .block.displaymode-two-thirds input:not([type=checkbox]):not([type=radio]):not([type=reset]), .EPiServerForms section.Form__Element > .block.displaymode-three-quarters input:not([type=checkbox]):not([type=radio]):not([type=reset]), .EPiServerForms section.Form__Element > .block.displaymode-one-quarter input:not([type=checkbox]):not([type=radio]):not([type=reset]), .EPiServerForms section.Form__Element > .block.displaymode-one-third input:not([type=checkbox]):not([type=radio]):not([type=reset]), .EPiServerForms section.Form__Element > .block.displaymode-half input:not([type=checkbox]):not([type=radio]):not([type=reset]), .EPiServerForms section.Form__Element > .block.displaymode-full input:not([type=checkbox]):not([type=radio]):not([type=reset]) {
  max-width: 100%;
}

.EPiServerForms section.Form__Element {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1.38rem;
}
.EPiServerForms section.Form__Element > .block {
  width: 100%;
}
.EPiServerForms section.Form__Element > .block.displaymode-full {
  width: 100%;
}
.EPiServerForms section.Form__Element > .block.displaymode-half {
  width: calc(50% - 0.69rem);
}
@media (max-width: 640px) {
  .EPiServerForms section.Form__Element > .block.displaymode-half {
    width: 100%;
  }
}
.EPiServerForms section.Form__Element > .block.displaymode-one-third {
  width: calc(33.33% - 0.92rem);
}
@media (max-width: 640px) {
  .EPiServerForms section.Form__Element > .block.displaymode-one-third {
    width: 100%;
  }
}
.EPiServerForms section.Form__Element > .block.displaymode-one-quarter {
  width: calc(25% - 1.1rem);
}
@media (max-width: 640px) {
  .EPiServerForms section.Form__Element > .block.displaymode-one-quarter {
    width: 100%;
  }
}
.EPiServerForms section.Form__Element > .block.displaymode-three-quarters {
  width: calc(75% - 0.69rem);
}
@media (max-width: 640px) {
  .EPiServerForms section.Form__Element > .block.displaymode-three-quarters {
    width: 100%;
  }
}
.EPiServerForms section.Form__Element > .block.displaymode-two-thirds {
  width: calc(66.66% - 0.46rem);
}
@media (max-width: 640px) {
  .EPiServerForms section.Form__Element > .block.displaymode-two-thirds {
    width: 100%;
  }
}

input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent !important;
  cursor: pointer;
}

/* Removes default focus */
input[type=range]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--input-background);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -0.25rem; /* Centers thumb on the track */
  /*custom styles*/
  background-color: var(--input-color);
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
}

input[type=range]:focus::-webkit-slider-thumb {
  border: 1px solid var(--input-background);
  outline: 3px solid var(--input-background);
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type=range]::-moz-range-track {
  background-color: var(--input-background);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type=range]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0.5rem; /*Removes default border-radius that FF applies*/
  /*custom styles*/
  background-color: var(--input-color);
  height: 1rem;
  width: 1rem;
}

input[type=range]::focus:-moz-range-thumb {
  border: 1px solid var(--input-color);
  outline: 3px solid var(--input-color);
  outline-offset: 0.125rem;
}

.EPiServerForms .FormRange .FormRange__Wrapper {
  max-width: 39.5rem;
  width: 100% !important;
}
.EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Wrapper {
  align-items: center;
}
.EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button {
  background-color: var(--button-primary-background) !important;
  color: var(--button-primary-foreground) !important;
}
.EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Slider__Button.FormRange__Slider__Button__Disable {
  background-color: var(--button-disabled-background) !important;
  color: var(--button-disabled-foreground) !important;
}

.component-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
}
.component-header-description {
  margin-top: 1.375rem;
}
.component-header-wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
}
@media screen and (max-width: 1024px) {
  .component-header-wrapper {
    gap: 0.75rem;
  }
}
@media (min-width: 1280.1px) {
  .component-header-wrapper {
    max-width: 60%;
  }
}
@media (min-width: 1280.1px) {
  .component-header.right {
    justify-content: flex-end;
  }
}
.component-header.right .component-header-wrapper {
  width: auto;
}
.component-header.center {
  text-align: center;
  justify-content: center;
}
.component-header.center .component-header-wrapper {
  flex-flow: column;
  align-items: center;
}
@media (max-width: 400px) {
  .component-header-title {
    overflow-wrap: anywhere;
  }
}

.static-header {
  position: relative;
}
.static-header .svg-background {
  z-index: 0;
}
.static-header .content-container {
  z-index: 2;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 2rem;
}
@media (max-width: 640px) {
  .grid {
    gap: 1rem;
  }
}
.grid-col-1 {
  grid-template-columns: minmax(0, 1fr);
  place-items: center center;
}
.grid-col-1-centered {
  place-items: center center;
  text-align: center;
  margin: 0 auto;
}
.grid-eq-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-eq-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-eq-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-6-3-3 {
  grid-template-columns: minmax(0, calc(50% - var(--gutter-h) / 3)) repeat(2, minmax(0, 1fr));
}
.grid-3-3-6 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) minmax(0, calc(50% - var(--gutter-h) / 3));
}
.grid-8-4 {
  grid-template-columns: minmax(0, calc(66.6666666667% - var(--gutter-h) / 4)) minmax(0, 1fr);
}
.grid-4-8 {
  grid-template-columns: minmax(0, 1fr) minmax(0, calc(66.6666666667% - var(--gutter-h) / 4));
}
@media (min-width: 640.1px) and (max-width: 1024px) {
  .grid-eq-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-6-3-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-6-3-3 > :first-child {
    grid-column: span 2;
  }
  .grid-3-3-6 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-3-3-6 > :last-child {
    grid-column: span 2;
  }
}
@media (max-width: 640px) {
  .grid-eq-2, .grid-eq-3, .grid-eq-4, .grid-6-3-3, .grid-3-3-6, .grid-8-4, .grid-4-8 {
    grid-template-columns: minmax(0, 1fr);
  }
}

.content-container {
  padding-block: 4rem;
}
@media screen and (max-width: 1024px) {
  .content-container {
    padding-block: 2rem;
  }
}
.content-container .content-container {
  padding-inline: 0;
  padding-block: 0;
  max-width: 100%;
  margin-inline: initial;
}

.pagination {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  gap: 1rem;
  justify-content: center;
}
.pagination-pages {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  align-items: baseline;
}
@media (max-width: 640px) {
  .pagination-pages {
    order: 1;
    width: 100%;
    justify-content: center;
    gap: 0.75rem;
  }
}
.pagination-buttons {
  display: flex;
  flex-flow: row wrap;
  gap: 6px;
}
@media (max-width: 640px) {
  .pagination-buttons {
    order: 2;
    width: calc(50% - 0.5rem);
    gap: 0.75rem;
  }
  .pagination-buttons:last-child {
    justify-content: flex-end;
  }
}
.pagination-page-button {
  --button-primary-background: var(--input-background);
  --button-primary-foreground: var(--foreground);
}
@media (max-width: 640px) {
  .pagination-page-button {
    padding: 0.5rem 0.75rem;
  }
}
.pagination-page-button.selected {
  --button-disabled-background: var(--button-selected);
  --button-disabled-foreground: var(--foreground);
  border: solid 1px currentcolor;
}

.audio-player-container {
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%;
  position: relative;
  width: 95%;
  max-width: 500px;
  background: var(--accordion-background);
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
}
.audio-player-container input[type=range] {
  position: relative;
  -webkit-appearance: none;
  max-width: calc(70% - 0.5rem) !important;
  margin: 0 !important;
  padding: 0 !important;
  margin: 30px 2.5% 20px 2.5%;
  outline: none;
}
.audio-player-container input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: linear-gradient(to right, rgba(0, 7, 17, 0.42) var(--buffered-width), rgba(0, 7, 17, 0.42) var(--buffered-width));
}
.audio-player-container input[type=range]::before {
  position: absolute;
  content: "";
  top: calc(50% - 1.5px);
  left: 0;
  width: var(--seek-before-width);
  height: 3px;
  background-color: var(--white);
  cursor: pointer;
}
.audio-player-container input[type=range]::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  border: 1px solid var(--white);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: var(--white);
  cursor: pointer;
  margin: -7px 0 0 0;
}
.audio-player-container input[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--white);
}
.audio-player-container input[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: linear-gradient(to right, rgba(0, 7, 17, 0.42) var(--buffered-width), rgba(0, 7, 17, 0.42) var(--buffered-width));
}
.audio-player-container input[type=range]::-moz-range-progress {
  background-color: var(--white);
}
.audio-player-container input[type=range]::-moz-focus-outer {
  border: 0;
}
.audio-player-container input[type=range]::-moz-range-thumb {
  box-sizing: content-box;
  border: 1px solid var(--white);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.audio-player-container input[type=range]:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--white);
}
.audio-player-container input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border: solid transparent;
  color: transparent;
}
.audio-player-container input[type=range]::-ms-fill-lower {
  background-color: var(--white);
}
.audio-player-container input[type=range]::-ms-fill-upper {
  background: linear-gradient(to right, rgba(0, 7, 17, 0.42) var(--buffered-width), rgba(0, 7, 17, 0.42) var(--buffered-width));
}
.audio-player-container input[type=range]::-ms-thumb {
  box-sizing: content-box;
  border: 1px solid #007db5;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.audio-player-container input[type=range]:active::-ms-thumb {
  transform: scale(1.2);
  background: var(--white);
}
.audio-player-container output {
  display: inline-block;
}
.audio-player-container input[type=range].volume-slider {
  margin: 10px 2.5%;
  width: 58%;
}
.audio-player-container input[type=range].volume-slider::-webkit-slider-runnable-track {
  background: rgba(0, 7, 17, 0.42);
}
.audio-player-container input[type=range].volume-slider:-moz-range-track {
  background: rgba(0, 7, 17, 0.42);
}
.audio-player-container input[type=range].volume-slider::-ms-fill-upper {
  background: rgba(0, 7, 17, 0.42);
}
.audio-player-container input[type=range].volume-slider:before {
  width: var(--volume-before-width);
}
.audio-player-container button {
  background-color: var(--white);
  width: 3.25rem;
  height: 3.25rem;
  text-align: center;
  max-width: calc(15% - 0.5rem) !important;
}
.audio-player-container button:hover, .audio-player-container button:focus {
  background-color: var(--black);
}
.audio-player-container button:hover i, .audio-player-container button:focus i {
  color: var(--white);
}
@media (max-width: 767px) {
  .audio-player-container button {
    width: 2.75rem;
    height: 2.75rem;
  }
}
.audio-player-container button i {
  line-height: 3.25rem;
  font-size: 1.5rem;
  color: var(--black);
}
@media (max-width: 767px) {
  .audio-player-container button i {
    line-height: 2.75rem;
    font-size: 1rem;
  }
}
.audio-player-container .mute-icon .ph-speaker-x {
  display: none;
}
.audio-player-container .mute-icon.muted .ph-speaker-high {
  display: none;
}
.audio-player-container .mute-icon.muted .ph-speaker-x {
  display: block;
}
.audio-player-container .play-icon .ph-play {
  display: none;
}
.audio-player-container .play-icon.pause .ph-play {
  display: block;
}
.audio-player-container .play-icon.pause .ph-pause {
  display: none;
}
.audio-player-bottom, .audio-player-top {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  gap: 0.75rem;
}
.audio-player-bottom {
  margin-top: 1rem;
}
.audio-player-time {
  max-width: calc(15% - 0.5rem) !important;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -2.1875rem;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -7.75rem;
  }
}
@keyframes color {
  100%, 0% {
    stroke: currentColor;
  }
  40% {
    stroke: currentColor;
  }
  66% {
    stroke: currentColor;
  }
  80%, 90% {
    stroke: currentColor;
  }
}
.loader {
  margin-block: 1.375rem;
  margin-inline: auto;
}
.loader-circle {
  position: relative;
  margin: 0 auto;
  width: 6.25rem;
}
.loader-circle:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.loader-circle .spin, .loader-circle .base {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader-circle .spin {
  z-index: 20;
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
}
.loader-circle .spin .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 89, 200;
  stroke-dashoffset: -17.75rem;
  stroke: currentColor;
}
.loader-circle .base {
  z-index: 10;
}
.loader-circle .base .path {
  stroke: currentColor;
}
.loader-circle .spin {
  color: var(--light-tertiary);
}
.loader-circle .base {
  color: var(--classic-primary);
}

.button-loader span {
  color: currentColor;
}
.button-loader .loader {
  display: none;
}
.button-loader .loader.loading {
  display: block;
}
.button-loader .loader-circle {
  width: 1rem;
  margin-left: 1rem;
}
.button-loader .loader-circle .spin .path {
  stroke-width: 5;
}
.button-loader .loader-circle .base .path {
  stroke-width: 5;
}

.tour-element {
  position: absolute;
  background-color: var(--jewel-primary);
  padding: 0.5rem 0.56rem 0.44rem;
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  gap: 0.5rem;
  width: max-content;
  max-width: 50vw;
  color: #000711;
  font-size: 0.875rem;
  line-height: 1.42857;
  font-size: var(--x-small-text);
  line-height: var(--x-small-text-height);
  font-weight: 700;
  font-family: "AcherusGrotesque", Arial, sans-serif;
}
@media (max-width: 640px) {
  .tour-element {
    width: auto;
    max-width: 80vw;
  }
}
.tour-element.top {
  bottom: calc(100% - 0.15rem);
}
.tour-element.top .tour-element-caret {
  top: calc(100% - 1px);
  transform: rotate(180deg);
}
.tour-element.bottom {
  top: calc(100% - 0.15rem);
}
.tour-element.bottom .tour-element-caret {
  bottom: calc(100% - 1px);
}
.tour-element.side .tour-element-caret {
  left: 1rem;
}
.tour-element.middle {
  left: 50%;
  transform: translateX(-50%);
}
.tour-element.middle .tour-element-caret {
  left: calc(50% - 6px);
}
.tour-element button {
  color: #000711;
}
.tour-element-caret {
  position: absolute;
  width: 12px;
}
.tour-wrapper {
  width: 100%;
  height: 0;
  position: relative;
  z-index: 998;
}

.main-content > .row > .wysiwyg-block, .main-content > .row > form, .tabs-panel > .wysiwyg-block, .tabs-panel > form, .content-detail-page-main-content > .wysiwyg-block, .content-detail-page-main-content > form, .event-detail-top-content-area > .wysiwyg-block, .event-detail-top-content-area > form, .event-detail-bottom-content-area > .wysiwyg-block, .event-detail-bottom-content-area > form, .article-detail-content-bottom > .row > .wysiwyg-block, .article-detail-content-bottom > .row > form, .article-detail-content-top > .row > .wysiwyg-block, .article-detail-content-top > .row > form, .directory-top-content > .wysiwyg-block, .directory-top-content > form, .directory-bottom-content > .wysiwyg-block, .directory-bottom-content > form {
  padding-block: 4rem;
}
@media screen and (max-width: 1024px) {
  .main-content > .row > .wysiwyg-block, .main-content > .row > form, .tabs-panel > .wysiwyg-block, .tabs-panel > form, .content-detail-page-main-content > .wysiwyg-block, .content-detail-page-main-content > form, .event-detail-top-content-area > .wysiwyg-block, .event-detail-top-content-area > form, .event-detail-bottom-content-area > .wysiwyg-block, .event-detail-bottom-content-area > form, .article-detail-content-bottom > .row > .wysiwyg-block, .article-detail-content-bottom > .row > form, .article-detail-content-top > .row > .wysiwyg-block, .article-detail-content-top > .row > form, .directory-top-content > .wysiwyg-block, .directory-top-content > form, .directory-bottom-content > .wysiwyg-block, .directory-bottom-content > form {
    padding-block: 2rem;
  }
}

.wysiwyg-block .rich-text:has(table) {
  max-width: 100%;
  overflow-x: scroll;
}
.wysiwyg-block .rich-text:has(table) table {
  margin-bottom: 1.375rem;
}
.wysiwyg-block-full .row, .wysiwyg-block-full .wysiwyg-block {
  width: 100%;
}
.wysiwyg-block .rich-text ul, .wysiwyg-block .rich-text ol {
  margin-left: 1rem;
}
.wysiwyg-block .rich-text .btn-primary + .btn-primary, .wysiwyg-block .rich-text .btn-primary + .btn-secondary, .wysiwyg-block .rich-text .btn-secondary + .btn-primary, .wysiwyg-block .rich-text .btn-secondary + .btn-secondary {
  margin-left: 1.375rem;
}
@media (max-width: 640px) {
  .wysiwyg-block .rich-text .btn-primary + .btn-primary, .wysiwyg-block .rich-text .btn-primary + .btn-secondary, .wysiwyg-block .rich-text .btn-secondary + .btn-primary, .wysiwyg-block .rich-text .btn-secondary + .btn-secondary {
    margin-top: 1rem;
  }
}

.media-heading-image img {
  width: 100%;
}
.media-heading-text {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  height: 100%;
  gap: 0.5rem;
  word-wrap: break-word;
}
.media-heading-text-subtitle .chip-subtitle {
  display: inline-block;
}
.media-heading-text-links {
  display: flex;
  flex-flow: row wrap;
  gap: 6px;
  margin-top: 2rem;
}
@media screen and (max-width: 1024px) {
  .media-heading-text-links {
    margin-top: 0.75rem;
  }
}
@media (max-width: 640px) {
  .media-heading-text-links .primary, .media-heading-text-links .secondary {
    width: 100%;
  }
  .media-heading-text-links .primary > a, .media-heading-text-links .secondary > a {
    width: 100%;
  }
}
.media-heading-text-content {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}
.media-heading-text-description {
  margin-top: 0.5rem;
}

.fifty-fifty-block {
  position: relative;
}
.fifty-fifty-block .svg-background {
  width: 100%;
  height: 100%;
}
@media (max-width: 640px) {
  .fifty-fifty-block .svg-background {
    width: 100%;
  }
}
.fifty-fifty-block .svg-background.svg-align-center .svg-background-svg {
  left: 30%;
}
@media (max-width: 640px) {
  .fifty-fifty-block .svg-background.svg-align-center .svg-background-svg {
    left: 0;
  }
}
.fifty-fifty-block .svg-background.svg-align-left .svg-background-svg {
  left: 50%;
}
@media (max-width: 640px) {
  .fifty-fifty-block .svg-background.svg-align-left .svg-background-svg {
    left: 0;
  }
}
.fifty-fifty-block .svg-background.svg-align-right .svg-background-svg {
  left: 0;
}
.fifty-fifty-container {
  position: relative;
  z-index: 2;
}
.fifty-fifty-container.default-width .media-heading-image img {
  width: auto;
}
.fifty-fifty-container.default-width .fifty-fifty-right .media-heading-image {
  display: flex;
  justify-content: flex-end;
}
.fifty-fifty-container.grid-col-1 .fifty-fifty-left {
  width: 100%;
  display: grid;
}
@media (min-width: 1280.1px) {
  .fifty-fifty-container.grid-col-1 .fifty-fifty-left .media-heading-text {
    max-width: 60%;
  }
}
.fifty-fifty-container.grid-col-1 .fifty-fifty-left > .center {
  justify-self: center;
  text-align: center;
}
.fifty-fifty-container.grid-col-1 .fifty-fifty-left > .center .media-heading-text-links {
  justify-content: center;
}
.fifty-fifty-container.grid-col-1 .fifty-fifty-left > .left {
  justify-self: flex-start;
}
.fifty-fifty-container.grid-col-1 .fifty-fifty-left > .right {
  justify-self: flex-end;
}

.listing-item {
  padding: 1.5rem;
  flex-grow: 1;
  min-width: 17.5rem;
  background-color: var(--card-background);
  border: solid 1px var(--card-border);
  display: flex;
  flex-flow: column;
  gap: 0.75rem;
  width: calc(25% - 3rem);
}
@media (max-width: 640px) {
  .listing-item {
    width: 100%;
  }
}
.listing-item-category {
  display: inline-block;
}
.listing-item-category + .listing-item-category {
  display: none;
}
.listing-item-categories {
  display: flex;
  flex-flow: row wrap;
  gap: 0.75rem;
}
.listing-item-info {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;
}
.listing-container-block-content {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1024px) {
  .listing-container-block-content {
    gap: 1.5rem;
    margin-top: 1.5rem;
  }
}

.accordion {
  margin-left: auto;
  margin-right: auto;
}
.accordion-panel {
  overflow: hidden;
  padding-inline: 1.375rem;
}
@media screen and (max-width: 640px) {
  .accordion-panel {
    padding-inline: 1rem;
  }
}
.accordion-panel > :last-child {
  margin-bottom: 0.75rem;
}
.accordion-panel[aria-hidden=true] {
  height: 0 !important;
}
@media (max-width: 1024px) {
  .accordion-container.grid {
    grid-template-columns: 1fr;
  }
}
.accordion-list {
  padding-left: 0;
  margin-top: 1rem;
}
@media (min-width: 1024.1px) {
  .accordion-list {
    margin-top: 1.375rem;
  }
}
.accordion-list-item + .accordion-list-item {
  margin-top: 0.75rem;
}
.accordion-icon {
  height: 1.375rem;
  width: 1.375rem;
  font-size: 1.375rem;
  text-align: center;
  place-content: center;
  display: flex;
  background-color: var(--accordion-icon-background);
  color: var(--accordion-icon-color);
}
.accordion-image {
  height: 1.375rem;
  width: 1.375rem;
  margin-right: 0.5rem;
}
.accordion-trigger {
  background: transparent;
  display: flex;
  align-items: center;
  padding-inline: 1.375rem;
  padding-block: 0.75rem;
  text-align: left;
  width: 100%;
  text-transform: none;
  background-color: var(--accordion-background);
  border-color: var(--accordion-border);
}
@media screen and (max-width: 640px) {
  .accordion-trigger {
    padding-inline: 1rem;
  }
}
.accordion-trigger:focus {
  box-shadow: none;
}
.accordion-trigger > * {
  pointer-events: none;
}
.accordion-trigger-title {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  gap: 0.25rem;
}
.accordion-panel {
  transition: 0.2s height;
  background-color: var(--accordion-background-active);
}
.accordion-panel-content {
  padding: 2rem;
}

.accordion-trigger {
  position: relative;
}
.accordion-trigger:hover, .accordion-trigger:focus {
  background-color: var(--accordion-background-active);
  border-color: var(--accordion-border-active);
}
.accordion-trigger[aria-expanded=true] {
  background-color: var(--accordion-background-active);
  border-color: var(--accordion-border-active);
}
.accordion-trigger[aria-expanded=true] i {
  transform: rotate(180deg);
}

.tabs-container > .content-container {
  padding-bottom: 0;
  padding-top: 4rem;
}
@media screen and (max-width: 1024px) {
  .tabs-container > .content-container {
    padding-top: 2rem;
  }
}
@media (max-width: 640px) {
  .tabs-container > .content-container {
    padding-top: 0;
  }
}
.tabs-list {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
@media (max-width: 640px) {
  .tabs-list {
    flex-wrap: wrap;
    padding-block: 1.375rem;
  }
}
.tabs-list-item {
  flex: 0 1 auto;
  margin-bottom: -1px;
  overflow: hidden;
  margin-right: 1rem;
}
.tabs-list-item:last-child {
  margin-right: 0;
}
.tabs-list-tab {
  padding: 0.75rem 1rem;
  margin: 3px 3px 0;
  position: relative;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  border-style: solid;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: transparent;
  text-align: center;
  font-weight: 700;
}
@media (max-width: 640px) {
  .tabs-list-tab {
    border: 1px solid var(--tab-border);
    width: 100%;
  }
}
.tabs-list-tab:hover, .tabs-list-tab:focus, .tabs-list-tab[aria-selected=true] {
  background-color: var(--tab-background-active);
  border-color: var(--tab-border);
}
@media (max-width: 640px) {
  .tabs-list-tab:hover, .tabs-list-tab:focus, .tabs-list-tab[aria-selected=true] {
    border-bottom: 1px solid var(--tab-border);
  }
}
.tabs-panel {
  background-color: var(--tab-background);
}
.tabs-panel:has(.rich-text table) {
  background-color: #fff;
}
@media (min-width: 640.1px) {
  .tabs-panel {
    border-top: 1px solid var(--tab-border);
    margin-top: -0.8px;
  }
}
.tabs-panel .rich-text table {
  background: #fff;
}

@media (max-width: 640px) {
  .tab-set-tab {
    width: 100%;
  }
}

.bio-block-content {
  gap: 1rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1.375rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1024px) {
  .bio-block-content {
    margin-top: 1.5rem;
  }
}
@media (min-width: 640.1px) {
  .bio-block-content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.375rem;
  }
}
@media (min-width: 1024.1px) {
  .bio-block-content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2rem;
  }
}
.bio-block-item {
  text-align: center;
}
@media (max-width: 640px) {
  .bio-block-item {
    width: 100%;
  }
}
.bio-block-image {
  width: 100%;
}
.bio-block-image img {
  width: auto;
  display: block;
  object-fit: contain;
  margin-inline: auto;
  object-position: 50% 50%;
  object-position: var(--focal-point-h) var(--focal-point-v);
}
.bio-block-button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
@media screen and (max-width: 1024px) {
  .bio-block-button-container {
    margin-top: 1.5rem;
  }
}

.quote-block-content {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  gap: 2rem;
}
@media screen and (max-width: 1024px) {
  .quote-block-content {
    margin-top: 1.5rem;
    gap: 1.5rem;
  }
}
.quote-block-item {
  flex-grow: 1;
}
@media (min-width: 640.1px) {
  .quote-block-item {
    width: calc(50% - 1rem);
  }
}
.quote-block-item-attribution {
  margin-top: 4rem;
  display: flex;
  flex-flow: row;
  gap: 2rem;
}
@media screen and (max-width: 1024px) {
  .quote-block-item-attribution {
    margin-top: 2rem;
  }
}
.quote-block-item-attribution-image {
  max-width: 10rem;
}
.quote-block-item-attribution-image img {
  width: 100%;
}

.advertisement-block {
  padding: 1rem;
}
@media (min-width: 1280.1px) {
  .advertisement-block {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem 1rem;
  }
}
@media (max-width: 640px) {
  .advertisement-block {
    padding: 0.5rem;
  }
}
.advertisement-block:has(.advertisement-block-advertisement + .advertisement-block-advertisement) {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 1024px) {
  .advertisement-block:has(.advertisement-block-advertisement + .advertisement-block-advertisement) {
    gap: 1rem;
  }
}
.advertisement-block-advertisement {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.advertisement-block-advertisement p {
  width: 100%;
}
.advertisement-block-advertisement > div {
  max-width: 100%;
}
.advertisement-block-advertisement iframe {
  max-width: 100%;
}
.advertisement-block-advertisement img {
  max-width: 100% !important;
  height: auto !important;
}

.sidebar-ad-slot > div {
  margin-inline: auto;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  padding-block: 1rem;
}
@media (max-width: 1024px) {
  .sidebar-ad-slot > div {
    justify-content: center;
  }
}

.media-carousel-block {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  flex-grow: 1;
  max-width: 85%;
}
@media (min-width: 1280.1px) {
  .media-carousel-block {
    width: 33%;
  }
}
.media-carousel-block-video {
  width: 100%;
}
.media-carousel-block-video iframe {
  aspect-ratio: 16/9;
  height: auto;
  width: 100%;
}
.media-carousel-block-image-audio, .media-carousel-block-video {
  background-color: var(--carousel-background);
  display: flex;
  flex-flow: row wrap;
  place-content: center;
  flex-grow: 1;
}
.media-carousel-block-image-audio img, .media-carousel-block-video img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.media-carousel-container-block .content-container {
  padding-bottom: 0;
  margin-bottom: 1.375rem;
}
@media screen and (max-width: 1024px) {
  .media-carousel-container-block .content-container {
    margin-bottom: 0.75rem;
  }
}
.media-carousel-container-block .swiper-navigation-container {
  padding-top: 1.375rem;
  padding-bottom: 4rem;
}
@media screen and (max-width: 1024px) {
  .media-carousel-container-block .swiper-navigation-container {
    padding-top: 0.75rem;
  }
}
@media screen and (max-width: 1024px) {
  .media-carousel-container-block .swiper-navigation-container {
    padding-bottom: 2rem;
  }
}

.swiper-navigation-buttons {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
@media (max-width: 640px) {
  .swiper-navigation-buttons {
    justify-content: center;
    width: 100%;
  }
}
.swiper-navigation-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
@media (max-width: 640px) {
  .swiper-navigation-container {
    gap: 1rem;
  }
}

.swiper-button-next, .swiper-button-prev {
  position: relative;
  left: auto;
  right: auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  background-color: var(--accordion-icon-background);
  color: var(--accordion-icon-color);
  padding: 0.25rem 0.75rem;
}
.swiper-button-next:after, .swiper-button-prev:after {
  content: none;
  display: none;
}

.swiper .swiper-pagination {
  flex-grow: 1;
  display: block;
  position: relative;
  width: auto;
  bottom: auto;
  top: auto;
  left: auto;
  --swiper-pagination-bullet-inactive-color:var(--button-disabled-background);
}
.swiper .swiper-pagination-bullet {
  width: 2rem;
  height: 3px;
  border-radius: 0;
}

.content-highlight-block-item-image {
  width: 100%;
  aspect-ratio: 1.72;
}
.content-highlight-block-item-image img {
  aspect-ratio: 1.72;
  object-fit: contain;
}
.content-highlight-block-item-description {
  max-height: 4.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
@supports (display: -webkit-box) {
  .content-highlight-block-item-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.content-highlight-item {
  height: auto;
  width: calc(25% - 1.5rem);
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
.content-highlight-item:first-child:nth-last-child(1) {
  width: 100%;
}
.content-highlight-item:last-child {
  margin-right: 0 !important;
}
@media (min-width: 640.1px) {
  .content-highlight-item {
    width: calc(50% - 1rem);
    /* two items */
    /* three items */
    /* four items */
  }
  .content-highlight-item:first-child:nth-last-child(2), .content-highlight-item:first-child:nth-last-child(2) ~ .content-highlight-item {
    width: calc(50% - 1rem);
  }
  .content-highlight-item:first-child:nth-last-child(3), .content-highlight-item:first-child:nth-last-child(3) ~ .content-highlight-item {
    width: calc(50% - 1rem);
  }
  .content-highlight-item:first-child:nth-last-child(4), .content-highlight-item:first-child:nth-last-child(4) ~ .content-highlight-item {
    width: calc(50% - 1rem);
  }
  .content-highlight-item:first-child:nth-last-child(1) {
    width: 100%;
  }
}
@media (min-width: 1024.1px) {
  .content-highlight-item {
    width: calc(25% - 1.5rem);
    /* two items */
    /* three items */
    /* four items */
  }
  .content-highlight-item:first-child:nth-last-child(2), .content-highlight-item:first-child:nth-last-child(2) ~ .content-highlight-item {
    width: calc(50% - 1rem);
  }
  .content-highlight-item:first-child:nth-last-child(3), .content-highlight-item:first-child:nth-last-child(3) ~ .content-highlight-item {
    width: calc(33.3333% - 1.375rem);
  }
  .content-highlight-item:first-child:nth-last-child(4), .content-highlight-item:first-child:nth-last-child(4) ~ .content-highlight-item {
    width: calc(33.3333% - 1.375rem);
  }
}
@media (min-width: 1280.1px) {
  .content-highlight-item {
    width: calc(25% - 1.5rem);
    /* two items */
    /* three items */
    /* four items */
  }
  .content-highlight-item:first-child:nth-last-child(2), .content-highlight-item:first-child:nth-last-child(2) ~ .content-highlight-item {
    width: calc(50% - 1rem);
  }
  .content-highlight-item:first-child:nth-last-child(3), .content-highlight-item:first-child:nth-last-child(3) ~ .content-highlight-item {
    width: calc(33.3333% - 1.375rem);
  }
  .content-highlight-item:first-child:nth-last-child(4), .content-highlight-item:first-child:nth-last-child(4) ~ .content-highlight-item {
    width: calc(25% - 1.5rem);
  }
}
@media (max-width: 640px) {
  .content-highlight-item {
    width: 100%;
  }
}
.content-highlight-item-wrapper {
  background-color: var(--card-background);
  border: solid 1px var(--card-border);
  display: flex;
  flex-flow: column;
  gap: 0.75rem;
  height: 100%;
  justify-content: space-between;
  padding: 1.375rem;
}
@media screen and (max-width: 640px) {
  .content-highlight-item-wrapper {
    padding: 1rem;
  }
}
.content-highlight-item-content {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.content-highlight-item-content-wrapper {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}
.content-highlight-container-block {
  overflow: hidden;
}
.content-highlight-container-block-items {
  overflow: visible;
}
.content-highlight-container-block .swiper-wrapper {
  margin-block: 1.375rem;
}
@media screen and (max-width: 640px) {
  .content-highlight-container-block .swiper-wrapper {
    margin-block: 1rem;
  }
}
.content-highlight-container-block .swiper-button-lock {
  display: none;
}

.hero-banner-block {
  position: relative;
}
.hero-banner-block[data-vimeo-hero] .content-container {
  min-height: 30rem;
}
@media (min-width: 767.1px) {
  .hero-banner-block[data-vimeo-hero] .content-container {
    min-height: 38rem;
  }
}
@media (min-width: 1600.1px) {
  .hero-banner-block[data-vimeo-hero] .content-container {
    min-height: 100vh;
  }
}
.hero-banner-block[data-vimeo-hero] .media-heading-text-links {
  align-items: center;
}
.hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary {
  width: auto;
}
@media (max-width: 400px) {
  .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary {
    width: 100%;
  }
}
.hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a {
  --button-primary-background: var(--white);
  --button-primary-foreground: var(--black);
  width: auto;
}
@media (max-width: 400px) {
  .hero-banner-block[data-vimeo-hero] .media-heading-text-links .secondary a, .hero-banner-block[data-vimeo-hero] .media-heading-text-links .primary a {
    width: 100%;
  }
}
@media (max-width: 640px) {
  .hero-banner-block[data-vimeo-hero] .hero-banner-align-vertical-bottom {
    order: 2;
  }
  .hero-banner-block[data-vimeo-hero] .hero-banner-align-vertical-bottom + .hero-banner-vimeo-controls {
    align-self: flex-start;
  }
}
@media (min-width: 640.1px) {
  .hero-banner-block[data-vimeo-hero] .hero-banner-left-content .hero-banner-left {
    max-width: 75%;
  }
}
@media (min-width: 1280.1px) {
  .hero-banner-block[data-vimeo-hero] .hero-banner-left-content .hero-banner-left {
    max-width: 50%;
  }
}
@media (min-width: 640.1px) {
  .hero-banner-block[data-vimeo-hero].hero-banner-align-right .hero-banner-left {
    order: 2;
  }
}
.hero-banner-block[data-image-hero] {
  overflow: hidden;
}
.hero-banner-block[data-image-hero] .content-container {
  min-height: 30rem;
}
@media (min-width: 767.1px) {
  .hero-banner-block[data-image-hero] .content-container {
    min-height: 36rem;
  }
}
.hero-banner-block[data-image-hero] .media-heading-text-links {
  align-items: center;
}
.hero-banner-block[data-image-hero] .media-heading-text-links .primary, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary {
  width: auto;
}
@media (max-width: 400px) {
  .hero-banner-block[data-image-hero] .media-heading-text-links .primary, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary {
    width: 100%;
  }
}
.hero-banner-block[data-image-hero] .media-heading-text-links .primary a, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a {
  width: auto;
}
@media (max-width: 400px) {
  .hero-banner-block[data-image-hero] .media-heading-text-links .primary a, .hero-banner-block[data-image-hero] .media-heading-text-links .secondary a {
    width: 100%;
  }
}
@media (min-width: 1024.1px) {
  .hero-banner-block[data-image-hero] .svg-background.svg-align-left svg {
    left: -15rem;
  }
}
@media (min-width: xlarge0.1px) {
  .hero-banner-block[data-image-hero] .svg-background.svg-align-left svg {
    left: -35rem;
  }
}
@media (min-width: 1280.1px) {
  .hero-banner-block[data-image-hero] .svg-background.svg-align-right svg {
    left: 50%;
  }
}
.hero-banner-block[data-image-hero].hero-banner-no-column .content-container {
  min-height: auto;
}
.hero-banner-block[data-image-hero].hero-banner-no-column:has(.hero-banner-image-background) .content-container {
  min-height: 30rem;
}
@media (min-width: 1024.1px) {
  .hero-banner-block[data-image-hero].hero-banner-no-column:has(.hero-banner-image-background) .content-container {
    min-height: 35rem;
  }
}
@media (min-width: 1600.1px) {
  .hero-banner-block[data-image-hero].hero-banner-no-column:has(.hero-banner-image-background) .content-container {
    min-height: 38rem;
  }
}
@media (min-width: 1024.1px) {
  .hero-banner-block[data-image-hero].hero-banner-no-column.hero-banner-align-right .hero-banner-left-content {
    justify-content: flex-end;
  }
}
.hero-banner-block[data-image-hero].hero-banner-no-column.hero-banner-align-right .hero-banner-left {
  min-width: calc(50% - 1rem);
}
@media (min-width: 1024.1px) {
  .hero-banner-block[data-image-hero].hero-banner-no-column.hero-banner-align-center .hero-banner-left-content {
    justify-content: center;
  }
}
@media (min-width: 1024.1px) {
  .hero-banner-block[data-image-hero].hero-banner-no-column.hero-banner-align-left .hero-banner-left-content {
    justify-content: flex-start;
  }
}
.hero-banner-block[data-image-hero].hero-banner-no-column .hero-banner-left-content .hero-banner-left {
  max-width: 100%;
}
@media (min-width: 1024.1px) {
  .hero-banner-block[data-image-hero].hero-banner-no-column .hero-banner-left-content .hero-banner-left {
    max-width: 60%;
  }
}
@media (max-width: 400px) {
  .hero-banner-block[data-image-hero].hero-banner-no-column .hero-banner-left-content .hero-banner-left {
    width: 100%;
  }
}
.hero-banner-block .media-heading-text-content {
  flex-grow: 0;
}
.hero-banner-block:has(.hero-banner-video-background) .chip-subtitle, .hero-banner-block:has(.hero-banner-image-background) .chip-subtitle {
  background: none;
  padding: 0;
}
@media (max-width: 1024px) {
  .hero-banner-block:not(:has(.hero-banner-image-background)) .content-container {
    min-height: auto;
  }
}
.hero-banner-block:not(:has(.hero-banner-image-background)):has(.svg-background):not(:has(.media-heading-image)) .content-container {
  min-height: auto;
}
.hero-banner-block.no-min-height .content-container {
  min-height: auto !important;
}
.hero-banner-left, .hero-banner-right {
  z-index: 3;
  position: relative;
}
@media (min-width: 1024.1px) {
  .hero-banner-left-image, .hero-banner-right-image {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}
@media (min-width: 1024.1px) {
  .hero-banner-left-image .media-heading-image img, .hero-banner-right-image .media-heading-image img {
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
    width: 100%;
    object-position: var(--focal-point-h) var(--focal-point-v);
  }
}
.hero-banner-left-image {
  height: 100%;
}
@media (min-width: 1024.1px) {
  .hero-banner-left-image {
    right: auto;
    left: 0;
  }
}
.hero-banner-left-image .hero-banner-left {
  height: 100%;
}
.hero-banner-left-image .hero-banner-left .media-heading-image {
  height: 100%;
}
.hero-banner-left-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 100%;
  gap: 1.375rem;
}
@media (min-width: 1024.1px) {
  .hero-banner-left-content .hero-banner-left {
    max-width: calc(50% - 1rem);
  }
}
@media (min-width: 1024.1px) {
  .hero-banner-right-content {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .hero-banner-right-content .hero-banner-right {
    margin-left: 2rem;
    grid-column-start: 2;
  }
}
@media screen and (min-width: 1024.1px) and (max-width: 1024px) {
  .hero-banner-right-content .hero-banner-right {
    margin-left: 1.5rem;
  }
}
.hero-banner-right-image {
  height: 100%;
}
.hero-banner-right-image .hero-banner-right {
  height: 100%;
}
.hero-banner-right-image .hero-banner-right .media-heading-image {
  height: 100%;
}
@media (max-width: 1024px) {
  .hero-banner-align-vertical-middle {
    align-self: flex-end;
  }
}
.hero-banner-align-vertical-middle .media-heading-text {
  justify-content: center;
}
.hero-banner-align-vertical-top .media-heading-text {
  justify-content: flex-start;
}
.hero-banner-align-vertical-bottom .media-heading-text {
  justify-content: flex-end;
}
.hero-banner-video-background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.hero-banner-video-background > div {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.hero-banner-video-background iframe {
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.77777778vh;
}
.hero-banner-vimeo-controls {
  z-index: 3;
  position: relative;
  align-self: flex-end;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
@media (max-width: 640px) {
  .hero-banner-vimeo-controls {
    width: 100%;
    justify-content: flex-end;
  }
}
.hero-banner-vimeo-controls button {
  background-color: var(--white);
  width: 3.25rem;
  height: 3.25rem;
  text-align: center;
}
.hero-banner-vimeo-controls button:hover, .hero-banner-vimeo-controls button:focus {
  background-color: var(--black);
}
.hero-banner-vimeo-controls button:hover i, .hero-banner-vimeo-controls button:focus i {
  color: var(--white);
}
@media (max-width: 767px) {
  .hero-banner-vimeo-controls button {
    width: 2.75rem;
    height: 2.75rem;
  }
}
.hero-banner-vimeo-controls button i {
  line-height: 3.25rem;
  font-size: 1.5rem;
  color: var(--black);
}
@media (max-width: 767px) {
  .hero-banner-vimeo-controls button i {
    line-height: 2.75rem;
    font-size: 1rem;
  }
}
.hero-banner-vimeo-controls .muted .ph-speaker-x {
  display: none;
}
.hero-banner-vimeo-controls .muted .ph-speaker-high {
  display: block;
}
.hero-banner-vimeo-controls .unmuted .ph-speaker-x {
  display: block;
}
.hero-banner-vimeo-controls .unmuted .ph-speaker-high {
  display: none;
}
.hero-banner-vimeo-controls .paused .ph-play {
  display: block;
}
.hero-banner-vimeo-controls .paused .ph-pause {
  display: none;
}
.hero-banner-vimeo-controls .playing .ph-play {
  display: none;
}
.hero-banner-vimeo-controls .playing .ph-pause {
  display: block;
}

td, th, thead td {
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.22222;
  font-size: var(--small-button-text);
  line-height: var(--small-button-text-height);
  border: solid 6px;
  border-color: var(--background);
  min-width: 9rem;
  padding: 1rem;
}

th, thead td {
  font-weight: 700;
  background-color: var(--accordion-background);
}

table {
  border-width: 7px !important;
  border-color: var(--background);
}
.download-masonry-sizer, .download-block {
  width: 100%;
}
@media (min-width: 640.1px) {
  .download-masonry-sizer, .download-block {
    width: calc(50% - 1rem);
  }
}
@media screen and (min-width: 640.1px) and (max-width: 640px) {
  .download-masonry-sizer, .download-block {
    width: calc(50% - 0.625rem);
  }
}
@media (min-width: 1024.1px) {
  .download-masonry-sizer, .download-block {
    width: calc(25% - 1.5rem);
  }
}
@media screen and (min-width: 1024.1px) and (max-width: 640px) {
  .download-masonry-sizer, .download-block {
    width: calc(25% - 0.9375rem);
  }
}

.download-container-filters {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1024px) {
  .download-container-filters {
    margin-top: 1.5rem;
  }
}
.download-container-filters [data-filter-state=active] {
  background-color: var(--inactive-button);
}
.download-container-filters [data-filter-state=active] i {
  display: none;
}
.download-container-items {
  margin-top: 2rem;
}
@media screen and (max-width: 1024px) {
  .download-container-items {
    margin-top: 1.5rem;
  }
}
.download-container-more {
  margin-inline: auto;
  display: block;
}
.download-container-filter {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.25rem;
}
.download-block {
  padding: 1rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  background-color: var(--card-background);
  border-color: var(--card-border);
  border-width: 1px;
  border-style: solid;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1024px) {
  .download-block {
    margin-bottom: 1.5rem;
  }
}
.download-block-image {
  width: 100%;
  height: 16.75rem;
  background-color: var(--download-card);
}
.download-block-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.download-block .category-pill {
  display: inline-block;
}
.download-block .categories {
  display: flex;
  flex-flow: row wrap;
  gap: 0.25rem;
}
.download-masonry-gutter {
  width: 2rem;
}
@media screen and (max-width: 640px) {
  .download-masonry-gutter {
    width: 1.25rem;
  }
}

.counter-container {
  display: grid;
  margin-top: 2rem;
  gap: 2rem;
}
@media screen and (max-width: 1024px) {
  .counter-container {
    margin-top: 1.5rem;
  }
}
.counter-container-block {
  position: relative;
}
.counter-container-block .content-container {
  z-index: 3;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .counter-container {
    gap: 1.5rem;
  }
}
.counter-container-4 {
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-template-rows: repeat(1, auto);
}
@media (max-width: 1024px) {
  .counter-container-4 {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: repeat(2, auto);
  }
}
@media (max-width: 640px) {
  .counter-container-4 {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-template-rows: repeat(4, auto);
  }
}
.counter-container-4:has(.highlighted) {
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-template-rows: repeat(3, auto);
}
@media (max-width: 1024px) {
  .counter-container-4:has(.highlighted) {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-rows: repeat(2, auto);
  }
}
@media (max-width: 640px) {
  .counter-container-4:has(.highlighted) {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-template-rows: repeat(4, auto);
  }
}
.counter-container-4 .highlighted {
  grid-column: 1/span 2;
  grid-row: 1/span 3;
}
@media (max-width: 1024px) {
  .counter-container-4 .highlighted {
    grid-column: 1/span 3;
    grid-row: 1;
  }
}
@media (max-width: 640px) {
  .counter-container-4 .highlighted {
    grid-column: 1;
    grid-row: 1;
  }
}
@media (min-width: 1024.1px) {
  .counter-container-4 .highlighted .counter-block-value {
    --x-large-text: 7.625rem;
  }
}
.counter-container-3 {
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-template-rows: auto;
}
@media (max-width: 1024px) {
  .counter-container-3 {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: repeat(2, auto);
  }
  .counter-container-3 .counter-item:last-child {
    grid-column: 1/span 2;
  }
}
@media (max-width: 640px) {
  .counter-container-3 {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-template-rows: repeat(3, auto);
  }
  .counter-container-3 .counter-item:last-child {
    grid-column: 1;
  }
}
.counter-container-2 {
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-template-rows: auto;
}
.counter-container-2 .counter-item:first-child {
  width: 100%;
  justify-self: end;
}
@media (max-width: 1024px) {
  .counter-container-2 {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: auto;
  }
}
@media (max-width: 640px) {
  .counter-container-2 {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-template-rows: repeat(2, auto);
  }
}
.counter-container-2 .counter-item {
  max-width: 39.5rem;
}
.counter-container-1 {
  grid-template-columns: auto;
  grid-template-rows: auto;
}
.counter-container-1 .counter-item {
  width: 100%;
  max-width: 81rem;
  justify-self: center;
}
@media (min-width: 1024.1px) {
  .counter-container-1 .counter-item .counter-block-value {
    --x-large-text: 7.625rem;
  }
}
.counter-item {
  background-color: var(--counter-background);
  padding: 2rem;
}
@media screen and (max-width: 1024px) {
  .counter-item {
    padding: 1.5rem;
  }
}
.counter-block {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}
.counter-block-value, .counter-block-label {
  word-break: break-word;
}
.counter-block-icon {
  width: 4rem;
  height: 4rem;
  margin-inline: auto;
}

.upcoming-events-filters {
  margin-block: 2rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
.upcoming-events-filters-item {
  display: flex;
  flex-flow: row wrap;
  gap: 0.25rem;
}
.upcoming-events-filters-item[data-filter-state=active] {
  background-color: var(--inactive-button);
}
.upcoming-events-filters-item[data-filter-state=active] i {
  display: none;
}
.upcoming-events-item {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding-block: 2rem;
  gap: 2rem;
  border-top: solid 2px var(--scrollbar-background);
}
@media screen and (max-width: 640px) {
  .upcoming-events-item {
    padding-block: 1.25rem;
    gap: 1.25rem;
  }
}
@media (max-width: 1024px) {
  .upcoming-events-item {
    display: flex;
    flex-flow: column;
  }
}
.upcoming-events-item.hide {
  display: none;
}
.upcoming-events-item-container-center {
  display: flex;
  flex-flow: row;
  width: 100%;
  gap: 2rem;
}
@media screen and (max-width: 640px) {
  .upcoming-events-item-container-center {
    gap: 1.25rem;
  }
}
@media (max-width: 1024px) {
  .upcoming-events-item-container-center {
    flex-wrap: wrap;
  }
}
.upcoming-events-item-container-left {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .upcoming-events-item-container-left {
    flex-flow: row;
    gap: 1rem;
  }
}
.upcoming-events-item-image {
  max-width: 55%;
}
@media (max-width: 1024px) {
  .upcoming-events-item-image {
    max-width: 100%;
    width: 100%;
  }
}
.upcoming-events-item-image img {
  width: 100%;
}
.upcoming-events-item-subtitle {
  text-transform: uppercase;
}
.upcoming-events-item-content {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  gap: 1rem;
  justify-content: space-between;
}
.upcoming-events-item-content-header, .upcoming-events-item-content-lower {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
.upcoming-events-item-content-info {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}

.alert-banner-content {
  flex-grow: 1;
}
.alert-banner-content-buttons {
  display: flex;
  flex-flow: row wrap;
  gap: 0.75rem;
}
.alert-banner-content-buttons:has(.primary) {
  margin-top: 0.25rem;
}
@media (max-width: 640px) {
  .alert-banner-content-buttons .primary, .alert-banner-content-buttons .secondary {
    width: 100%;
  }
  .alert-banner-content-buttons .primary > a, .alert-banner-content-buttons .secondary > a {
    width: 100%;
  }
}
.alert-banner-block .content-container {
  padding-block: 1rem;
  display: flex;
  flex-flow: row;
  gap: 0.75rem;
}
.alert-banner-icon i, .alert-banner-close i {
  font-size: 1.5rem;
}
.alert-banner-close {
  width: 1.875rem;
  height: 1.875rem;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.alert-banner-close i {
  width: 100%;
}
.alert-banner-close i:hover, .alert-banner-close i:active, .alert-banner-close i:focus {
  transition: font-size 0.2s ease;
  font-size: 1.875rem;
}
.alert-success {
  background-color: var(--alert-green);
}
.alert-info {
  background-color: var(--alert-grey);
}
.alert-warning {
  background-color: var(--alert-yellow);
}
.alert-error {
  background-color: var(--alert-bright-red);
}
.alert-pwa {
  padding: 1rem 2rem;
  display: flex;
  flex-flow: row;
  gap: 0.75rem;
}

.interstitial-block .content-container {
  width: 100%;
}
.interstitial-block-form, .interstitial-block-media {
  width: calc(50% - 1rem);
}
@media (max-width: 1024px) {
  .interstitial-block-form, .interstitial-block-media {
    width: 100%;
  }
}
.interstitial-block-media {
  position: relative;
}
.interstitial-block-media img {
  width: 100%;
}
.interstitial-block-image {
  position: relative;
  z-index: 2;
}
.interstitial-block-illustration {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.interstitial-block-illustration .illustration {
  width: 100%;
  height: 100%;
}
.interstitial-block-close {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
.interstitial-block-inner {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 2rem;
  padding-block: 2rem;
  padding-inline: 7.5rem;
}
@media screen and (max-width: 640px) {
  .interstitial-block-inner {
    gap: 1.25rem;
    padding-block: 1.25rem;
  }
}
@media screen and (max-width: 1280px) {
  .interstitial-block-inner {
    padding-inline: 4rem;
  }
}
@media screen and (max-width: 1024px) {
  .interstitial-block-inner {
    padding-inline: 2.5rem;
  }
}
@media screen and (max-width: 640px) {
  .interstitial-block-inner {
    padding-inline: 1rem;
  }
}
.interstitial-block .modal-close:not(.icon-link) {
  position: relative;
  padding: 10px 14px 8px;
}
.interstitial-block-ad .interstitial-block-inner {
  padding: 1rem;
  width: auto;
  gap: 0.5rem;
  align-items: flex-start;
  max-width: 100%;
}
.interstitial-block-ad .interstitial-block-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
}
.interstitial-block-ad .modal-dialog {
  width: max-content;
  max-width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.interstitial-block-ad .advertisement-block-advertisement {
  width: 100%;
}
.interstitial-block-ad .interstitial-block-close {
  max-height: 46px;
}

.float-block-open {
  color: var(--button-primary-foreground);
  padding: 6px;
  border-radius: 2.625rem;
  display: block;
  background: var(--button-primary-foreground);
  border: solid 1px var(--tab-border);
  position: fixed;
  z-index: 4;
  bottom: 2rem;
  cursor: pointer;
  right: 2rem;
}
@media screen and (max-width: 640px) {
  .float-block-open {
    right: 1.25rem;
  }
}
.float-block-open i {
  display: block;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 10px 14px 8px;
  background: var(--button-primary-background);
  border-radius: 2.625rem;
}
.float-block .content-container {
  width: 100%;
  height: 100vh;
}
.float-block-modal-content {
  border: solid 1px var(--black);
  padding: 2rem;
  overflow-y: auto;
  height: 100%;
}
@media screen and (max-width: 640px) {
  .float-block-modal-content {
    padding: 1.25rem;
  }
}
.float-block-modal-content > * {
  max-width: calc(100% - 100px);
}
.float-block-modal-collapse {
  color: var(--button-primary-foreground);
  padding: 6px;
  border-radius: 2.625rem;
  display: block;
  top: 2rem;
  border: none;
  background-color: var(--background);
}
.float-block-modal-collapse i {
  display: block;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 10px 14px 8px;
  background: var(--button-primary-background);
  border-radius: 2.625rem;
}
.float-block .modal-dialog {
  height: 100%;
}

.modal-container {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  opacity: 1;
  display: flex;
  justify-content: center;
}
.modal-container[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: none;
}
.modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal-overlay:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.modal-overlay::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
}
.modal-dialog {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  height: 100%;
  overflow: auto;
}
.modal-inner-cta-links {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.modal-inner-survey {
  max-width: 40rem;
  margin-inline: auto;
}
.modal-inner-survey .interstitial-block-inner {
  padding: 1.5rem;
}
.modal-inner-survey .theme-light-primary, .modal-inner-survey .article-detail-advertisement .advertisement-block, .article-detail-advertisement .modal-inner-survey .advertisement-block {
  --button-primary-background: var(--jewel-primary);
  --background-color: var(--alert-grey);
  background-color: var(--alert-grey);
}
.modal-inner-survey .interstitial-block-form {
  width: 100%;
}
.modal-inner-survey .interstitial-block-close .modal-close {
  top: 0;
  right: 0;
}

.modal-container {
  background-color: var(--tab-background);
  transition: 0.3s opacity;
}
.modal-container-survey {
  background-color: rgba(0, 0, 0, 0.68);
}
.modal-inner {
  background-color: var(--background);
  pointer-events: auto;
}
.modal-inner-full-bleed {
  padding: 0;
}

.modal-close {
  top: 0.5rem;
  right: 1rem;
  pointer-events: auto;
  position: absolute;
  cursor: pointer;
}

.author-block {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
.author-block-image {
  max-width: 3.4375rem;
}
.author-block-image img {
  width: 100%;
  border-radius: 3.4375rem;
}

.sponsor-block {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}
.sponsor-block-image {
  max-width: 2.625rem;
}
.sponsor-block-image img {
  width: 100%;
  border-radius: 2.625rem;
  aspect-ratio: 1;
}

.registration-cost-table-header, .registration-cost-table-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  gap: 2rem;
  align-items: center;
}
.registration-cost-table-column {
  padding-block: 1rem;
}
@media (max-width: 1024px) {
  .registration-cost-table-column {
    padding-block: 0.5rem;
  }
}
.registration-cost-table-column-mobile {
  display: flex;
  flex-flow: column;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
}
@media (min-width: 1024.1px) {
  .registration-cost-table-column-mobile {
    display: none;
  }
}
.registration-cost-table-header {
  background-color: var(--registration-header);
  color: #F8F5F3;
}
@media (max-width: 1024px) {
  .registration-cost-table-header {
    display: none;
  }
}
.registration-cost-table-item {
  background-color: var(--registration-body);
  color: var(--foreground);
  margin-top: 0.5rem;
  padding-inline: 1.5rem;
}
@media (max-width: 1024px) {
  .registration-cost-table-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    padding-inline: 1rem;
    gap: 0.5rem;
    align-items: flex-start;
  }
}
@media (max-width: 640px) {
  .registration-cost-table-item {
    display: flex;
    flex-flow: column;
  }
}
@media (max-width: 1024px) {
  .registration-cost-table-item-header {
    width: 100%;
    grid-column: span 3;
  }
}
.registration-cost-table-item-header h3 {
  display: flex;
  flex-flow: row;
  gap: 0.5rem;
  color: var(--foreground);
}
@media (max-width: 640px) {
  .registration-cost-table-item-header h3 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
.registration-cost-table-trigger {
  background: none !important;
  border: none !important;
  padding-inline: 0;
}
.registration-cost-table-trigger:hover {
  cursor: pointer;
}
@media (max-width: 640px) {
  .registration-cost-table-trigger {
    width: 100%;
  }
}
.registration-cost-table-trigger[aria-expanded=true] .ph-plus {
  display: none;
}
.registration-cost-table-trigger[aria-expanded=true] .ph-minus {
  display: block;
}
.registration-cost-table-trigger[aria-expanded=true] .ph-caret-down {
  display: none;
}
.registration-cost-table-trigger[aria-expanded=true] .ph-caret-up {
  display: block;
}
.registration-cost-table-trigger .ph-minus {
  display: none;
}
.registration-cost-table-trigger .ph-caret-up {
  display: none;
}
.registration-cost-table-accordion {
  background: none;
  padding-inline: 0;
}
.registration-cost-footer {
  margin-top: 1rem;
}
.registration-cost-heading + .registration-cost-table {
  margin-top: 2rem;
}

.title-block-content {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}
@media (max-width: 640px) {
  .title-block-content {
    gap: 1rem;
  }
}
.title-block-header {
  width: calc(50% - 1rem);
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  justify-content: center;
}
@media (max-width: 1024px) {
  .title-block-header {
    width: auto;
  }
}
.title-block-image {
  width: calc(50% - 1rem);
}
@media (max-width: 1024px) {
  .title-block-image {
    width: auto;
  }
}
@media (max-width: 1024px) and (max-width: 640px) {
  .title-block-image {
    text-align: center;
    width: 100%;
  }
}
.title-block .content-container {
  padding-block: 2rem;
}
@media screen and (max-width: 1024px) {
  .title-block .content-container {
    padding-block: 1.5rem;
  }
}

.ad-carousel-block {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  flex-grow: 1;
  margin-block: 1rem;
}
.ad-carousel-item {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  padding-block: 1rem;
}
.ad-carousel-item .advertisement-block-advertisement {
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 0.25rem;
}
.ad-carousel-item .advertisement-block-advertisement p {
  width: auto;
}
.ad-carousel-container-block .content-container {
  padding-bottom: 0;
  margin-bottom: 1.375rem;
}
@media screen and (max-width: 1024px) {
  .ad-carousel-container-block .content-container {
    margin-bottom: 0.75rem;
  }
}
.ad-carousel-container-block .swiper-navigation-container {
  padding-top: 1.375rem;
  padding-bottom: 4rem;
}
@media screen and (max-width: 1024px) {
  .ad-carousel-container-block .swiper-navigation-container {
    padding-top: 0.75rem;
  }
}
@media screen and (max-width: 1024px) {
  .ad-carousel-container-block .swiper-navigation-container {
    padding-bottom: 2rem;
  }
}

.swiper-navigation-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
@media (max-width: 640px) {
  .swiper-navigation-container {
    gap: 1rem;
  }
}

.swiper .swiper-pagination {
  flex-grow: 1;
  display: block;
  position: relative;
  width: auto;
  bottom: auto;
  top: auto;
  left: auto;
  --swiper-pagination-bullet-inactive-color:var(--button-disabled-background);
}
.swiper .swiper-pagination-bullet {
  width: 0.75rem;
  height: 3px;
  border-radius: 0;
}

.survey-form-question {
  margin-bottom: 2rem;
  width: 100%;
}
.survey-form-answer-list {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  row-gap: 0.25rem;
}
.survey-form-percent {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 10%;
  width: 100%;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.33333;
  font-size: var(--large-regular-text);
  line-height: var(--large-regular-text-height);
}
.survey-form-percent-largest .survey-form-percent {
  color: var(--svg-fill-primary);
}
.survey-form-percent-largest .survey-form-percent-line.animate:before {
  background-color: var(--svg-fill-primary);
}
.survey-form-percent-line {
  width: 200px;
  display: block;
  position: relative;
  height: 1.125rem;
  flex-grow: 1;
  background-color: transparent;
  margin-bottom: 0.25rem;
}
.survey-form-percent-line:before {
  position: absolute;
  content: "";
  background: var(--alert-green);
  left: 0;
  width: 0;
  transition: all 0.5s ease;
  height: 100%;
}
.survey-form-percent-line.animate:before {
  width: var(--survey-answer-percent);
  background-color: var(--button-primary-background);
}
.survey-form-results {
  margin-top: 1rem;
  padding: 2rem;
}
@media (max-width: 640px) {
  .survey-form-results {
    padding: 1rem;
  }
}
@media (max-width: 400px) {
  .survey-form-results {
    padding: 0;
  }
}
.survey-form-results ul {
  width: 100%;
  margin-bottom: 2rem;
}
.survey-form-results-container {
  padding: 1.375rem;
  display: none;
}
.survey-form-results-container.active {
  display: block;
}
.survey-form-results-container.open .loader {
  display: none;
}
.survey-form-result-container {
  display: flex;
  flex-flow: row wrap;
}
.survey-form-result-container > ul {
  /* 
  li:nth-child(1) {
      .survey-form-percent-line {
          &:before {
              background: $event-type-primary;
          }
      }
  }

  li:nth-child(2) {
      .survey-form-percent-line {
          &:before {
              background: $event-type-secondary;
          }
      }
  }

  li:nth-child(3) {
      .survey-form-percent-line {
          &:before {
              background: $event-type-tertiary;
          }
      }
  }

  li:nth-child(4) {
      .survey-form-percent-line {
          &:before {
              background: $event-type-quaternary;
          }
      }
  }

  li:nth-child(5) {
      .survey-form-percent-line {
          &:before {
              background: $event-type-quinary;
          }
      }
  }

  li:nth-child(6) {
      .survey-form-percent-line {
          &:before {
              background: $event-type-senary;
          }
      }
  }

  li:nth-child(6) {
      .survey-form-percent-line {
          &:before {
              background: $event-type-septenary;
          }
      }
  }*/
}

.EPiServerSurveyForm div.Form__Status .Form__Success__Message {
  background-color: var(--background-color);
  color: var(--foreground);
}

.login-page-container {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  border-top: solid 1px var(--chip-outline-border);
  margin-top: 2rem;
}
.login-page-container .btn-primary {
  font-family: "AcherusGrotesque", Arial, sans-serif;
}
@media (max-width: 640px) {
  .login-page-container .btn-primary {
    width: 100%;
  }
}
.login-page-login {
  margin-block: 2rem;
  width: calc(50% - 1rem);
}
@media (max-width: 640px) {
  .login-page-login {
    width: 100%;
    margin-block: 0;
  }
}
.login-page-login p {
  margin-top: 1rem;
}
.login-page-login fieldset {
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;
}
.login-page-login .form-element {
  width: calc(50% - 0.75rem);
}
@media (max-width: 640px) {
  .login-page-login .form-element {
    width: 100%;
  }
}
.login-page-login .link {
  margin-top: 1.5rem;
  display: block;
}
.login-page-login form {
  margin-top: 1.5rem;
}
.login-page-create-acount {
  margin-block: 2rem;
  width: calc(50% - 1rem);
}
@media (max-width: 640px) {
  .login-page-create-acount {
    width: 100%;
    margin-bottom: 0;
  }
}
.login-page-create-acount p {
  margin-top: 1rem;
}
.login-page-create-acount .btn-primary {
  margin-top: 2rem;
}
.login-page-heading p {
  margin-top: 1rem;
  max-width: 50rem;
}
.login-page-error {
  background-color: var(--alert-bright-red);
}
.login-page-error-content {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
}
.login-page-error > .content-container {
  padding-block: 1.5rem;
}
.login-page-error-close {
  background: none;
  border-style: none;
  border: none;
  cursor: pointer;
  width: 1.875rem;
  height: 1.875rem;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.login-page-error-close i {
  width: 100%;
}
.login-page-error-close i:hover, .login-page-error-close i:active, .login-page-error-close i:focus {
  transition: font-size 0.2s ease;
  font-size: 1.875rem;
}
.login-page-error-message {
  display: flex;
  flex-flow: row;
  gap: 1rem;
  max-width: 75%;
}
@media (max-width: 640px) {
  .login-page-error-message {
    max-width: calc(100% - 1.5rem);
  }
}
.login-page-error-message i {
  font-size: 1.5rem;
}
.login-page-error-message p {
  max-width: 65ch;
}

.error-page-body {
  margin-top: 1.375rem;
}

.subscription-page-block {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  margin-block: 2rem;
}
@media screen and (max-width: 1024px) {
  .subscription-page-block {
    margin-block: 1.5rem;
  }
}
.subscription-page-header {
  display: flex;
  gap: 1.375rem;
  flex-flow: column;
}
.subscription-page-header h1 {
  overflow-wrap: break-word;
}
@media (max-width: 640px) {
  .subscription-page-form button {
    width: 100%;
  }
}
.subscription-page-form button + button {
  margin-left: 1rem;
}
@media (max-width: 640px) {
  .subscription-page-form button + button {
    margin-top: 1rem;
    margin-left: 0;
  }
}
.subscription-page-error, .subscription-page-user {
  padding: 1.5rem;
  margin-top: 1.5rem;
}

.learn-upon-content .form-element {
  margin-block: 1.375rem;
}

.article-landing-standard {
  border-color: var(--card-border);
}
.article-landing-highlighted {
  background-color: var(--jewel-primary);
  border-color: var(--jewel-primary);
}
.article-landing-result {
  padding: 0.75rem 1.375rem;
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 2rem;
}
@media screen and (max-width: 1280px) {
  .article-landing-result {
    gap: 1.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .article-landing-result {
    gap: 1rem;
  }
}
.article-landing-result + .article-landing-result {
  margin-top: 2rem;
}
@media screen and (max-width: 1280px) {
  .article-landing-result + .article-landing-result {
    margin-top: 1.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .article-landing-result + .article-landing-result {
    margin-top: 1rem;
  }
}
.article-landing-result-sponsor {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
.article-landing-result-sponsor-img {
  width: 42px;
  height: 42px;
  border-radius: 30px;
}
.article-landing-result-content {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  max-width: calc(65% - 1rem);
}
@media screen and (max-width: 1280px) {
  .article-landing-result-content {
    max-width: calc(65% - 0.625rem);
  }
}
@media screen and (max-width: 1024px) {
  .article-landing-result-content {
    max-width: calc(65% - 0.5rem);
  }
}
@media (max-width: 640px) {
  .article-landing-result-content {
    order: 2;
    max-width: 100%;
  }
}
.article-landing-result-img {
  width: 100%;
  object-fit: contain;
  max-width: calc(35% - 1rem);
}
@media screen and (max-width: 1280px) {
  .article-landing-result-img {
    max-width: calc(35% - 0.625rem);
  }
}
@media screen and (max-width: 1024px) {
  .article-landing-result-img {
    max-width: calc(35% - 0.5rem);
  }
}
@media (max-width: 640px) {
  .article-landing-result-img {
    order: 1;
    width: 100%;
    max-width: 100%;
  }
}
.article-landing-result-description .btn-link {
  text-align: left;
  padding: 0;
}
.article-landing-result-description p {
  margin-top: 0.5rem;
}
.article-landing-result.is-sponsored {
  background-color: var(--secondary-background);
  border-color: var(--card-border);
}
.article-landing-result-category {
  padding: 0;
  color: var(--classic-primary);
}
.article-landing-result-category-list {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}
.article-landing-result-list > .subtitle {
  margin-bottom: 0.75rem;
}
.article-landing > .content-container {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  gap: 5rem;
}
@media (max-width: 1280px) {
  .article-landing > .content-container {
    gap: 2rem;
  }
}
@media (max-width: 1024px) {
  .article-landing > .content-container {
    grid-template-columns: 1fr;
    gap: 1.375rem;
  }
}
.article-landing-sidebar {
  overflow: hidden;
}
.article-landing-sidebar .btn-secondary {
  margin-top: 1.375rem;
}
@media (min-width: 1024.1px) {
  .article-landing-sidebar .btn-secondary {
    margin-bottom: 1.375rem;
  }
}

.article-category-pill a {
  font-weight: 700;
}

.recent-blog-post {
  margin-top: 0.75rem;
}
.recent-blog-post-list {
  margin-bottom: 2rem;
}

.pinned-articles {
  margin-bottom: 1.375rem;
}
@media screen and (max-width: 1024px) {
  .pinned-articles {
    margin-bottom: 0.75rem;
  }
}

.date-error-message {
  color: var(--alert-bright-red);
  margin-top: 0.75rem;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.42857;
  font-size: var(--x-small-text);
  line-height: var(--x-small-text-height);
}

.facet-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;
}
.facet-list {
  display: flex;
  flex-flow: column;
}
.facet-date h3 {
  font-size: 1rem;
  line-height: 1.375;
  font-size: var(--label-text);
  line-height: var(--label-text-height);
}
.facet-item {
  margin-bottom: 0.75rem;
  border-bottom: solid 0.125rem rgba(0, 7, 17, 0.12);
  padding-bottom: 0.75rem;
}
.facet-item:has(+ .facet-item-date) {
  border-bottom: none;
  margin-bottom: 0;
}
.facet-item.facet-item-date {
  border-bottom: none;
  margin-bottom: 0;
}
.facet-item.facet-item-date + .facet-item-date {
  margin-bottom: 0.75rem;
  border-bottom: solid 0.125rem rgba(0, 7, 17, 0.12);
  padding-bottom: 0.75rem;
}
.facet-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.facet-item-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
}
.facet-item h3 {
  margin-bottom: 0.5rem;
}
@media (max-width: 1024px) {
  .facet-item-date input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
    width: 100%;
    max-width: 100%;
  }
}
.facet-item-list {
  padding-block: 0.5rem;
  margin-top: 0.5rem;
  overflow: hidden;
}
.facet-item-list.closed {
  display: none;
}
.facet-item-list.closed .facet-checkbox {
  display: none;
  height: 0;
}
.facet-item-list.closed .facet-checkbox + .facet-checkbox {
  margin-top: 0;
}
.facet-item-mobile-trigger, .facet-item-mobile-trigger[aria-expanded=true] {
  background-color: transparent;
  color: inherit;
  padding: 0;
}
@media (max-width: 1024px) {
  .facet-item .facet-select {
    width: 100%;
    max-width: 100%;
  }
}
.facet-checkbox + .facet-checkbox {
  margin-top: 0.5rem;
}
.facet-container {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
@media (max-width: 1024px) {
  .facet-container {
    border-top: solid 1px;
    border-color: var(--card-border);
    padding-top: 0.75rem;
  }
}
.facet-reset {
  padding-inline: 0;
  color: var(--classic-primary);
}

.accordion-content-facet {
  background-color: transparent;
  padding-inline: 0;
}

.mobile-filter-accordion .accordion-panel[aria-hidden=false] {
  padding-bottom: 1.375rem;
}
.mobile-filter-accordion .accordion-panel[aria-hidden=false].accordion-content-facet {
  padding-bottom: 0;
}
.mobile-filter-accordion .accordion-panel[aria-hidden=false].accordion-content-facet > p {
  margin-top: 1rem;
}
.mobile-filter-accordion .accordion-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px;
  border-color: transparent;
}
.mobile-filter-accordion .accordion-trigger:focus {
  background-color: transparent;
}
.mobile-filter-accordion .accordion-trigger:focus-visible {
  outline: none;
  border-color: var(--secondary-background);
}
.mobile-filter-accordion input[type=checkbox]:focus + label {
  box-shadow: none;
  border: solid 1px var(--classic-tertiary);
}
.mobile-filter-accordion input[type=checkbox] + label {
  box-shadow: none;
  border: solid 1px transparent;
  padding-right: 0.25rem;
}

.article-detail-hero-top {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 2rem;
}
@media (min-width: 1024.1px) {
  .article-detail-hero-top {
    display: grid;
    grid-template-columns: minmax(100px, calc(100% - 28rem)) minmax(100px, auto);
    grid-template-rows: repeat(2, minmax(100px, auto));
    gap: 1.375rem;
  }
}
.article-detail-hero-top.max-image-height .article-detail-hero-img img {
  max-height: 250px;
}
.article-detail-hero-top:has(.article-detail-hero-img) {
  width: 100%;
}
.article-detail-hero-top:not(:has(.article-detail-hero-img)) .article-detail-hero-content {
  max-width: 100%;
}
.article-detail-hero-subtitle .chip-subtitle {
  display: inline-block;
}
.article-detail-hero-content {
  display: flex;
  flex-flow: column;
  gap: 1.375rem;
  flex-grow: 1;
}
@media (max-width: 1024px) {
  .article-detail-hero-content {
    max-width: 100%;
  }
}
.article-detail-hero-img {
  max-width: 26rem;
}
@media (max-width: 1024px) {
  .article-detail-hero-img {
    max-width: 26rem;
    margin-inline: auto;
    width: 100%;
  }
}
@media (min-width: 1024.1px) {
  .article-detail-hero-img {
    grid-row: span 2;
  }
}
@media (max-width: 1024px) {
  .article-detail-hero-img img {
    max-width: 100%;
  }
}
.article-detail-hero-bottom {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
  flex-grow: 1;
}
.article-detail-hero-bottom:not(:has(.article-detail-hero-sponsor)) {
  justify-content: flex-end;
}
@media (min-width: 1024.1px) {
  .article-detail-hero-description {
    margin-bottom: 4rem;
  }
}
@media (max-width: 1024px) {
  .article-detail-hero-categories {
    width: 100%;
  }
}
.article-detail-hero-categories-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 0.5rem;
}
@media (max-width: 1024px) {
  .article-detail-hero-categories-list {
    justify-content: flex-start;
  }
}
.article-detail-hero-sponsor-name {
  margin-bottom: 0.25rem;
}
.article-detail-hero > .content-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  column-gap: 5rem;
  align-items: flex-end;
}
.article-detail-hero-ctas {
  display: flex;
  flex-flow: row wrap;
  gap: 0.38rem;
  grid-column: 1;
}
@media (max-width: 1024px) {
  .article-detail-hero-ctas {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .article-detail-hero-ctas button, .article-detail-hero-ctas a, .article-detail-hero-ctas > div {
    width: 100%;
  }
}
.article-detail-hero-ctas a.btn {
  background-color: var(--white);
}
.article-detail-hero-ctas a.btn:after {
  background-color: var(--white);
}
.article-detail-sponsor {
  gap: 1rem;
}
@media (max-width: 1024px) {
  .article-detail-sponsor {
    width: 100%;
  }
}
.article-detail-highlight {
  background-color: var(--secondary-background);
}
.article-detail-highlight .content-container {
  padding-block: 2rem;
}
.article-detail-highlight-content {
  text-align: center;
}
.article-detail-content {
  display: flex;
  flex-flow: column;
  gap: 1.375rem;
}
.article-detail-content-wrap {
  display: grid;
  grid-template-columns: minmax(175px, 1fr) minmax(300px, 3fr) minmax(300px, 1fr);
  gap: 2rem;
}
@media (max-width: 1024px) {
  .article-detail-content-wrap {
    grid-template-columns: minmax(100%, 1fr);
  }
}
@media (min-width: 1280.1px) {
  .article-detail-content-wrap {
    grid-template-columns: minmax(250px, 1fr) minmax(300px, 3fr) minmax(300px, 1fr);
  }
}
@media (min-width: 1600.1px) {
  .article-detail-content-wrap {
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 4fr) minmax(300px, 1fr);
  }
}
.article-detail-author {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .article-detail-author {
    max-width: calc(50% - 0.5rem);
    text-align: left;
    gap: 0;
  }
}
@media (max-width: 640px) {
  .article-detail-author {
    width: 100%;
    max-width: 100%;
    gap: 1rem;
  }
}
.article-detail-author-header {
  width: 100%;
}
.article-detail-author-content {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.article-detail-form .EPiServerForms .Form__Element:last-child {
  margin-bottom: 0;
}
.article-detail-related-title {
  margin-bottom: 1.375rem;
}
.article-detail-related-links {
  margin-top: 1.5rem;
}
.article-detail-related-links li + li {
  margin-top: 1.375rem;
}
@media (max-width: 1024px) {
  .article-detail-related-links-desktop {
    display: none;
  }
}
.article-detail-publish-header {
  margin-bottom: 1rem;
}
.article-detail-publish-date {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .article-detail-publish-date {
    max-width: calc(50% - 0.5rem);
    margin-bottom: 1rem;
  }
}
@media (max-width: 400px) {
  .article-detail-publish-date {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5rem;
  }
}
.article-detail-sidebar-left {
  display: flex;
  flex-flow: column;
  gap: 2rem;
}
@media (max-width: 1024px) {
  .article-detail-sidebar-left {
    display: flex;
    flex-flow: row wrap;
    gap: 1.5rem;
    justify-content: flex-start;
  }
}
@media (max-width: 1024px) {
  .article-detail-sidebar-right {
    display: flex;
    flex-flow: row wrap;
    gap: 1.375rem;
  }
}
@media (max-width: 640px) {
  .article-detail-sidebar-right {
    display: block;
  }
}
.article-detail-social {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}
@media (max-width: 1024px) {
  .article-detail-social {
    justify-content: flex-start;
  }
}
.article-detail-advertisement .advertisementblock + .advertisementblock {
  margin-block: 1rem;
}
.article-detail-advertisement .advertisement-block {
  padding: 0;
}
.article-detail-advertisement {
  display: flex;
  flex-flow: column;
}
.article-detail-advertisement-container {
  display: flex;
  flex-flow: column;
  row-gap: 2rem;
  justify-content: flex-start;
  flex-grow: 1;
}

#like-button {
  display: flex;
  flex-flow: row;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
}

.event-detail-hero-image {
  width: 100%;
  aspect-ratio: 8/3;
  overflow: hidden;
}
@media (max-width: 640px) {
  .event-detail-hero-image {
    aspect-ratio: 16/9;
  }
}
.event-detail-hero-image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: 50% 50%;
  object-position: var(--focal-point-h) var(--focal-point-v);
}
.event-detail-hero-video {
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
}
.event-detail-hero-vimeo-background {
  position: relative;
  z-index: -1;
}
.event-detail-hero-vimeo-background iframe {
  height: 100%;
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  transform: none;
  z-index: -1;
}
.event-detail-hero-vimeo-controls {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 9;
}
@media (max-width: 1024px) {
  .event-detail-hero-vimeo-controls {
    bottom: 1rem;
    right: 1rem;
  }
}
.event-detail-header {
  background-color: var(--secondary-background);
}
.event-detail-header-wrap {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  justify-content: space-between;
}
.event-detail-header-info {
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
  align-self: flex-end;
  text-align: right;
  place-items: flex-end;
}
@media (max-width: 1024px) {
  .event-detail-header-info {
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  }
}
.event-detail-header-content {
  flex-grow: 1;
  max-width: calc(100% - 200px);
}
.event-detail-header-content h1 {
  margin-top: 0.25rem;
  margin-bottom: 1.5rem;
}
.event-detail-header-img {
  max-width: 15.75rem;
  width: 20%;
}
@media (max-width: 640px) {
  .event-detail-header-img {
    display: none;
  }
}
.event-detail-subnav {
  background-color: var(--jewel-primary);
}
@media (max-width: 1024px) {
  .event-detail-subnav-link {
    display: block;
    width: 100%;
    padding: 1rem;
    text-align: center;
    background-color: var(--accordion-background-active);
  }
}
.event-detail-subnav-list {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  align-items: center;
}
@media (max-width: 1024px) {
  .event-detail-subnav-list {
    flex-flow: column;
    gap: 2px;
  }
}
.event-detail-subnav .content-container {
  padding-block: 1.375rem;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: 1rem;
}
@media (max-width: 1024px) {
  .event-detail-subnav .content-container {
    flex-flow: row wrap;
  }
}
@media (max-width: 640px) {
  .event-detail-subnav-cta {
    width: 100%;
  }
}
@media (max-width: 640px) {
  .event-detail-subnav-cta a {
    width: 100%;
  }
}
.event-detail-date {
  display: flex;
  flex-flow: column;
}

@media (min-width: 1024.1px) {
  .sub-nav-accordion {
    height: 100%;
  }
}
.sub-nav-accordion-button {
  padding-block: 0.55rem;
}
@media (min-width: 1024.1px) {
  .sub-nav-accordion-button {
    display: none;
  }
}
.sub-nav-accordion-list {
  margin-top: 0;
  flex-grow: 1;
}
@media (max-width: 640px) {
  .sub-nav-accordion-list {
    width: 100%;
  }
}
.sub-nav-accordion-list .event-detail-subnav-list {
  margin-bottom: 0;
}
@media (min-width: 1024.1px) {
  .sub-nav-accordion-list .event-detail-subnav-list {
    height: 100%;
  }
}
.sub-nav-accordion-panel {
  padding-inline: 0;
}
@media (min-width: 1024.1px) {
  .sub-nav-accordion-panel[aria-hidden=true] {
    display: block;
    height: 100% !important;
    background-color: transparent;
  }
}

.progress-block-grid {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  margin-top: 3.88rem;
  justify-content: center;
}
@media (max-width: 1280px) {
  .progress-block-grid {
    gap: 1.375rem;
  }
}
.progress-block-item {
  width: 100%;
  max-width: 29.5rem;
}
@media (min-width: 640.1px) {
  .progress-block-item {
    max-width: calc(50% - 1.5rem);
  }
}
@media (min-width: 1280.1px) {
  .progress-block-item {
    max-width: calc(25% - 1.5rem);
  }
}
.progress-block-item-content {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
@media (max-width: 640px) {
  .progress-block-item-content {
    text-align: left;
    max-width: calc(60% - 0.5rem);
  }
}
.progress-block-title {
  text-decoration: underline;
}
.progress-block-link {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  text-decoration: none;
}
@media (max-width: 640px) {
  .progress-block-link {
    flex-flow: row wrap;
  }
}
.progress-block-link:hover .progress-block-title, .progress-block-link:active .progress-block-title, .progress-block-link:focus .progress-block-title {
  text-decoration: underline;
}
.progress-block-link:hover .progress-block-image {
  background-color: var(--jewel-primary);
  transform: scale(1.1);
}
.progress-block-link:hover .progress-block-image svg, .progress-block-link:hover .progress-block-image img {
  fill: white;
}
.progress-block-link:hover .progress-block-image-hover {
  display: block;
}
.progress-block-link:hover .progress-block-image:has(.progress-block-image-hover) > img {
  display: none;
}
.progress-block-link:hover .progress-block-image:has(.progress-block-image-hover) > picture {
  display: none;
}
.progress-block-content {
  text-decoration: none;
}
.progress-block-image {
  border-style: solid;
  border-width: 1px;
  border-color: var(--jewel-primary);
  display: flex;
  place-content: center;
  max-width: 8.75rem;
  padding-inline: 1rem;
  padding-block: 2rem;
  background-color: var(--secondary-background);
  transition: all 0.05s ease;
}
@media (max-width: 640px) {
  .progress-block-image {
    max-width: calc(40% - 0.5rem);
  }
}
.progress-block-image img {
  max-width: 3rem;
  max-height: 3rem;
  width: 100%;
  height: 100%;
  display: block;
  margin-inline: 2rem;
  fill: var(--jewel-primary);
}
.progress-block-image svg {
  fill: var(--jewel-primary);
}
.progress-block-image-hover {
  display: none;
}
.progress-block .global-heading-block.component-header {
  text-align: center;
  justify-content: center;
}
.progress-block .global-heading-block .component-header-wrapper {
  flex-flow: column;
  align-items: center;
}

.directory-listing > .content-container {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  gap: 2rem;
}
@media (max-width: 1024px) {
  .directory-listing > .content-container {
    grid-template-columns: minmax(100px, 1fr);
  }
}
.directory-listing > .content-container .loader {
  grid-column: span 2;
}
.directory-listing-search {
  display: flex;
  flex-flow: row;
  margin-bottom: 1rem;
}
.directory-listing-search input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  max-width: 100%;
}
.directory-listing .sort-select {
  max-width: 15rem;
  flex-grow: 1;
}
@media (max-width: 640px) {
  .directory-listing .sort-select {
    max-width: 100%;
    width: auto;
  }
}
.directory-listing .sort-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.directory-listing-cards {
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
}
.directory-listing-card-cell {
  word-wrap: break-word;
}
.directory-listing-card-cell img {
  max-width: 6rem;
}
.directory-listing-card-cell ul {
  list-style-type: disc;
  margin-left: 1.375rem;
}
.directory-listing-card-cell + .directory-listing-card-cell {
  margin-top: 0.25rem;
}
.directory-listing-card-wrap {
  border: solid 1px var(--button-primary-foreground);
  padding: 1.5rem;
  position: relative;
  background: var(--secondary-background);
}
.directory-listing-card-wrap.open {
  border: solid 1px var(--button-disabled-background);
}
.directory-listing-card-wrap.directory-listing-highlight {
  background-color: var(--jewel-primary);
  border-color: var(--jewel-primary);
}
.directory-listing-card-wrap.directory-listing-highlight .directory-listing-card-modal-content {
  background-color: var(--jewel-primary);
  border-color: var(--jewel-primary);
}
.directory-listing-card-modal {
  position: fixed;
  background: var(--button-disabled-background);
  width: 100%;
  left: 0;
  top: 0;
  z-index: 8;
  height: 100%;
  overflow-y: scroll;
}
.directory-listing-card-modal-content {
  position: relative;
  background: var(--secondary-background);
  margin-inline: auto;
  top: 20%;
  max-width: 80vw;
}
.directory-listing-card-modal-content > .btn-primary {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 3;
}
.directory-listing-card-modal-content .primary-data-header {
  max-width: calc(100% - 5rem);
}
.directory-listing-card-toggle {
  justify-self: flex-end;
}
.directory-listing-card-toggle-mobile {
  width: 100%;
  margin-top: 1rem;
}
.directory-listing-context:has(.sort-container) + .directory-results .result-count {
  margin-top: -2.875rem;
}
@media (max-width: 640px) {
  .directory-listing-context:has(.sort-container) + .directory-results .result-count {
    margin-top: 0;
  }
}
.directory-listing-sidebar {
  overflow: hidden;
}

.directory-results .result-count {
  margin-bottom: 2rem;
  line-height: 2.875rem;
  max-width: 50%;
}
@media (max-width: 640px) {
  .directory-results .result-count {
    max-width: 100%;
  }
}
.directory-results-message {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 2rem;
  padding: 2rem;
  gap: 2rem;
}
@media (max-width: 1024px) {
  .directory-results-message {
    margin-top: 1.375rem;
    padding: 1rem;
    gap: 1rem;
  }
}
.directory-results-message .ph-info {
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: var(--jewel-tertiary);
}

.primary-data-header {
  display: grid;
  margin-bottom: 1.5rem;
}
.primary-data-header:has(.primary-data-header-title) {
  grid-template-columns: 3fr 1fr;
  gap: 1.5rem;
  align-items: end;
}
@media (max-width: 640px) {
  .primary-data-header:has(.primary-data-header-title) {
    grid-template-columns: 1fr;
  }
}
.primary-data-flex {
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;
  column-gap: 2rem;
}
@media (max-width: 1024px) {
  .primary-data-flex {
    column-gap: 1.5rem;
  }
}
.primary-left-data, .primary-right-data {
  max-width: 100%;
}

.secondary-data {
  margin-top: 1.5rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;
}
.secondary-data:has(.secondary-left-data):has(.secondary-right-data) .secondary-left-data, .secondary-data:has(.secondary-left-data):has(.secondary-right-data) .secondary-right-data {
  width: calc(50% - 0.75rem);
}
@media (max-width: 640px) {
  .secondary-data:has(.secondary-left-data):has(.secondary-right-data) .secondary-left-data, .secondary-data:has(.secondary-left-data):has(.secondary-right-data) .secondary-right-data {
    width: 100%;
  }
}
.secondary-left-data, .secondary-right-data {
  width: 100%;
  word-wrap: break-word;
}

.directory-top-content .wysiwyg-block {
  padding-block: 1.5rem;
}
.directory-top-content + .directory-listing-page-app .content-container {
  padding-top: 1rem;
}

@media (min-width: 1024.1px) {
  .search-results {
    grid-column: 2;
    min-height: 100vh;
  }
}
.search-results-sidebar {
  overflow: hidden;
}
@media (min-width: 1024.1px) {
  .search-results-sidebar {
    grid-column: 1;
    grid-row: 1/span 3;
  }
}
.search-results-sidebar > .mobile-filter-accordion > .accordion-trigger {
  background-color: var(--dark-primary);
  border-color: var(--dark-primary);
  color: #fff;
}
@media (min-width: 1024.1px) {
  .search-results-pagination {
    grid-column: 2;
  }
}
.search-results-pagination .pagination {
  justify-content: center;
}
.search-results-header {
  display: flex;
  flex-flow: row wrap;
  row-gap: 1.5rem;
}
@media (min-width: 1024.1px) {
  .search-results-header {
    grid-column: 2;
  }
}
.search-results-header-sort {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1.375rem;
}
@media (max-width: 640px) {
  .search-results-header-desc {
    width: 100%;
  }
}
.search-results-header .header-search-button, .search-results-header .header-search-button i:before {
  background-color: var(--dark-primary);
  color: #fff;
}
.search-results-header .header-search-button {
  max-height: 2.875rem;
}
.search-results-header .search-input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
  max-width: 100%;
  background: #f6f6f6;
  max-height: 2.875rem;
}
.search-results-header .search-autocomplete {
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  width: 100%;
  max-width: 75%;
}
@media (max-width: 640px) {
  .search-results-header .search-autocomplete {
    max-width: 100%;
  }
}
.search-results-header .sort-container {
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}
.search-results-header .sort-select {
  flex-grow: 1;
  max-width: 10rem;
}
@media (max-width: 640px) {
  .search-results-header .sort-select {
    max-width: 100%;
  }
}
.search-results-wrap {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(100px, 3fr);
  gap: 1.5rem;
  column-gap: 2rem;
}
@media (max-width: 1024px) {
  .search-results-wrap {
    grid-template-columns: minmax(100px, 1fr);
  }
}

.search-listing-card {
  display: flex;
  flex-flow: row wrap;
  column-gap: 1.5rem;
  padding: 0.75rem;
  border: solid 1px var(--card-border);
}
.search-listing-card + .search-listing-card {
  margin-top: 1.38rem;
}
@media (min-width: 640.1px) {
  .search-listing-card:has(.search-listing-img) .search-listing-content {
    max-width: calc(100% - 13.15rem);
  }
}
.search-listing-card:has(a:hover) {
  background-color: var(--secondary-background);
}
.search-listing-img {
  max-width: 11.65rem;
}
@media (min-width: 640.1px) {
  .search-listing-img {
    width: 11.65rem;
  }
}
@media (max-width: 640px) {
  .search-listing-img {
    display: none;
  }
}
.search-listing-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.75rem;
  max-width: 100%;
}
@media (max-width: 640px) {
  .search-listing-header {
    flex-flow: column-reverse;
  }
}
.search-listing-content {
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.75rem;
  justify-content: space-between;
}
.search-listing-title {
  width: 100%;
  word-wrap: break-word;
}
@media (max-width: 640px) {
  .search-listing-title {
    max-width: 100%;
  }
}
.search-listing-categories {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}
.search-listing-category {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.375;
  font-size: var(--label-text);
  line-height: var(--label-text-height);
}
.search-listing-category:hover {
  text-decoration: underline;
}
.search-listing-category-restricted {
  display: flex;
  flex-flow: row;
  gap: 0.25rem;
  align-items: center;
  line-height: normal;
  background: var(--light-primary);
  color: var(--dark-primary);
  border-color: var(--dark-primary);
  text-decoration: none;
  cursor: default;
  font-weight: 700;
}
.search-listing-category-restricted:hover {
  cursor: default;
  text-decoration: none;
}

.popular-searches {
  margin-block: 2rem;
}
@media (max-width: 1024px) {
  .popular-searches {
    display: none;
  }
}
.popular-searches-list {
  display: flex;
  gap: 0.75rem;
  flex-flow: column;
}

.search-autocomplete {
  position: relative;
}
.search-autocomplete-results {
  position: absolute;
  top: 100%;
  right: 2.625rem;
  background: #fff;
  border: solid 1px var(--input-background);
  z-index: 9;
  padding: 1rem;
  width: calc(100% - 2.625rem);
  display: none;
}
.search-autocomplete-results.visible {
  display: block;
}
.search-autocomplete-results.visible button {
  width: 100%;
}
.search-autocomplete-results.visible button:hover, .search-autocomplete-results.visible button:focus {
  background-color: var(--input-background);
}

.search-context-selector {
  width: 25%;
}
@media (max-width: 640px) {
  .search-context-selector {
    width: 100%;
  }
}
.search-context-selector .sort-select {
  width: 100%;
  max-width: 100%;
  background-color: var(--input-background);
}

.my-news-preferences {
  margin-top: 1.5rem;
}
.my-news-preferences .section-options {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 50%));
  column-gap: 2rem;
  row-gap: 0.38rem;
  margin-bottom: 1.5rem;
  margin-top: 0.75rem;
}
@media (min-width: 1024.1px) {
  .my-news-preferences .section-options {
    max-width: 47rem;
  }
}
@media (max-width: 640px) {
  .my-news-preferences .section-options {
    grid-template-columns: repeat(1, minmax(100px, 100%));
  }
}
.my-news-preferences-page .alert-success, .my-news-preferences-page .alert-error {
  padding: 1.375rem;
  margin-bottom: 1rem;
}
.my-news-preferences-ctas {
  display: flex;
  flex-flow: row wrap;
  gap: 0.38rem;
  grid-column: 1;
}
@media (max-width: 1024px) {
  .my-news-preferences-ctas {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .my-news-preferences-ctas button, .my-news-preferences-ctas a, .my-news-preferences-ctas > div {
    width: 100%;
  }
}
.my-news-preferences-count {
  margin-bottom: 1.5rem;
  font-weight: 700;
}
.my-news-header {
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}
.my-news-header-desc {
  max-width: 42.375rem;
}
.my-news-items {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  align-items: flex-start;
}
.my-news-category-card {
  background-color: var(--dark-secondary);
  width: 100%;
  padding: 1.5rem;
}
@media (max-width: 640px) {
  .my-news-category-card {
    padding: 1rem;
  }
}
@media (min-width: 767.1px) {
  .my-news-category-card {
    width: calc(50% - 1rem);
  }
}
@media (min-width: 1024.1px) {
  .my-news-category-card {
    width: calc(33.3333% - 1.35rem);
  }
}
.my-news-category-card.user-defined {
  background-color: var(--secondary-background);
}
.my-news-item {
  border-top: solid 1px;
  border-color: var(--my-news-border);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  display: flex;
  flex-flow: column;
  gap: 0.25rem;
}
.my-news-item-title {
  width: 100%;
  font-weight: 700;
}
.my-news-item-content > a {
  display: flex;
  flex-flow: row wrap;
  gap: 0.75rem;
}
@media (min-width: 640.1px) {
  .my-news-item-content > a:has(img) .my-news-item-title {
    max-width: calc(100% - 6rem);
  }
}
.my-news-item-image {
  max-width: 5rem;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}
@media (max-width: 640px) {
  .my-news-item-image {
    display: none;
  }
}
.my-news-item-byline {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.42857;
  font-size: var(--x-small-text);
  line-height: var(--x-small-text-height);
}
.my-news-no-articles {
  border-top: solid 1px;
  border-color: var(--my-news-border);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}
.my-news-page .like-count {
  color: var(--jewel-primary);
  font-size: 0.875rem;
  line-height: 1.42857;
  font-size: var(--x-small-text);
  line-height: var(--x-small-text-height);
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: var(--calendar-range-color);
}

.rbc-off-range-bg {
  background: var(--calendar-range-bg-color);
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #fff;
}
.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: text;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: var(--classic-tertiary);
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: var(--button-primary-background);
  border-color: var(--button-primary-background);
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: var(--button-primary-foreground);
  background-color: var(--button-primary-background);
  border-color: var(--button-primary-background);
}
.rbc-toolbar button:focus {
  color: var(--button-primary-foreground);
  background-color: var(--button-primary-background);
  border-color: var(--button-primary-background);
}
.rbc-toolbar button:hover {
  color: var(--button-primary-foreground);
  background-color: var(--button-primary-background);
  border-color: var(--button-primary-background);
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .rbc-toolbar {
    flex-direction: column;
  }
}
.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 0;
  color: #000711;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #fff;
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #fff;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
  padding: 0;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #fff;
}
.rbc-show-more:hover, .rbc-show-more:focus {
  color: #fff;
}

.rbc-month-view {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 8px solid #fff;
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 9px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 8px solid #fff;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 8px solid #fff;
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #fff;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #fff;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #fff;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #fff;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #fff;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #fff;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid #fff;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid white;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #fff;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}
.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #fff;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #fff;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #fff;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #fff;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #fff;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #fff;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #fff;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #fff;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #fff;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #fff;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #fff;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #fff;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

.rbc-event.rbc-event-continues-after, .rbc-day-slot .rbc-event-continues-after.rbc-background-event {
  padding-right: 0;
  position: relative;
}
.rbc-event.rbc-event-continues-after:after, .rbc-day-slot .rbc-event-continues-after.rbc-background-event:after {
  display: block;
  top: 6px;
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 12px);
  left: 100%;
  background-color: #fff;
}

.rbc-event.rbc-event-continues-prior, .rbc-day-slot .rbc-event-continues-prior.rbc-background-event {
  padding-left: 0;
  margin-left: -1px;
}

.rbc-date-cell.rbc-now {
  color: #fff;
}
.rbc-day-bg:not(.rbc-today):not(.rbc-off-range-bg) {
  background-color: #F8F5F2;
}
.rbc-calendar .content-container {
  width: 100%;
}
.rbc-month-row {
  min-height: 300px;
}
.rbc-btn-group {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem;
}
.rbc-active.btn-primary {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.rbc-event:hover .rbc-event-content:has(.event-style-primary), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-primary) {
  background-color: #019221;
  border-color: #019221;
}
.rbc-event:hover .rbc-event-content:has(.event-style-secondary), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-secondary) {
  background-color: #0FAEC3;
  border-color: #0FAEC3;
}
.rbc-event:hover .rbc-event-content:has(.event-style-tertiary), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-tertiary) {
  background-color: #C30A36;
  border-color: #C30A36;
}
.rbc-event:hover .rbc-event-content:has(.event-style-quaternary), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-quaternary) {
  background-color: #0052CC;
  color: white;
  border-color: #0052CC;
}
.rbc-event:hover .rbc-event-content:has(.event-style-quinary), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-quinary) {
  background-color: #7D1D74;
  color: white;
  border-color: #7D1D74;
}
.rbc-event:hover .rbc-event-content:has(.event-style-senary), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-senary) {
  background-color: #F07E15;
  border-color: #F07E15;
}
.rbc-event:hover .rbc-event-content:has(.event-style-septenary), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-septenary) {
  background-color: #F0CD15;
  border-color: #F0CD15;
}
.rbc-event:hover .rbc-event-content:has(.event-style-default), .rbc-day-slot .rbc-background-event:hover .rbc-event-content:has(.event-style-default) {
  background-color: #585858;
  color: white;
  border-color: #585858;
}
.rbc-toolbar button:has(.arrow) {
  padding: 7px 12px;
  display: flex;
  place-items: center;
}
.rbc-toolbar button i {
  font-size: 1.5rem;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-color: var(--button-disabled-background);
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  background-color: var(--button-disabled-background);
}
.rbc-row-content-scrollable {
  overflow: hidden;
}

.rbc-toolbar button .rbc-row-segment:first-child:nth-last-child(1) .rbc-event, .rbc-toolbar button .rbc-row-segment:first-child:nth-last-child(1) .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-toolbar button .rbc-row-segment:first-child:nth-last-child(1) .rbc-background-event {
  padding-right: 20px;
}
.rbc-toolbar button .rbc-row-segment:first-child:nth-last-child(1) .rbc-event.rbc-event-continues-after, .rbc-toolbar button .rbc-row-segment:first-child:nth-last-child(1) .rbc-day-slot .rbc-event-continues-after.rbc-background-event, .rbc-day-slot .rbc-toolbar button .rbc-row-segment:first-child:nth-last-child(1) .rbc-event-continues-after.rbc-background-event {
  padding-right: 0;
  position: relative;
}

.rbc-event-content {
  background-color: white;
  padding: 6px 12px;
  white-space: normal;
  position: relative;
  border-width: 2px;
  border-style: solid;
}
.rbc-event-content:has(.event-style-primary) {
  background-color: #DFF8E4;
  border-color: #DFF8E4;
  border-left-color: #019221;
}
.rbc-event-content:has(.event-style-primary):has(.is-highlighted) {
  border-color: #019221;
}
.rbc-event-content:has(.event-style-secondary) {
  background-color: #DBF9FD;
  border-color: #DBF9FD;
  border-left-color: #0FAEC3;
}
.rbc-event-content:has(.event-style-secondary):has(.is-highlighted) {
  border-color: #0FAEC3;
}
.rbc-event-content:has(.event-style-tertiary) {
  background-color: #FFEDF1;
  border-color: #FFEDF1;
  border-left-color: #C30A36;
}
.rbc-event-content:has(.event-style-tertiary):has(.is-highlighted) {
  border-color: #C30A36;
}
.rbc-event-content:has(.event-style-quaternary) {
  background-color: #E7EDFF;
  border-color: #E7EDFF;
  border-left-color: #0052CC;
}
.rbc-event-content:has(.event-style-quaternary):has(.is-highlighted) {
  border-color: #0052CC;
}
.rbc-event-content:has(.event-style-quinary) {
  background-color: #FCEEFA;
  border-color: #FCEEFA;
  border-left-color: #7D1D74;
}
.rbc-event-content:has(.event-style-quinary):has(.is-highlighted) {
  border-color: #7D1D74;
}
.rbc-event-content:has(.event-style-senary) {
  background-color: #FFECE7;
  border-color: #FFECE7;
  border-left-color: #F07E15;
}
.rbc-event-content:has(.event-style-senary):has(.is-highlighted) {
  border-color: #F07E15;
}
.rbc-event-content:has(.event-style-septenary) {
  background-color: #FFFAE2;
  border-color: #FFFAE2;
  border-left-color: #F0CD15;
}
.rbc-event-content:has(.event-style-septenary):has(.is-highlighted) {
  border-color: #F0CD15;
}
.rbc-event-content:has(.event-style-default) {
  background-color: #F8F8F8;
  border-color: #F8F8F8;
  border-left-color: #585858;
}
.rbc-event-content:has(.event-style-default):has(.is-highlighted) {
  border-color: #585858;
}

.rbc-row-segment .rbc-event-content {
  white-space: normal;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background: none;
}

.rbc-row-segment:not(:first-child) {
  padding: 0;
  padding-left: 5px;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background: none;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-primary), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-primary) {
  background-color: #019221;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-secondary), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-secondary) {
  background-color: #0FAEC3;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-tertiary), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-tertiary) {
  background-color: #C30A36;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-quaternary), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-quaternary) {
  background-color: #0052CC;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-quinary), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-quinary) {
  background-color: #7D1D74;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-senary), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-senary) {
  background-color: #F07E15;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-septenary), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-septenary) {
  background-color: #F0CD15;
}
.rbc-event.rbc-selected .rbc-event-content:has(.event-style-default), .rbc-day-slot .rbc-selected.rbc-background-event .rbc-event-content:has(.event-style-default) {
  background-color: #585858;
}

.rbc-month-row {
  overflow: initial;
}

.themed-toolbar {
  background-color: var(--jewel-primary);
  margin-bottom: 2rem;
}
.themed-toolbar .content-container {
  max-width: 1500px;
}
@media (min-width: 1600.1px) {
  .themed-toolbar .content-container {
    max-width: 122.5rem;
  }
}
.themed-toolbar .rbc-toolbar {
  flex-direction: row;
}

.event-wrapper {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  gap: 2rem;
}
@media (max-width: 1280px) {
  .event-wrapper {
    gap: 2rem;
  }
}
@media (max-width: 1024px) {
  .event-wrapper {
    grid-template-columns: 1fr;
    gap: 1.375rem;
  }
}

.list-event-item {
  width: 100%;
}
.list-event-item + .list-event-item {
  margin-top: 2rem;
}
.list-event-card-content {
  cursor: pointer;
  background-color: #F8F8F8;
  padding: 1rem;
  margin-top: 0.375rem;
  display: flex;
  flex-flow: column;
  gap: 0.375rem;
  position: relative;
  border-style: solid;
  border-width: 2px;
}
.list-event-card-content.event-style-primary {
  background-color: #DFF8E4;
  border-color: #DFF8E4;
  border-left-color: #019221;
}
.list-event-card-content.event-style-primary.is-highlighted {
  border-color: #019221;
}
.list-event-card-content.event-style-secondary {
  background-color: #DBF9FD;
  border-color: #DBF9FD;
  border-left-color: #0FAEC3;
}
.list-event-card-content.event-style-secondary.is-highlighted {
  border-color: #0FAEC3;
}
.list-event-card-content.event-style-tertiary {
  background-color: #FFEDF1;
  border-color: #FFEDF1;
  border-left-color: #C30A36;
}
.list-event-card-content.event-style-tertiary.is-highlighted {
  border-color: #C30A36;
}
.list-event-card-content.event-style-quaternary {
  background-color: #E7EDFF;
  border-color: #E7EDFF;
  border-left-color: #0052CC;
}
.list-event-card-content.event-style-quaternary.is-highlighted {
  border-color: #0052CC;
}
.list-event-card-content.event-style-quinary {
  background-color: #FCEEFA;
  border-color: #FCEEFA;
  border-left-color: #7D1D74;
}
.list-event-card-content.event-style-quinary.is-highlighted {
  border-color: #7D1D74;
}
.list-event-card-content.event-style-senary {
  background-color: #FFECE7;
  border-color: #FFECE7;
  border-left-color: #F07E15;
}
.list-event-card-content.event-style-senary.is-highlighted {
  border-color: #F07E15;
}
.list-event-card-content.event-style-septenary {
  background-color: #FFFAE2;
  border-color: #FFFAE2;
  border-left-color: #F0CD15;
}
.list-event-card-content.event-style-septenary.is-highlighted {
  border-color: #F0CD15;
}
.list-event-card-content.event-style-default {
  background-color: #F8F8F8;
  border-color: #F8F8F8;
  border-left-color: #585858;
}
.list-event-card-content.event-style-default.is-highlighted {
  border-color: #585858;
}
.list-event-card .event-details {
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.event-calendar-wrapper {
  position: relative;
}
.event-calendar-cta {
  margin-block: 0.5rem;
}
.event-calendar-page-heading .rich-text {
  margin-block: 0.5rem;
}

.event-view-modal {
  position: sticky;
  top: 0;
  background: var(--card-background);
  width: 100%;
  height: 0;
  z-index: 6;
}
.event-view-modal-inner {
  display: flex;
  flex-flow: column;
  gap: 2rem;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  background-color: var(--accordion-background);
  overflow-y: scroll;
}
.event-view-modal .content-container {
  width: calc(75% - 2rem);
  background-color: #fff;
}
@media (max-width: 1024px) {
  .event-view-modal .content-container {
    width: 100%;
  }
}
.event-view-modal-close {
  display: flex;
  justify-content: flex-end;
}

.event-view-content {
  display: flex;
  flex-flow: row;
  margin-top: 0.5rem;
}
@media (max-width: 640px) {
  .event-view-content {
    flex-wrap: wrap;
  }
}
.event-view-content-image {
  max-width: 30%;
}
@media (max-width: 640px) {
  .event-view-content-image {
    max-width: 100%;
    width: 100%;
  }
}
.event-view-content-inner {
  flex-grow: 1;
  display: flex;
  margin-top: 0;
  gap: 0.5rem;
}
.event-view-content-inner .title {
  width: 100%;
}
.event-view-content-inner .event-details {
  width: 100%;
}
.event-view-content-inner .subtitle-content {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;
}

body:has(.float-block-open) div#lhnHocButton.lhnslide {
  bottom: 15rem;
  right: 0;
}

div#lhnHocButton div.lhnHocChatTxtCont span {
  font-family: "AcherusGrotesque", Arial, sans-serif;
  font-size: 1rem;
}

div#lhnHocButton div.lhnHocChatBtnCont {
  background-color: var(--jewel-primary);
  height: auto;
}

div#lhnHocButton div.lhnHocChatBtn {
  background-color: var(--jewel-primary);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

div#lhnHocButton div.lhnHocChatBubbleCont {
  height: 30px;
  float: none !important;
}

div#lhnHocButton div.lhnHocChatTxtCont {
  height: 20px;
  float: none !important;
  height: auto;
}

div#lhnHocButton div.lhnHocFrontBubble:before {
  border: 0;
  content: "\e160";
  font-family: "Phosphor" !important;
  font-size: 2rem;
  position: relative;
  left: auto;
  top: auto;
  color: white;
  line-height: 1.5rem;
}

div#lhnHocButton div.lhnHocChatBubble:after {
  display: none;
}

div#lhnHocButton div.lhnHocCtCont {
  display: none !important;
}

div#lhnHocButton div.lhnHocFrontBubble {
  background: none;
  width: auto;
  height: auto;
  margin-right: 0;
}

div#lhnHocButton.lhnslide div.lhnHocBackBubble {
  display: none !important;
}

div#lhnHocButton.lhnslide div.lhnHocChatBubble {
  margin-right: 0;
}

div#lhnHocButton.lhnslide .lhnHocChatBtnCont {
  border-radius: 0;
}
@media (max-width: 640px) {
  div#lhnHocButton.lhnslide .lhnHocChatBtnCont {
    padding: 10px 12px;
  }
}

div#lhnHelpOutCenter.lhnWindow *, div#lhnHelpOutCenter.lhnWindow :after, div#lhnHelpOutCenter.lhnWindow :before {
  font-family: "AcherusGrotesque", Arial, sans-serif;
}

#lhnHelpOutCenter div.lhnWindow-header {
  background-color: var(--jewel-primary);
  border-radius: 0;
}
#lhnHelpOutCenter div.lhnWindow-header * {
  color: white;
  font-family: "Jubilat", Arial, sans-serif;
}

div#lhnHocButton div.lhnHocButtonClear {
  display: none;
}

div#lhnHocButton div.lhnHocChatTxtCont {
  /* show the text on desktop
  @include media-min(small) {
      margin: auto;
      padding: 0;
      width: auto;
      height: auto;
      overflow: hidden;
      clip: initial;
      position: relative;
  }*/
}

#lhnHelpOutCenter .lhnSurveyForm div.lhnRadioGroup input:checked + span, #lhnHelpOutCenter button.form_submit, #lhnHelpOutCenter button.lhnFormButton {
  background-color: var(--dark-primary);
  text-shadow: none;
  border-radius: 0;
}

#lhnHelpOutCenter div.lhnWindow-header div.lhnWindow-header-controls img {
  filter: none;
}

.atlwdg-trigger.atlwdg-RIGHT {
  top: 47%;
}

div#lhnHocButton.lhnslide {
  bottom: 10rem;
  right: 0;
}