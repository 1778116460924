﻿/// <reference path="../../entries/main">

@each $_theme, $_values in $themes {
    $_dark-primary: map-get($_values, 'dark-primary');
    $_dark-secondary: map-get($_values, 'dark-secondary');
    $_dark-tertiary: map-get($_values, 'dark-tertiary');
    $_classic-primary: map-get($_values, 'classic-primary');
    $_classic-secondary: map-get($_values, 'classic-secondary');
    $_classic-tertiary: map-get($_values, 'classic-tertiary');
    $_jewel-primary: map-get($_values, 'jewel-primary');
    $_jewel-secondary: map-get($_values, 'jewel-secondary');
    $_jewel-tertiary: map-get($_values, 'jewel-tertiary');
    $_light-primary: map-get($_values, 'light-primary');
    $_light-secondary: map-get($_values, 'light-secondary');
    $_light-tertiary: map-get($_values, 'light-tertiary');

    %theme-#{$_theme} {
        @include generate_colors($_values);
    }

    .theme-#{$_theme} {
        @extend %theme-#{$_theme};
    }
}
@each $_theme in $default-themes {
    .theme-#{$_theme} {
        @extend %theme-default;
    }
}
%default-theme-vars {
    --dark-tertiary: #{$ara-dark-grey};
    --classic-tertiary: #{$ara-classic-grey};
    --jewel-tertiary: #{$ara-bright-grey};
    --light-tertiary: #{$ara-light-grey};
    --black: #{$black};
    --white: #{$white};
    
    color: var(--foreground);
    --alert-green: #{$alert-green};
    --alert-yellow: #{$alert-yellow};
    --alert-red: #{$alert-red};
    --alert-bright-red: #{$alert-bright-red};
    --alert-grey: #{$alert-grey};
}


.theme-light {
    @extend %default-theme-vars;
    --background: #{$default-background};
    --background-color: #{$default-background};
    --secondary-background: #{$secondary-background};
    --foreground: #{$black};
    --button-primary-background: var(--dark-primary);
    --button-primary-foreground: #{$light-copy};
    --button-disabled-background: #{$button-dark-disabled};
    --button-disabled-foreground: #{$text-disabled};
    --button-highlight-background: var(--jewel-primary);
    --button-highlight-foreground: #{$black};
    --button-secondary-background: var(--jewel-primary);
    --button-secondary-foreground: #{$black};
    --button-tertiary-foreground: #{$black};
    --button-tertiary-background: var(--jewel-primary);
    --button-secondary-icon: #{$white};
    --button-selected: #{$input-focus};
    --accordion-background: #{$button-accordion-light};
    --accordion-background-active: #{$button-accordion-light-active};
    --accordion-border: #{$light-background-active};
    --accordion-border-active: #{$light-copy};
    --accordion-icon-background: #{$black};
    --accordion-icon-color: #{$white};
    --chip-background: #{$input-active};
    --chip-outline-background: #{$card-background};
    --chip-outline-border: #{$border-disabled};
    --tab-background: #{$light-background-active};
    --tab-background-active: #{$light-background-active};
    --tab-border: #{$border-secondary};
    --card-background: #{$card-background};
    --card-border: #{$light-background-active};
    --carousel-background: #{$border-secondary};
    --swiper-theme-color: #{$black };
    --scrollbar-background: #{$input-focus};
    --scrollbar-foreground: $black;
    --table-background: #{$table-background};
    --inactive-button: #{$inactive-background};
    --download-card: #{$input-active};
    --counter-background: #{$counter-background};
    --input-background: #{$input-active};
    --input-color: #{$border-secondary};
    --input-background-disabled: #{$input-disabled};
    --input-color-disabled: #{$border-secondary};
    --registration-header: var(--dark-primary);
    --registration-body: #{$secondary-background};
    --link-color: var(--classic-primary);
    --link-color-active: var(--jewel-primary);
    --link-color-visited: var(--dark-primary);
    --calendar-range-color: #{$border-disabled};
    --calendar-range-bg-color: #{$input-disabled};
    --my-news-border: #{$border-dark};
}
.theme-dark {
    @extend %default-theme-vars;
    --foreground: #{$light-copy };
    --button-primary-background: #{$tertiary-background };
    --button-primary-foreground: #{$black };
    --button-disabled-background: #{$text-disabled-light };
    --button-disabled-foreground: #{$button-light-disabled };
    --button-secondary-background: #{$tertiary-background };
    --button-secondary-foreground: #{$tertiary-background };
    --button-tertiary-foreground: #{$black};
    --button-tertiary-background: #{$tertiary-background };
    --button-secondary-icon: #{$black };
    --button-selected: #{$input-focus};
    --accordion-background: #{$dark-background };
    --accordion-background-active: #{$dark-background-active };
    --accordion-border: rgba(#{$black }, .22);
    --accordion-border-active: #{$light-background };
    --accordion-icon-background: #{$light-copy};
    --accordion-icon-color: #{$black};
    --chip-background: #{$light-background };
    --chip-outline-background: #{$card-background-dark};
    --chip-outline-border: #{$border-disabled-dark};
    --tab-background: var(--background-color);
    --tab-background-active: #{$light-background};
    --tab-border: #{$border-disabled-dark};
    --card-background: #{$background-black};
    --card-border: #{$border-black};
    --carousel-background: #{$medium-background};
    --swiper-theme-color: #{$light-copy };
    --scrollbar-background: #{$input-focus};
    --scrollbar-foreground: $black;
    --table-background: #{$table-background};
    --inactive-button: #{$inactive-background-dark};
    --download-card: #{$card-background};
    --input-background: #{$light-background};
    --input-color: #{$light-copy };
    --input-background-disabled: #{$input-background-disabled};
    --input-color-disabled: #{$text-disabled};
    --registration-header: #{$header-dark};
    --registration-body: #{$body-dark};
    --link-color: var(--light-primary);
    --link-color-active: var(--jewel-primary);
    --link-color-visited: var(--light-secondary);
    --my-news-border: #{$text-disabled-darker};
    --counter-background: #{$border-secondary};
}

///colored backgrounds
.theme-dark-primary {
    --background-color: var(--dark-primary);
    background-color: var(--dark-primary);
    --svg-fill-primary: var(--jewel-primary);
    --svg-fill-secondary: var(--classic-primary);
    --gradient-color-top: rgba(var(--RGB_dark-primary), 0.8);
    --gradient-color-bottom: rgba(var(--RGB_classic-primary), 0.8);
    --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}
.theme-dark-secondary {
    --background-color: var(--dark-secondary);
    background-color: var(--dark-secondary);
    --svg-fill-primary: var(--jewel-secondary);
    --svg-fill-secondary: var(--classic-secondary);
    --gradient-color-top: rgba(var(--RGB_dark-secondary), 0.8);
    --gradient-color-bottom: rgba(var(--RGB_classic-secondary), 0.8);
    --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}
.theme-dark-tertiary {
    --background-color: var(--dark-tertiary);
    background-color: var(--dark-tertiary);
    --svg-fill-primary: var(--jewel-tertiary);
    --svg-fill-secondary: var(--classic-tertiary);
    --gradient-color-top: rgba(var(--RGB_dark-tertiary), 0.8);
    --gradient-color-bottom: rgba(var(--RGB_classic-tertiary), 0.8);
    --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}
.theme-classic-primary {
    --background-color: var(--classic-primary);
    background-color: var(--classic-primary);
    --svg-fill-primary: var(--jewel-primary);
    --svg-fill-secondary: var(--jewel-primary);
    --gradient-color-top: rgba(var(--RGB_classic-primary), 0.8);
    --gradient-color-bottom: rgba(var(--RGB_jewel-primary), 0.8);
    --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}
.theme-classic-secondary {
    --background-color: var(--classic-secondary);
    background-color: var(--classic-secondary);
    --svg-fill-primary: var(--jewel-secondary);
    --svg-fill-secondary: var(--jewel-secondary);
    --gradient-color-top: rgba(var(--RGB_classic-secondary), 0.8);
    --gradient-color-bottom: rgba(var(--RGB_jewel-secondary), 0.8);
    --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}
.theme-classic-tertiary {
    --background-color: var(--classic-tertiary);
    background-color: var(--classic-tertiary);
    --svg-fill-primary: var(--jewel-tertiary);
    --svg-fill-secondary: var(--jewel-tertiary);
    --gradient-color-top: rgba(var(--RGB_classic-tertiary), 0.8);
    --gradient-color-bottom: rgba(var(--RGB_jewel-tertiary), 0.8);
    --background-gradient: linear-gradient(180deg,var(--gradient-color-top) 0%, var(--gradient-color-bottom) 100%);
}

//only svg backgrounds with white
.theme-light-primary {
    --background-color: #{$default-background };
    background-color: #{$default-background };
    --svg-fill-primary: var(--light-primary);
    --svg-fill-secondary: var(--jewel-primary);
    --background-gradient: #{$gradient-light };
}
.theme-light-secondary {
    --background-color: #{$default-background };
    background-color: #{$default-background };
    --svg-fill-primary: var(--light-secondary);
    --svg-fill-secondary: var(--jewel-secondary);
    --background-gradient: #{$gradient-light };
}
.theme-light-tertiary {
    --background-color: #{$default-background };
    background-color: #{$default-background };
    --svg-fill-primary: var(--light-tertiary);
    --svg-fill-secondary: var(--jewel-tertiary);
    --background-gradient:#{$gradient-light };
}