﻿/// <reference path="../entries/main">

.grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: $gap;

    @include media(small) {
        gap: $gap-sm;
    }

    &-col-1 {
        grid-template-columns: minmax(0, 1fr);
        place-items: center center;

        &-centered {
            place-items: center center;
            text-align: center;
            margin: 0 auto;
        }
    }

    &-eq-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &-eq-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &-eq-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &-6-3-3 {
        grid-template-columns: minmax(0, calc(50% - var(--gutter-h) / 3)) repeat(2, minmax(0, 1fr));
    }

    &-3-3-6 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) minmax(0, calc(50% - var(--gutter-h) / 3));
    }

    &-8-4 {
        grid-template-columns: minmax(0, calc(2 / 3 * 100% - var(--gutter-h) / 4)) minmax(0, 1fr);
    }

    &-4-8 {
        grid-template-columns: minmax(0, 1fr) minmax(0, calc(2 / 3 * 100% - var(--gutter-h) / 4));
    }

    @include media-range(small, medium) {
        &-eq-4 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &-6-3-3 {
            grid-template-columns: repeat(2, 1fr);

            & > :first-child {
                grid-column: span 2;
            }
        }

        &-3-3-6 {
            grid-template-columns: repeat(2, 1fr);

            & > :last-child {
                grid-column: span 2;
            }
        }
    }

    @include media(small) {
        &-eq-2, &-eq-3,
        &-eq-4, &-6-3-3,
        &-3-3-6, &-8-4, &-4-8 {
            grid-template-columns: minmax(0, 1fr);
        }
    }
}
.content-container {
    @extend %max-width;

    @include responsive-context($standard-padding-map-v) {
        padding-block: $responsive-variable;
    }

    .content-container {
        @include responsive-context($null-map) {
            padding-inline: $responsive-variable;
        }

        @include responsive-context($null-map) {
            padding-block: $responsive-variable;
        }

        max-width: 100%;
        margin-inline: initial;
    }
}