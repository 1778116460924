﻿/// <reference path="../entries/main">

%body {
    @include font-size($body-font-sizes);
    font-family: $body-font;
    font-weight: $font-weight-semibold;
    color: var(--foreground);

    ::selection {
        color: var(--foreground);
    }

    ::-moz-selection {
        color: $white;
    }
}

%h1, %h2, %h3, %h4, %h5 {
    font-family: $heading-font;
}

%h6, %large, %small, %body, %regular {
    font-family: $body-font;
}

%h1 {
    @include font-size($x-large-font-sizes);
    font-weight: $heading-weight-semilight;
}

%h2 {
    @include font-size($large-font-sizes);
    font-weight: $heading-weight-semilight;
}

%h3 {
    @include font-size($medium-large-font-sizes);
    font-weight: $heading-weight-semilight;
}


%h4 {
    @include font-size($medium-font-sizes);
    font-weight: $heading-weight-light;
}


%h5 {
    @include font-size($reg-font-sizes);
    font-weight: $heading-weight-light;
}

%h6 {
    @include font-size($small-font-sizes);
    font-weight: $font-weight-bold;
}

%blockquote {
    @include font-size($quote-font-sizes);
    font-family: $heading-font;
    font-weight: $heading-weight-light;
}
%large {
    @include font-size($large-reg-font-sizes);
}

%regular {
    @include font-size($body-font-sizes);
}

%small {
    @include font-size($x-small-font-sizes);
}

%subtitle, .subtitle {
    @include font-size($x-small-font-sizes);
    font-family: $body-font;
    font-weight: $heading-weight-bold;
    text-transform: uppercase;
}
%accent {
    @include font-size($label-font-sizes);
    font-family: $heading-font;
}

%nav-header {
    font-family: $body-font;
    @include font-size($body-font-sizes);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    color: $mega-nav-text;
}

%hyperlink {
    text-decoration: underline;
    color:currentColor;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

%linkreverse, .link-reverse {
    text-decoration: none;
    color: currentColor;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

%unlink, .unlink {
    color: inherit;
    text-decoration: none;
}

%navlink, .nav-link {
    @extend %unlink;
    @extend %regular;
    font-weight: 700;

    @include media-min(nav-large) {
        @include font-size($large-reg-font-sizes);
    }

    &:hover {
        text-decoration: underline;
    }

    i {
        text-decoration: none;
    }
}

