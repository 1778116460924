﻿/// <reference path="../entries/main">

.login-page {
    &-container {
        display: flex;
        flex-flow: row wrap;
        gap: 2rem;
        border-top: solid 1px var(--chip-outline-border);
        margin-top: 2rem;

        .btn-primary {
            font-family: $body-font;

            @include media(small) {
                width: 100%;
            }
        }
    }

    &-login {
        margin-block: 2rem;
        width: calc(50% - 1rem);

        @include media(small) {
            width: 100%;
            margin-block: 0;
        }

        p {
            margin-top: 1rem;
        }

        fieldset {
            display: flex;
            flex-flow: row wrap;
            gap: 1.5rem;
        }

        .form-element {
            width: calc(50% - .75rem);

            @include media(small) {
                width: 100%;
            }
        }

        .link {
            margin-top: 1.5rem;
            display: block;
        }

        form {
            margin-top: 1.5rem;
        }
    }

    &-create-acount {
        margin-block: 2rem;
        width: calc(50% - 1rem);

        @include media(small) {
            width: 100%;
            margin-bottom: 0;
        }

        p {
            margin-top: 1rem;
        }

        .btn-primary {
            margin-top: 2rem;
        }
    }

    &-heading p {
        margin-top: 1rem;
        max-width: 50rem;
    }



    &-error {
        background-color: var(--alert-bright-red);

        &-content {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: $space;
        }

        > .content-container {
            padding-block: $gap-md-lg;
        }

        &-close {
            background: none;
            border-style: none;
            border: none;
            cursor: pointer;
            width: $icon-font-size-active;
            height: $icon-font-size-active;
            text-align: center;
            display: flex;
            flex-flow: row;
            justify-content: center;
            text-align: center;
            align-items: center;
            @extend %un-button;
            i {
                width: 100%;

                &:hover, &:active, &:focus {
                    transition: font-size 0.2s ease;
                    font-size: $icon-font-size-active;
                }
            }
        }

        &-message {
            display: flex;
            flex-flow: row;
            gap: $gap-sm;
            max-width: 75%;

            @include media(small) {
                max-width: calc(100% - 1.5rem);
            }

            i {
                font-size: $gap-md-lg;
            }

            p {
                max-width: 65ch;
            }
        }
    }
}

