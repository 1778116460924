﻿/// <reference path="../entries/main">
@use "sass:math";

%visually-hidden, .visually-hidden {
    @include visually-hidden;
}

.visually-hidden-mobile {
    @include media(small) {
        @include visually-hidden;
    }
}
%hide {
    @include hide;
}

%text-top-margin {
    margin-top: 1em;
}

%medium-top-margin {
    margin-top: 0.5em;
}

%small-top-margin {
    margin-top: 0.325em;
}

%svg-bg-left {
    background-repeat: no-repeat;
    background-size: $svg-icon-md $svg-icon-md;
    background-position: left top 0.25em;
    padding-left: $svg-icon-md * 1.5;
    padding-top: $sliver;
}
%round {
    // Rounded border, for circles or pill-shapes. Not elliptical.
    border-radius: $border-radius-round;
}

%checkmark {
    // Used for the checkbox check, and the check indicator on button/pill appearance radio/checkboxes
    content: '';
    position: absolute;
    display: inline-block;
    border: $radiocheck-weight solid var(--foreground);
    border-width: 0 0 $radiocheck-weight $radiocheck-weight;
    transform: rotate(-45deg);
    display: block;
    top: 0.35em;
    left: 0.25em;
    width: math.div($radiocheck-size, 1.5);
    height: math.div($radiocheck-size, 3);
}
