﻿/// <reference path="../entries/main">

.interstitial-block {
    .content-container {
        width: 100%;
    }

    &-form, &-media {
        width: calc(50% - 1rem);

        @include media(medium) {
            width: 100%;
        }
    }

    &-media {
        position: relative;

        img {
            width: 100%;
        }
    }

    &-image {
        position: relative;
        z-index: 2;
    }

    &-illustration {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .illustration {
            width: 100%;
            height: 100%;
        }
    }

    &-close {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
    }

    &-inner {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        @include responsive-context($gutter-map-h) {
            gap: $responsive-variable;
            padding-block: $responsive-variable;
        }

        @include responsive-context($standard-padding-map) {
            padding-inline: $responsive-variable;
        }
    }

    .modal-close:not(.icon-link) {
        position: relative;
        @extend %btn-primary;
        padding: 10px 14px 8px;
    }

    &-ad {
        .interstitial-block-inner {
            padding: 1rem;
            width: auto;
            gap: $space;
            align-items: flex-start;
            max-width: 100%;
        }

        .interstitial-block-content {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: transparent;
        }

        .modal-dialog {
            width: max-content;
            max-width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: center;
        }

        .advertisement-block-advertisement {
            width: 100%;
        }

        .interstitial-block-close {
            max-height: 46px;
        }
    }
}