﻿/// <reference path="../entries/main">

.quote {
    &-block {
        &-content {
            display: flex;
            flex-flow: row wrap;

            @include responsive-context($gutter-map-v) {
                margin-top: $responsive-variable;
                gap:$responsive-variable;
            }
        }

        &-item {
            flex-grow: 1;

            @include media-min(small) {
                width: calc(50% - #{$gap-sm});
            }

            &-text {
                @extend %h4;
            }

            &-attribution {
                @include responsive-context($standard-padding-map-v) {
                    margin-top: $responsive-variable;
                }
                display:flex;
                flex-flow:row;
                gap:$gap;
                &-image {
                    max-width:10rem;

                    img {
                        width:100%;
                    }
                }
            }
        }
    }
}