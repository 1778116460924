﻿/// <reference path="../../entries/main">
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent !important;
    cursor: pointer
}

/* Removes default focus */
input[type="range"]:focus {
    outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
    background-color: var(--input-background);
    border-radius: 0.5rem;
    height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -.25rem; /* Centers thumb on the track */
    /*custom styles*/
    background-color: var(--input-color);
    height: 1rem;
    width: 1rem;
    border-radius: .5rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid var(--input-background);
    outline: 3px solid var(--input-background);
    outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
    background-color: var(--input-background);
    border-radius: 0.5rem;
    height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: .5rem; /*Removes default border-radius that FF applies*/
    /*custom styles*/
    background-color: var(--input-color);
    height: 1rem;
    width: 1rem;
}

input[type="range"]::focus:-moz-range-thumb {
    border: 1px solid var(--input-color);
    outline: 3px solid var(--input-color);
    outline-offset: 0.125rem;
}

.EPiServerForms .FormRange .FormRange__Wrapper {
    max-width: $max-input-width;
    width: 100% !important;

    .FormRange__Slider__Wrapper {
        align-items: center;
    }

    .FormRange__Slider__Button {
        @extend %btn-primary;
        background-color: var(--button-primary-background) !important;
        color: var(--button-primary-foreground) !important;

        &.FormRange__Slider__Button__Disable {
            background-color: var(--button-disabled-background) !important;
            color: var(--button-disabled-foreground) !important;
        }
    }
}

