﻿/// <reference path="../entries/main">
@use "sass:math";
.my-news {
    &-preferences {
        margin-top: $gap-md-lg;

        .section-options {
            display: grid;
            grid-template-columns: repeat(2, minmax(100px, 50%));
            column-gap: $gap;
            row-gap: .38rem;
            margin-bottom: $gap-md-lg;
            margin-top: $gap-xs;

            @include media-min(medium) {
                max-width: 47rem;
            }

            @include media(small) {
                grid-template-columns: repeat(1, minmax(100px, 100%));
            }
        }

        &-page {
            .alert-success, .alert-error {
                padding: $gap-md;
                margin-bottom: $gap-sm;
            }

            .alert-message {
                @extend %h6;
            }
        }

        &-ctas {
            display: flex;
            flex-flow: row wrap;
            gap: .38rem;
            grid-column: 1;

            @include media(medium) {
                width: 100%;
            }

            button, a, > div {
                @include media(medium) {
                    width: 100%;
                }
            }
        }

        &-count {
            margin-bottom: $gap-md-lg;
            font-weight: $font-weight-bold;
        }
    }

    &-header {
        display: flex;
        flex-flow: column wrap;
        gap: $gap-sm;
        margin-bottom: $gap;

        &-desc {
            max-width: 42.375rem;
        }
    }

    &-items {
        display: flex;
        flex-flow: row wrap;
        gap: $gap;
        align-items: flex-start;
    }

    &-category {
        &-card {
            background-color: var(--dark-secondary);
            width: 100%;
            padding: $gap-md-lg;

            @include media(small) {
                padding: $gap-sm;
            }

            @include media-min (tablet) {
                width: calc(50% - 1rem);
            }

            @include media-min (medium) {
                width: calc(33.3333% - 1.35rem);
            }



            &.user-defined {
                background-color: var(--secondary-background);
            }

            &-header {
                > a {
                    @extend %icon-link;
                }
            }
        }
    }

    &-item {
        border-top: solid 1px;
        border-color: var(--my-news-border);
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        display: flex;
        flex-flow: column;
        gap: $sliver;

        &-title {
            width: 100%;
            font-weight: $font-weight-bold;
            @extend %h6;
        }

        &-content > a {
            display: flex;
            flex-flow: row wrap;
            gap: .75rem;
            @extend %linkreverse;

            &:has(img) .my-news-item-title {
                @include media-min(small) {
                    max-width: calc(100% - 6rem);
                }
            }
        }

        &-image {
            max-width: 5rem;
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;

            @include media(small) {
                display: none;
            }
        }

        &-byline {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            @include font-size($x-small-font-sizes);
        }
    }

    &-no-articles {
        border-top: solid 1px;
        border-color: var(--my-news-border);
        margin-top: 1.5rem;
        padding-top: 1.5rem;
    }

    &-page {
        .like-count {
            color: var(--jewel-primary);
            @include font-size($x-small-font-sizes);
        }
    }
}