﻿/// <reference path="../entries/main">

.alert {
    &-banner {
        &-content {
            flex-grow: 1;

            &-buttons {
                display: flex;
                flex-flow: row wrap;
                gap: $gap-xs;

                &:has(.primary) {
                    margin-top: $sliver;
                }

                .primary, .secondary {
                    @include media(small) {
                        width: 100%;

                        > a {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &-block {
            .content-container {
                padding-block: 1rem;
                display: flex;
                flex-flow: row;
                gap: $gap-xs;
            }
        }

        &-icon i, &-close i {
            font-size: $icon-font-size;
        }

        &-close {
            width: $icon-font-size-active;
            height: $icon-font-size-active;
            text-align: center;
            display: flex;
            flex-flow: row;
            justify-content: center;
            text-align: center;
            align-items: center;

            i {
                width: 100%;

                &:hover, &:active, &:focus {
                    transition: font-size 0.2s ease;
                    font-size: $icon-font-size-active;
                }
            }
        }
    }



    &-success {
        background-color: var(--alert-green);
    }

    &-info {
        background-color: var(--alert-grey);
    }

    &-warning {
        background-color: var(--alert-yellow);
    }

    &-error {
        background-color: var(--alert-bright-red);
    }

    &-pwa {
        padding: $gap-sm $gap;
        display: flex;
        flex-flow: row;
        gap: $gap-xs;
    }
}