﻿/// <reference path="../../entries/main">
.footer {
    position: relative;
    z-index: 7;
    &-container {
        display: grid;
        grid-template-columns: repeat(4, minmax(100px, 1fr));
        grid-template-rows: repeat(3, auto);



        @include media(medium) {
            grid-template-columns: repeat(2, minmax(100px, 1fr));
            grid-template-rows: repeat(4, auto);
        }

        @include media(small) {
            grid-template-columns: repeat(1, minmax(100px, 1fr));
            grid-template-rows: repeat(6, auto);
        }

        @include responsive-context($gutter-map-v) {
            gap: $responsive-variable;
        }
    }

    &-logo {
        max-width: 11.5rem;
    }

    &-column {
        &-links {
            margin-top: $gap-sm;

            a {
                @extend %linkreverse;
            }

            ul {
                display: flex;
                flex-flow: column;
                gap: $gap-xs;
            }
        }

        &-content {
            display: flex;
            flex-flow: column;
            gap: $gap-xs;
            margin-top: $gap-sm;

            .btn-secondary {
                display: inline-block;
                max-width: fit-content;
                --background: var(--jewel-primary);
            }
        }

        &-address {
            display: grid;
            grid-template-columns: 20px auto;

            p {
                grid-column: 2;
            }
        }
    }

    &-socials {
        width: 100%;
        border-bottom: solid 1px var(--tab-border);
        padding-bottom: .5rem;
        display: flex;
        flex-flow: row wrap;
        gap: .5rem;
        align-items:center;
    }

    &-social-link {
        border-radius: 4px;
        text-decoration: none;
        display: inline-block;
        margin: 5px;

        i {
            display: block;
            padding: 2px;
            color: var(--dark-primary);
        }

        svg {
            display: block;
            width: 20px;
            height: 20px;
        }

        &.facebook svg, .linkedin svg {
            width: 26px;
            height: 26px;
        }
    }

    &-logo, &-bottom {
        grid-column: 1 / span 4;



        @include media(medium) {
            grid-column: 1 / span 2;
        }

        @include media(small) {
            grid-column: 1;
        }
    }

    .footer-bottom {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: .5rem;

        &-links ul {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;
        }

        &-copyright {
            @include media-min(small) {
                max-width: 50%;
            }
        }
    }
}