﻿/// <reference path="../entries/main">
$button-padding-menu: .875rem 2.5rem .75rem 1.125rem;
$button-padding: .875rem 1.125rem .75rem;
$button-padding-small: .625rem .875rem;
$button-padding-icon: .375rem .625rem;


%btn {
    @include font-size($button-font-sizes);
    display: inline-block;
    padding: $button-padding;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    border-style: solid;
    border: none;

    &[disabled], &.disabled {
        cursor: not-allowed;
    }

    .svg-icon {
        margin-left: $space;
    }
}

%btn-primary {
    @extend %btn;
    font-weight: $font-weight-bold;
    @include button-helper();
    position: relative;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: all 0.15s ease;
    }

    &:hover, &:active {
        &:after {
            top: -.12rem;
            left: -.25rem;
            right: -.25rem;
            bottom: -.12rem;
            width: calc(100% + .5rem);
            height: calc(100% + .24rem);
        }
    }

    &[disabled], &.disabled {
        &:after {
            display: none;
        }
    }

    &-highlight {
        @include button-helper($highlight-button-config);
    }

    .ph-arrow-right {
        top: 2px;
        position: relative;
    }
}
%btn-outline {
    @extend %btn;
    border-width: 1px;
    border-style: solid;
    border-color: var(--foreground);
    background-color: var(--background-color);
    color: var(--foreground);

    &:hover, &:active, &:focus {
        background-color: var(--background-color);
        border-color: var(--button-primary-background);
        color: var(--foreground);
    }

    &[disabled], &.disabled {
        border-color: var(--button-disabled-background);
        color: var(--button-disabled-foreground);
        box-shadow: none;

        svg {
            fill: var(--button-disabled-foreground);
        }

        &:hover {
            cursor: not-allowed;
        }
    }
}

%btn-small {
    @include font-size($small-button-font-sizes);
    padding: $button-padding-small;
}

%btn-link {
    @extend %btn;
    background-color:transparent;
    @extend %hyperlink;
}

%btn-light {
    @extend %btn;
    @include button-helper($default-button-light-config);
}

%btn-remove {
    @extend %btn;

    @include media(small) {
        @include round-focus;
        border-radius: 9999em;
        width: var(--square-button-size);
        height: var(--square-button-size);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            @include visually-hidden;
        }

        .svg-icon {
            margin: 0;
        }
    }
}

%un-button-un-style {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    text-align: left;

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &[disabled],
    &[aria-disabled="true"] {
        background-color: transparent;
        color: var(--square-button-size);
        cursor: not-allowed;
    }
}

%un-button, %un-btn {
    @extend %body;
    @extend %un-button-un-style;
}

%icon-style-base {
    background-color: var(--background);
    border: none;
    color: var(--foreground);
    border-style: none;
    font-size: 1.375rem;
    vertical-align: middle;
    fill: currentColor;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    transition: all .1s ease;
    position: absolute;
    right: .25rem;
    bottom: 0;
    font-weight: $font-weight-normal;

    &:before {
        display: inline-block;
        text-decoration: none;
    }
}
%icon-style-hover {
    right: .125rem;
    bottom: 0;
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
}

%icon-link {
    --background: var(--button-secondary-background);
    --foreground: var(--button-secondary-icon);
    gap: .5rem;
    @include font-size($button-font-sizes);
    font-weight: $font-weight-bold;
    border: none;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 4px;
    text-decoration-color: var(--background);
    position: relative;
    padding-right: 2.5rem;
    display: inline-block;
    margin-top: 0.5rem;

    i {
        @extend %icon-style-base;
    }
    //phosphor icon: ph-arrow-up-right
    &:not(:has(i)) {
        &:after {
            @extend %icon-style-base;
            font-family: "Phosphor" !important;
            content: "\e092";
        }

        &:hover, &:focus {
            &:after {
                @extend %icon-style-hover;
            }
        }
    }

    &:hover, &:focus {
        text-decoration: underline;
        text-decoration-thickness: 1.5px;
        text-underline-offset: 4px;
        text-decoration-color: var(--background);

        i {
            @extend %icon-style-hover;
        }
    }

    &[disabled],
    &[aria-disabled="true"] {
        background-color: transparent;
        cursor: not-allowed;
    }
}

%btn-icon {
    @extend %icon-link;
}