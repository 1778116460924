@use "sass:math";

// Spacing variables
$gap-xl:                    7.5rem;
$gap-lg:                    5rem;
$gap:                       2rem;
$gap-md-lg:                 1.5rem;
$gap-md:                    1.375rem;
$gap-sm:                    1rem;
$gap-xs:                    0.75rem;
$space:                     0.5rem;
$sliver:                    0.25rem;

// Layout sizing and sizing for ui elements included in layouts
$max-page-margin:           7.5rem;
$max-line-length:           50rem;
$max-gutter-width:          2rem;
$max-gutter-height:         2rem;
$max-width:                 122.5rem;

$svg-inline-spacing: 0.5em;


// Miscellaneous
$transition:                0.2s;
$one-third:                 math.div(99.9%, 3);

//inputs
$radiocheck-size: 1.5rem;
$radiocheck-padding: $space;
$radiocheck-weight: 3px;
$radiocheck-offset: -0.05em;
$radiocheck-weight: 2px;
$border-radius-round: 9999em;