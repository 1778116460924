﻿/// <reference path="../entries/main">
.hero-banner {
    &-block {
        position: relative;

        &[data-vimeo-hero] {

            .content-container {
                min-height: 30rem;

                @include media-min(tablet) {
                    min-height: 38rem;
                }

                @include media-min(x-large) {
                    min-height: 100vh;
                }
            }

            .media-heading-text-links {
                align-items: center;

                .secondary, .primary {
                    width: auto;

                    @include media(mobile) {
                        width: 100%;
                    }

                    a {
                        @extend %btn-primary;
                        --button-primary-background: var(--white);
                        --button-primary-foreground: var(--black);
                        width: auto;

                        @include media(mobile) {
                            width: 100%;
                        }
                    }
                }
            }

            .hero-banner-align-vertical-bottom {
                @include media(small) {
                    order: 2;

                    + .hero-banner-vimeo-controls {
                        align-self: flex-start;
                    }
                }
            }

            .hero-banner {
                &-left {
                    &-content {
                        .hero-banner-left {
                            @include media-min(small) {
                                max-width: 75%;
                            }

                            @include media-min(large) {
                                max-width: 50%;
                            }
                        }
                    }
                }
            }

            &.hero-banner-align-right {
                .hero-banner-left {
                    @include media-min(small) {
                        order: 2;
                    }
                }
            }
        }
        //end vimeo specific styles


        &[data-image-hero] {
            overflow: hidden;

            .content-container {
                min-height: 30rem;

                @include media-min(tablet) {
                    min-height: 36rem;
                }
            }

            .media-heading-text-links {
                align-items: center;

                .primary, .secondary {
                    width: auto;

                    @include media(mobile) {
                        width: 100%;
                    }

                    a {
                        @extend %btn-primary;
                        width: auto;

                        @include media(mobile) {
                            width: 100%;
                        }
                    }
                }

                .secondary {
                    a {
                        @extend %btn-light;
                    }
                }
            }

            .svg-background {
                &.svg-align-left svg {
                    @include media-min(medium) {
                        left: -15rem;
                    }

                    @include media-min(xlarge) {
                        left: -35rem;
                    }
                }

                &.svg-align-right svg {

                    @include media-min(large) {
                        left: 50%;
                    }
                }
            }

            &.hero-banner-no-column {
                .content-container {
                    min-height: auto;
                }

                &:has(.hero-banner-image-background) {
                    .content-container {
                        min-height: 30rem;

                        @include media-min(medium) {
                            min-height: 35rem;
                        }

                        @include media-min(x-large) {
                            min-height: 38rem;
                        }
                    }
                }

                &.hero-banner-align-right {
                    .hero-banner-left-content {
                        @include media-min(medium) {
                            justify-content: flex-end;
                        }
                    }

                    .hero-banner-left {
                        min-width: calc(50% - #{$gap-sm});
                    }
                }

                &.hero-banner-align-center {
                    .hero-banner-left-content {
                        @include media-min(medium) {
                            justify-content: center;
                        }
                    }
                }

                &.hero-banner-align-left {
                    .hero-banner-left-content {
                        @include media-min(medium) {
                            justify-content: flex-start;
                        }
                    }
                }

                .hero-banner-left-content {

                    .hero-banner-left {
                        max-width: 100%;

                        @include media-min(medium) {
                            max-width: 60%;
                        }
                        @include media(mobile) {
                            width:100%;
                        }
                    }
                }
            }
        }

        .media-heading-text-content {
            flex-grow: 0;
        }

        &:has(.hero-banner-video-background), &:has(.hero-banner-image-background) {
            .chip-subtitle {
                background: none;
                @extend %subtitle;
                padding: 0;
            }
        }

        &:not(:has(.hero-banner-image-background)) {
            .content-container {
                @include media(medium) {
                    min-height: auto;
                }
            }
        }

        &:not(:has(.hero-banner-image-background)):has(.svg-background):not(:has(.media-heading-image)) {
            .content-container {
                min-height: auto;
            }
        }
        &.no-min-height {
            .content-container {
                min-height: auto!important;
            }
        }
    }

    &-left, &-right {
        z-index: 3;
        position: relative;

        &-image {
            @include media-min(medium) {
                width: 50%;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
            }

            .media-heading-image img {
                @include media-min(medium) {
                    object-fit: cover;
                    object-position: 50% 50%;
                    height: 100%;
                    width: 100%;
                    object-position: var(--focal-point-h) var(--focal-point-v);
                }
            }
        }
    }

    &-left {
        &-image {
            height: 100%;

            @include media-min(medium) {
                right: auto;
                left: 0;
            }

            .hero-banner-left {
                height: 100%;

                .media-heading-image {
                    height: 100%;
                }
            }
        }

        &-content {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            height: 100%;
            gap: $gap-md;

            .hero-banner-left {

                @include media-min(medium) {
                    max-width: calc(50% - #{$gap-sm});
                }
            }
        }
    }

    &-right {
        &-content {
            @include media-min(medium) {
                display: grid;
                grid-template-columns: 50% 50%;

                .hero-banner-right {
                    @include responsive-context($gutter-map-v) {
                        margin-left: $responsive-variable;
                    }

                    grid-column-start: 2;
                }
            }
        }

        &-image {
            height: 100%;

            .hero-banner-right {
                height: 100%;

                .media-heading-image {
                    height: 100%;
                }
            }
        }
    }

    &-align-vertical {
        &-middle {
            @include media(medium) {
                align-self: flex-end;
            }

            .media-heading-text {
                justify-content: center;
            }
        }

        &-top {
            .media-heading-text {
                justify-content: flex-start;
            }
        }

        &-bottom {
            .media-heading-text {
                justify-content: flex-end;
            }
        }
    }

    &-gradient {
        @extend %gradient;
    }

    &-video-background {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;

        > div {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        iframe {
            height: 56.25vw;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            width: 177.77777778vh;
        }
    }

    &-image-background {
        @extend %image-background;
    }

    &-vimeo-controls {
        z-index: 3;
        position: relative;
        align-self: flex-end;
        display: flex;
        flex-flow: row wrap;
        gap: $gap-sm;

        @include media(small) {
            width: 100%;
            justify-content: flex-end;
        }

        button {
            @extend %un-btn;
            background-color: var(--white);
            width: 3.25rem;
            height: 3.25rem;
            text-align: center;

            &:hover, &:focus {
                background-color: var(--black);

                i {
                    color: var(--white);
                }
            }

            @include media(tablet) {
                width: 2.75rem;
                height: 2.75rem;
            }

            i {
                line-height: 3.25rem;
                font-size: $icon-font-size;
                color: var(--black);

                @include media(tablet) {
                    line-height: 2.75rem;
                    font-size: 1rem;
                }
            }
        }

        .muted {
            .ph-speaker-x {
                display: none;
            }

            .ph-speaker-high {
                display: block;
            }
        }

        .unmuted {
            .ph-speaker-x {
                display: block;
            }

            .ph-speaker-high {
                display: none;
            }
        }

        .paused {
            .ph-play {
                display: block;
            }

            .ph-pause {
                display: none;
            }
        }

        .playing {
            .ph-play {
                display: none;
            }

            .ph-pause {
                display: block;
            }
        }
    }
}
