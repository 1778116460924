﻿/// <reference path="../../entries/main">
@media print {
    /* All your print styles go here */
    div#lhnHocButton {
        display: none!important;
    }
    .atlwdg-trigger {
        display: none!important;
    }
    .article-detail-social, .article-detail-recommendations, .advertisement-block {
        display: none !important;
    }
    body {
        h1 {
            @include font-size($medium-font-sizes);
        }

        h2 {
            @include font-size($medium-font-sizes);
        }

        h3 {
            @include font-size($medium-font-sizes);
        }
    }
}