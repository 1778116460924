﻿/// <reference path="../entries/main">
%chip-subtitle {
    background: var(--chip-background);
    padding: $sliver $gap-xs;
    border-radius: 0px 10px 0px 0px;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    font-weight:$font-weight-extbold;

    &:hover {
        background: var(--chip-background);
    }
}

%chip-outline {
    padding: $sliver $gap-xs;
    border: solid 1px var(--chip-outline-border);
    background: var(--chip-outline-background);
}

%chip-themed {
    padding: $space $gap-xs;
    background: var(--button-primary-background);
    color: var(--button-primary-foreground);
    display: flex;
    flex-flow: row;
    gap: $space;

    svg {
        fill: currentColor;
    }
}

%chip-themed-light {
    background: $light-copy;
    color: $black;
    display: flex;
    flex-flow: row;
    gap: $space;

    svg {
        fill: currentColor;
    }
}