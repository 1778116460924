﻿/// <reference path="../../entries/main">

.header {

    &-mega-nav {
        @extend %max-width;

        &-wrapper {
            background-color: var(--dark-primary);
            color: $white;
            position: relative;
            border-bottom: solid 1px var(--jewel-primary);

            @include media(nav-tablet) {
                display: none;
            }

            &[aria-hidden="false"] {

                @include media(nav-tablet) {
                    display: block;
                    //override online chat zindex when open
                    z-index: 999992;
                }
            }

            @include media(nav-tablet) {
                background: var(--dark-primary);
                color: $white;
                padding-block: 1.375rem;
            }
        }

        &-links {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            gap: $gap-sm;

            @include media-min(nav-large) {
                gap: $gap-md;
            }

            @include media(nav-tablet) {
                display: block;
            }
        }



        &-block {
            &-title {
                display: flex;
                flex-flow: row wrap;
                @include responsive-property('column-gap', $gutter-map-v);
                margin-bottom: 0;
                @include responsive-property('padding-right', $gutter-map-v);
                flex-flow: column;
                color: white;

                > * {
                    overflow-wrap: break-word;
                }

                > a {
                    @extend %linkreverse;
                }

                @include media-min(nav-tablet) {
                    background: var(--classic-primary);
                    width: 25%;
                    padding-block: 1.75rem;
                }

                @include media(nav-tablet) {
                    h3 {
                        display: none;
                    }

                    width: 100%;
                }
            }

            &-description {
                @include media(nav-tablet) {
                    display: none;
                }
            }

            &-link {
                &-list {
                    display: grid;
                    grid-template-columns: repeat(4, minmax(100px, 1fr));
                    @include responsive-property('column-gap', $gutter-map-h);

                    @include media(nav-tablet) {
                        display: block;
                    }
                }

                &-item {
                    a {
                        @extend %navlink;
                        @include font-size($small-font-sizes);
                        padding-block: .625rem;
                        display: block;

                        @include media(nav-tablet) {
                            @include font-size($body-font-sizes);
                            padding-block: .375rem;
                            font-weight: 500;
                        }
                    }
                }
            }

            &-links {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                @include responsive-property('gap', $gutter-map-h);

                @include media-min(nav-tablet) {
                    margin-left: $gap;
                    padding-block: 1.75rem;
                    width: 75%;
                }

                @include media(nav-tablet) {
                    grid-template-columns: 1fr 1fr;
                    width: 100%;
                }

                @include media(small) {
                    grid-template-columns: 1fr;
                }
            }

            &-top-link {
                @extend %navlink;
                padding-block: .625rem;
                display: flex;
                background: none;
                border: none;
                color: $white;
                gap: $space;
                cursor: pointer;


                @include media(nav-tablet) {
                    font-weight: 700;
                    width: 100%;
                    color: $white;
                    justify-content: space-between;
                    padding-inline: 0;

                    i {
                        background: $white;
                        color: $black;
                        width: 17px;
                        height: 17px;
                        display: flex;
                        justify-content: center;
                    }
                }

                + .header-mega-nav-block-top-link {
                    @include media(nav-tablet) {
                        padding-left: 1.5rem;
                    }
                }

                &:hover {
                    text-decoration: none;
                }

                i {
                    transition: all .1s ease;

                    &:before {
                        vertical-align: middle;
                    }
                }
            }

            &-content {
                background-color: var(--dark-primary);
                position: absolute;
                z-index: 999;
                right: 0;
                width: 100%;
                color: black;
                top: auto;
                height: auto;
                overflow: hidden;
                transition: transform .3s ease;
                transform: scaleY(0);
                transform-origin: top;

                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    width: 100%;
                    left: 0;
                    height: 1px;
                    background-color: var(--jewel-primary);
                    z-index: 2;
                }

                .header-mega-nav-block-content-wrapper {
                    transform: scaleY(2);
                    transition: transform .3s ease;
                }

                @include media(nav-tablet) {
                    position: relative;
                    transform: scaleY(0);
                    padding-inline: 0;
                    height: 0;
                    transition: all .3s ease;

                    &:before {
                        display: none;
                    }

                    &[aria-expanded="true"] {
                        transform: scaleY(1);

                        .header-mega-nav-block-content-wrapper {
                            transform: scaleY(1);
                            opacity: 1;
                        }
                    }

                    &[aria-expanded="false"] {
                        .header-mega-nav-block-content-wrapper {
                            opacity: 0;
                        }
                    }
                }

                &-wrapper {
                    @extend %max-width;

                    @include media-min(nav-tablet) {
                        display: flex;
                        flex-flow: row;
                        @include responsive-property('gap', $grid-spacing-map);

                        &:after {
                            content: "";
                            position: absolute;
                            background: var(--classic-primary);
                            width: 100%;
                            height: 100%;
                            right: 75%;
                            z-index: 1;
                        }
                    }

                    @include media(nav-tablet) {
                        padding-inline: 0;
                    }
                }

                &-item {
                    display: flex;
                    flex-flow: row;
                    z-index: 2;
                    width: 100%;

                    @include media(nav-tablet) {
                        flex-flow: column;
                        width: 100%;
                    }
                }
            }

            &-cta {
                .btn-primary {
                    background-color: var(--jewel-primary);
                    color: $black;

                    @include media-min(nav-tablet) {
                        margin-top: $gap-xs;
                    }

                    @include media(nav-tablet) {
                        margin-bottom: $gap-xs;
                    }
                }

                .ph-arrow-right {
                    @include media(nav-tablet) {
                        display: none;
                    }
                }
            }
        }

        &-link {
            &-item {
                @include media(nav-tablet) {
                    border-bottom: solid 1px $light-background;
                }

                .header-mega-nav-block-top-link i {
                    transition: transform .3s;
                }

                &:hover {
                    @media (pointer: fine) {
                        .header-mega-nav-block-top-link i {
                            transform: rotate(180deg);
                        }

                        .header-mega-nav-block-content {
                            @include media-min(medium) {
                                transform: scaleY(1);

                                .header-mega-nav-block-content-wrapper {
                                    transform: scaleY(1);
                                }
                            }
                        }
                    }
                }

                &:has(.tour-wrapper:hover) {
                    @media (pointer: fine) {
                        .header-mega-nav-block-top-link i {
                            transform: rotate(0);
                        }

                        .header-mega-nav-block-content {
                            @include media-min(nav-tablet) {
                                transform: scaleY(0);

                                .header-mega-nav-block-content-wrapper {
                                    transform: scaleY(0);
                                }
                            }
                        }
                    }
                }

                &:has(.trigger-enter-active) {
                    .header-mega-nav-block-top-link i {
                        transform: rotate(180deg);
                    }

                    .header-mega-nav-block-content {
                        @include media(nav-tablet) {
                            height: auto;
                            padding-top: $gap-xs;
                            padding-bottom: $gap-xs;
                        }

                        @include media-min(nav-tablet) {
                            transform: scaleY(1);

                            .header-mega-nav-block-content-wrapper {
                                transform: scaleY(1);
                            }
                        }
                    }
                }
            }

            &-set {
                &-block {
                    color: white;

                    + * {
                        margin-top: $gap-sm;
                    }
                }

                &-list {
                    display: flex;
                    flex-flow: column;
                    gap: .375rem;
                }

                &-title {
                    margin-bottom: .375rem;
                    display: block;
                    @extend %nav-header;
                }

                &-item {
                    a {
                        @extend %linkreverse;
                    }
                }
            }
        }
    }
}

.mega-nav {
    &-highlight-block {
        + * {
            margin-top: $gap-sm;
        }

        &-header {
            @extend %nav-header;
            margin-bottom: .375rem;
            display: block;
        }

        &-cta {
            @extend %regular;
            color: white;
            z-index: 3;
            text-align: left;
        }


        &-description {
            color: white;

            @include media(nav-tablet) {
                display: none;
            }
        }

        &-image {
            margin-bottom: $sliver;
        }

        &-content {
            gap: $sliver;
            position: relative;
            display: flex;
            flex-flow: column;
            justify-content: center;

            @include media(nav-tablet) {
                max-width: 19.5rem;
            }
        }

        &-image {

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        &-cta {
            @include media(small) {
                width: 100%;

                .btn-primary {
                    width: 100%;
                }
            }

            .icon-link {
                i {
                    background-color: var(--jewel-primary);
                    color: $white;
                }

                @include media(small) {
                    justify-content: space-between;
                }
            }
        }

        &-flex {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            z-index: 3;
            position: relative;

            @include media(nav-tablet) {
                width: 100%;
                flex-flow: row wrap;
                gap: 1rem;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}




