﻿/// <reference path="../entries/main">
.ad-carousel {
    &-block {
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column;
        gap: $gap-sm;
        flex-grow: 1;
        margin-block:$gap-sm;
    }

    &-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        gap: $gap;
        padding-block:$gap-sm;
        .advertisement-block-advertisement {
            display: flex;
            flex-flow: column;
            justify-content: center;
            gap: $sliver;

            p {
                width: auto;
            }
        }
    }

    &-container-block {
        .content-container {

            @include responsive-context($null-map) {
                padding-bottom: $responsive-variable;
            }

            @include responsive-context($small-gutter-map-v) {
                margin-bottom: $responsive-variable;
            }
        }

        .swiper-navigation {
            &-container {
                @extend %max-width;

                @include responsive-context($small-gutter-map-v) {
                    padding-top: $responsive-variable;
                }

                @include responsive-context($standard-padding-map-v) {
                    padding-bottom: $responsive-variable;
                }
            }
        }
    }
}
.swiper-navigation {

    &-container {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include media(small) {
            gap: $gap-sm;
        }
    }
}

.swiper {
    .swiper-pagination {
        flex-grow: 1;
        display: block;
        position: relative;
        width: auto;
        bottom: auto;
        top: auto;
        left: auto;
        --swiper-pagination-bullet-inactive-color:var(--button-disabled-background);
        &-bullet {
            width: $gap-xs;
            height: 3px;
            border-radius: 0;
        }
    }
}
