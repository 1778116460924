﻿/// <reference path="../entries/main">
.tabs {

    &-container {

        > .content-container {
            @include responsive-context($null-map) {
                padding-bottom: $responsive-variable;
            }

            @include responsive-context($standard-padding-map-v) {
                padding-top: $responsive-variable;
            }

            @include media(small) {
                padding-top: 0;
            }
        }
    }

    &-list {
        display: flex;
        justify-content: center;
        gap: $space;

        @include media(small) {
            flex-wrap: wrap;
            padding-block: $gap-md;
        }

        &-item {
            flex: 0 1 auto;
            margin-bottom: -1px;
            overflow: hidden;
            margin-right: $gap-sm;

            &:last-child {
                margin-right: 0;
            }
        }

        &-tab {
            @extend %un-button;
            padding: $gap-xs $gap-sm;
            margin: ($focus-weight * 1.5) ($focus-weight * 1.5) 0;
            position: relative;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            border-style: solid;
            border-top-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-color: transparent;
            text-align: center;
            font-weight: 700;
            @include media(small) {
                border: 1px solid var(--tab-border);
                width: 100%;
            }

            &:hover,
            &:focus,
            &[aria-selected="true"] {
                background-color: var(--tab-background-active);
                border-color: var(--tab-border);



                @include media(small) {
                    border-bottom: 1px solid var(--tab-border);
                }
            }
        }
    }

    &-panel {
        background-color: var(--tab-background);

        &:has(.rich-text table) {
            background-color: $white;
        }

        @include media-min(small) {
            border-top: 1px solid var(--tab-border);
            margin-top: -.8px;
        }

        .rich-text {
            table {
                background: $white;
            }
        }
    }
}
.tab{
    &-set-tab {
    @include media(small) {
        width: 100%;
    }
}}