﻿/// <reference path="../entries/main">
.registration-cost {
    &-table-header, &-table-item {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        gap: $gap;
        align-items: center;
    }

    &-table {
        &-column {
            padding-block: $gap-sm;

            @include media(medium) {
                padding-block: $space;
            }

            &-mobile {
                @include media-min(medium) {
                    display: none;
                }

                display: flex;
                flex-flow: column;
                gap: $sliver;
                margin-bottom: $sliver;
            }
        }

        &-header {
            background-color: var(--registration-header);
            color: $light-copy;

            @include media(medium) {
                display: none;
            }
        }

        &-item {
            background-color: var(--registration-body);
            color: var(--foreground);
            margin-top: $space;
            padding-inline: $gap-md-lg;

            @include media(medium) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: auto auto auto;
                padding-inline: $gap-sm;
                gap: $space;
                align-items: flex-start;
            }

            @include media(small) {
                display: flex;
                flex-flow: column;
            }

            &-header {
                @include media(medium) {
                    width: 100%;
                    grid-column: span 3;
                }

                h3 {
                    display: flex;
                    flex-flow: row;
                    gap: $space;
                    color: var(--foreground);

                    @include media(small) {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                    }
                }
            }
        }

        &-trigger {
            background: none !important;
            border: none !important;
            padding-inline: 0;
            &:hover {
                cursor: pointer;
            }
            @include media(small) {
                width: 100%;
            }

            &[aria-expanded="true"] {
                .ph-plus {
                    display: none;
                }

                .ph-minus {
                    display: block;
                }

                .ph-caret-down {
                    display: none;
                }

                .ph-caret-up {
                    display: block;
                }
            }

            .ph-minus {
                display: none;
            }

            .ph-caret-up {
                display: none;
            }
        }

        &-accordion {
            background: none;
            padding-inline: 0;
        }
    }

    &-footer {
        margin-top: $gap-sm;
    }

    &-heading {
        + .registration-cost-table {
            margin-top: $gap;
        }
    }
}
