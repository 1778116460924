﻿// ------------- FONTS ------------- //


$body-font: 'AcherusGrotesque', Arial, sans-serif;
$heading-font: 'Jubilat', Arial, sans-serif;
$heading-font-italic: 'Jubilat-Itlaic', Arial, sans-serif;

// ------------- FONT WEIGHTS ------------- //
$font-weight-thin: 100;
$font-weight-light: 200;
$font-weight-semilight:300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-extbold: 800;
$font-weight-black: 900;

$heading-weight-light: 355;
$heading-weight-semilight: 445;
$heading-weight-normal: 500;
$heading-weight-semibold: 700;
$heading-weight-bold: 800;

// ------------- LINE HEIGHTS ------------- //
$heading-line-height: 1;
$body-line-height: 1.125;

// Miscellaneous
$form-field-font-size: 1rem;
$icon-font-size: 1.5rem;
$icon-font-size-active:1.875rem;
$form-field-line-height: $body-line-height;
$paragraph-spacing: 1em;