﻿/// <reference path="../../entries/main">
@use "sass:math";
.header-toggle-search, .header-toggle-button, .header-toggle-button-tablet, .header-search-button {
    background-color: var(--dark-primary);
    border: none;
    color: $white;
    border-style: none;
    padding: $button-padding-menu;
    margin-top: 0;
    transition: all 0.1s ease;

    &:hover, &:active {

        i {
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
            bottom: calc(50% - .75rem);
            right: calc(50% - .75rem);
            --background: var(--dark-primary);
        }
    }
}
.header-toggle-search, .header-toggle-button {
    @include media(small) {
        padding-inline: 1.375rem;
        padding-block: 1.25rem;
        height: fit-content;
        i{
            --background: var(--dark-primary);
        }
    }
}

.header-toggle-button i, .header-toggle-button-tablet i, .header-toggle-search i, .header-search-button i {
    font-size: $icon-font-size;
    line-height: $icon-font-size;
    fill: currentColor;
    bottom: calc(50% - .75rem);
    right: calc(50% - .75rem);
}
.header-toggle-search:focus i {
    width: 1.5rem;
    height: 1.5rem;
    bottom: calc(50% - .75rem);
    right: calc(50% - .75rem);
}

.header-toggle-button-tablet {
    &:hover, &:active {
        i {
            right: $gap-xs;
        }
    }

    i {
        right: $gap-xs;
        --background: var(--dark-primary);
    }
}

.header-toggle-search span {
    line-height: 1.375rem;
    vertical-align: middle;
    font-size: $gap-sm;
    display: none;
}

.header {
    &-main {
        @extend %max-width;
        padding-block: $gap-sm;
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr);
        grid-template-rows: auto auto;
        gap: $space;

        @include media (small) {
            grid-template-columns: auto auto;
            z-index: 2;
            position: relative;
            background: $white;
        }

        &.logo-large {
            grid-template-columns: repeat(2, minmax(100px, 1fr));
        }
    }

    &-sister-nav {
        background: $secondary-background;
        position: relative;
        z-index: 2;

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-toggle {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
        justify-self: flex-end;
        display: flex;
        flex-flow: row;
        gap: .375rem;

        @include media-min(small) {
            display: none;
        }

        &-button {
            &-tablet {
                @include media(small) {
                    display: none;
                }

                @include media-min(nav-tablet) {
                    display: none;
                }
            }
        }
    }

    &-search {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
        justify-self: flex-end;
        gap: .375rem;
        display: flex;
        flex-flow: row;
        width: 100%;

        @include media(nav-tablet) {
            grid-column: 1 / span 2;
            width: 100%;
        }

        @include media(small) {
            display: none;

            &[aria-hidden="false"] {
                display: block;
            }
        }

        &-wrapper {
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            flex-grow: 1;
            position: relative;
        }

        &.logo-large {
            input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
                max-height: 4.375rem;
                @include font-size($small-font-sizes);

                @include media(medium) {
                    width: 100%;
                    min-width: auto;
                }

                @include media-min(large) {
                    width: 100%;
                    max-width: 55.875rem;
                }
            }

            .search-autocomplete-results {
                @include media-min(large) {
                    width: 100%;
                    max-width: 55.875rem;
                }
            }
        }

        input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
            border: none;
            background-color: $search-input;
            @extend %large;
            padding: .5375rem 1.6875rem;
            min-width: 20.125rem;
            max-width: 47.125rem;

            @include media(medium) {
                width: 100%;
                min-width: auto;
            }
        }

        .search-autocomplete-results {
            width: 100%;
            max-width: 47.125rem;
        }

        &-button {
            background-color: var(--jewel-primary);
            padding: .9375rem 1.3125rem;
            color: $black;
            max-height: 4.375rem;

            &:after {
                display: none;
            }

            .search-button-text {
                @extend %visually-hidden;
            }

            &:hover {
                i {
                    color: $white;

                    &:before {
                        color: $white;
                    }
                }
            }

            &:focus {
                i {
                    bottom: calc(50% - 0.75rem);
                    right: calc(50% - 0.75rem);
                    width: 1.5rem;
                    height: 1.5rem;
                    line-height: 1.5rem;
                    --background: var(--dark-primary);
                    color: $white;

                    &:before {
                        color: $white;
                    }
                }
            }

            i {
                color: $black;

                &:before {
                    color: $black;
                }
            }
        }
    }

    &-utility-nav {

        &-desktop {
            grid-row-start: 1;
            grid-column-start: 2;
            justify-self: flex-end;
            margin-bottom: .5rem;

            @include media(small) {
                display: none;
            }

            .utility-nav-links {
                justify-content: flex-end;
            }
        }

        &-mobile {
            display: none;
            flex-flow: row wrap;
            row-gap: $gap-xs;

            @include media(small) {
                display: flex;
                margin-inline: $gap-sm;
                margin-bottom: 1.375rem;
            }
        }
    }

    &-logo {
        grid-column-start: 1;
        grid-row: 1 / span 2;
        align-self: center;




        @include media(nav-tablet) {
            grid-row: 1 / span 1;
        }

        a {
            display: inline-block;
        }

        &.logo-small img {
            min-height: 54px;
            height: 54px;
            max-width: 203px;

            @include media(mobile) {
                max-width: 170px;
                min-height: auto;
                height: auto;
            }
        }

        &.logo-large img {
            min-height: 54px;
            max-width: 203px;

            @include media(mobile) {
                max-width: 170px;
                min-height: auto;
                height: auto;
            }


            @include media-min(large) {
                min-height: 181px;
                width: 554px;
                max-width: 100%;
            }
        }
    }
}

.sister-nav {
    &-links {
        display: flex;
        flex-flow: row wrap;
        @extend %large;
        @extend %max-width;
        gap: $gap-sm;

        @include media(small) {
            column-gap: $space;
        }
    }

    &-link {
        padding-inline: $gap-xs;
        padding-block: $space;
        border-top: solid 4px;
        border-color: transparent;
        display: block;
        @extend %navlink;

        &-item.active a, &-item:hover a {
            background: $white;
            border-color: var(--jewel-primary);
        }

        @include media(medium) {
            @include font-size($label-font-sizes);
        }

        @include media(mobile) {
            padding-inline: 0;
        }
    }
}




//menu hamburger animation
.hamburger--spin {
    @include media-min(small) {
        padding-right: 1.75rem;
    }

    @include media(small) {
        padding: .55rem .5rem;
    }

    .hamburger-inner {
        transition-duration: 0.22s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
            transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
        }

        &::after {
            transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &[aria-expanded="true"] {
        .hamburger-inner {
            transform: rotate(225deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    &:after {
        display: none;
        content: none;
    }
}

    $hamburger-layer-width: 1.75rem !default;
    $hamburger-layer-height: 2px !default;
    $hamburger-layer-spacing: 6px !default;
    $hamburger-layer-color: #000 !default;
    $hamburger-color: $white;
    $hamburger-hover-use-filter: false !default;
    $hamburger-hover-filter: opacity(50%) !default;
    $hamburger-active-hover-filter: $hamburger-hover-filter !default;

    .hamburger--spin {

        &[aria-expanded="true"] {
            &:hover {
                @if $hamburger-hover-use-filter == true {
                    filter: $hamburger-active-hover-filter;
                }
            }

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $hamburger-color;
            }
        }
    }

    .hamburger-box {
        width: $hamburger-layer-width;
        height: 1rem;
        display: inline-block;
        position: relative;

        @include media-min(small) {
            padding-left: .5rem;
        }
    }

    .hamburger-inner {
        display: block;
        top: calc(50% + 3px);
        margin-top: math.div($hamburger-layer-height, -2);

        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-color;
            position: absolute;
            border-radius: 2px;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
    }