﻿/// <reference path="../entries/main">


%border-disabled {
    border:solid 1px $border-disabled;
}
%light-box {
    background-color:$default-background;
    @extend %border-disabled;
}


%gradient {
    background: var(--background-gradient);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

%image-background {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: 50% 50%;
        object-position: var(--focal-point-h) var(--focal-point-v);
    }
}