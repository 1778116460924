﻿/// <reference path="../entries/main">
.media-heading {
    &-image {
        img {
            width: 100%;
        }
    }

    &-text {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        height: 100%;
        gap: $space;
        word-wrap: break-word;

        &-subtitle {
            .chip-subtitle {
                display: inline-block;
            }
        }

        &-links {
            display: flex;
            flex-flow: row wrap;
            gap: 6px;

            @include responsive-context($component-header-map) {
                margin-top: $responsive-variable;
            }

            .primary, .secondary {
                @include media(small) {
                    width: 100%;

                    > a {
                        width: 100%;
                    }
                }

                a {
                    @extend %btn-primary;
                }
            }

            .secondary {
                a {
                    @extend %btn-light;
                }
            }
        }

        &-content {
            flex-grow: 1;
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
        }

        &-description {
            margin-top: $space;
        }
    }
}
.fifty-fifty {
    &-block {
        position: relative;

        .svg-background {
            width: 100%;
            height: 100%;

            @include media(small) {
                width: 100%;
            }

            &.svg-align {
                &-center {
                    .svg-background-svg {
                        left: 30%;
                    }


                    @include media(small) {
                        .svg-background-svg {
                            left: 0;
                        }
                    }
                }

                &-left {
                    .svg-background-svg {
                        left: 50%;
                    }

                    @include media(small) {
                        .svg-background-svg {
                            left: 0;
                        }
                    }
                }

                &-right {
                    .svg-background-svg {
                        left: 0;
                    }
                }
            }
        }
    }

    &-container {
        position: relative;
        z-index: 2;

        &.default-width {
            .media-heading-image {

                img {
                    width: auto;
                }
            }

            .fifty-fifty-right {
                .media-heading-image {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        &.grid-col-1 {
            .fifty-fifty-left {
                width: 100%;
                display: grid;

                .media-heading-text {
                    @include media-min(large) {
                        max-width: 60%;
                    }
                }


                > .center {
                    justify-self: center;
                    text-align: center;

                    .media-heading-text-links {
                        justify-content: center;
                    }
                }

                > .left {
                    justify-self: flex-start;
                }

                > .right {
                    justify-self: flex-end;
                }
            }
        }
    }
}