﻿//common colors    
    $black: #000711;
    $white:#fff;
    $default-background: #fff;
    $secondary-background: #F8F5F2;
    $tertiary-background: #F3EEE9;
    $light-copy:#F8F5F3;
    $dark-gray:#727272;
    $dark-background: rgba(#000, .32);
    $dark-background-active: rgba(#000, .64);
    $light-background: rgba($light-copy , .14);
    $medium-background: rgba($light-copy , .64);
    $mega-nav-text: rgba($white, .68);
    $border-dark: rgba($black, .42);
    $inactive-background: rgba($black, .82);
    $inactive-background-dark: rgba($light-copy, .82);
    $light-background-active: rgba($tertiary-background , .82);
    $card-background: rgba($tertiary-background, .22);
    $card-background-dark: rgba($tertiary-background, .20);
    $default-focus-ring: $black;
    $background-black:rgba(#000, .22);
    $border-black:rgba(#000, .82);
    $gradient-light:rgba($secondary-background , .80);
    $header-dark: rgba($dark-gray, .92);
    $body-dark: rgba($dark-gray, .62);

    //button-defaults
    $button-background-light: #E6E1DD;
    $text-disabled:rgba($light-copy, .22);
    $text-disabled-darker: rgba($light-copy, .42);
    $text-disabled-light:rgba($button-background-light, .55);
    $button-dark-disabled:rgba($black, .65);
    $button-light-disabled: rgba($black, .42);
    $button-accordion-light: rgba($tertiary-background, .32);
    $button-accordion-light-active: rgba($tertiary-background, .64);
    $counter-background: rgba($tertiary-background, .72);
    
    // inputs
    $search-input: #f6f6f6;
    $input-active: rgba($black, .08);
    $input-disabled: rgba($black, .04);
    $input-focus: rgba($black, .18);
    $border-facets: rgba($black, .12);
    $border-disabled:rgba($black, .42);
    $border-disabled-dark: rgba($light-copy, .42);
    $border-secondary: rgba($black, .72);
    $border-tertiary: rgba($light-copy, .72);
    $input-background-disabled: rgba($light-copy, .04);
    
    //alerts
    $alert-green: #31CB65;
    $alert-yellow: #FFDB5C;
    $alert-red: #FF1818;
    $alert-bright-red: #F0294D;
    $alert-grey: #F3EEE9;
    $table-background: rgba($black, .04);

    //event types
$event-type-primary: #019221;
$event-type-secondary: #0FAEC3;
$event-type-tertiary: #C30A36;
$event-type-quaternary: #0052CC;
$event-type-quinary: #7D1D74;
$event-type-senary: #F07E15;
$event-type-septenary: #F0CD15;

$event-type-default: #585858;

$event-accent-primary: #DFF8E4;
$event-accent-secondary: #DBF9FD;
$event-accent-tertiary: #FFEDF1;
$event-accent-quaternary: #E7EDFF;
$event-accent-quinary: #FCEEFA;
$event-accent-senary: #FFECE7;
$event-accent-septenary: #FFFAE2;

$event-accent-default: #F8F8F8;


//ARA
$ara-dark-blue: #000F3A;
    $ara-dark-red: #610113;
    $ara-dark-grey: #1F1D1B;

    $ara-classic-blue: #003589;
    $ara-classic-red: #D00027;
    $ara-classic-grey: #5B534D;

    $ara-bright-blue: #258AFE;
    $ara-bright-red: #FE254E;
    $ara-bright-grey: #9E8E80;

    $ara-light-blue: #BEDBFF;
    $ara-light-red: #EEB1B4;
    $ara-light-grey: #F8F5F2;

    //ASEA
    $asea-dark-purple: #380041;
    $asea-dark-blue: #0B3544;
    $asea-dark-grey: #1F1D1B;

    $asea-classic-purple: #721281;
    $asea-classic-blue: #21678F;
    $asea-classic-grey: #5B534D;

    $asea-bright-purple: #DA6AEC;
    $asea-bright-blue: #3EB6FA;
    $asea-bright-grey: #9E8E80;

    $asea-light-purple: #EDCFF1;
    $asea-light-blue: #CAE5EF;
    $asea-light-grey: #F8F5F2;

    //NTSA
    $ntsa-dark-blue: #003033;
    $ntsa-dark-green: #2B4200;
    $ntsa-dark-grey: #1F1D1B;

    $ntsa-classic-blue: #01595F;
    $ntsa-classic-green: #527E00;
    $ntsa-classic-grey: #5B534D;

    $ntsa-bright-blue: #2FBDC6;
    $ntsa-bright-green: #9BD42F;
    $ntsa-bright-grey: #9E8E80;

    $ntsa-light-blue: #C0ECF0;
    $ntsa-light-green: #DBEABF;
    $ntsa-light-grey: #F8F5F2;

    //ASPPA
    $asppa-dark-green: #1A4500;
    $asppa-dark-blue: #02314D;
    $asppa-dark-grey: #1F1D1B;

    $asppa-classic-green: #338005;
    $asppa-classic-blue: #094D75;
    $asppa-classic-grey: #5B534D;

    $asppa-bright-green: #66CA2B;
    $asppa-bright-blue: #0391E5;
    $asppa-bright-grey: #9E8E80;

    $asppa-light-green: #CBEDB7;
    $asppa-light-blue: #CCF3FC;
    $asppa-light-grey: #F8F5F2;

    //NAPA
    $napa-dark-blue: #001A43;
    $napa-dark-teal: #0C2B35;
    $napa-dark-grey: #1F1D1B;

    $napa-classic-blue: #164285;
    $napa-classic-teal: #00687E;
    $napa-classic-grey: #5B534D;

    $napa-bright-blue: #4786EB;
    $napa-bright-teal: #00C6E1;
    $napa-bright-grey: #9E8E80;

    $napa-light-blue: #CEDEF6;
    $napa-light-teal: #C8EAEF;
    $napa-light-grey: #F8F5F2;

    //PSCA
    $psca-dark-red: #58000C;
    $psca-dark-purple: #191537;
    $psca-dark-grey: #1F1D1B;

    $psca-classic-red: #AF0019;
    $psca-classic-purple: #2E2766;
    $psca-classic-grey: #5B534D;

    $psca-bright-red: #FF2845;
    $psca-bright-purple: #685ADA;
    $psca-bright-grey: #9E8E80;

    $psca-light-red: #FFD8DD;
    $psca-light-purple: #CECEE8;
    $psca-light-grey: #F3EEE9;

    $default-button-config: ( off: (var(--button-disabled-background), var(--button-disabled-foreground)), on: (var(--button-primary-background), var(--button-primary-foreground)), focus: (var(--button-primary-background), var(--button-primary-foreground)), disabled: (var(--button-disabled-background), var(--button-disabled-foreground)) );

$highlight-button-config: ( off: (var(--button-disabled-background), var(--button-disabled-foreground)), on: (var(--button-highlight-background), var(--button-highlight-foreground)), focus: (var(--button-highlight-background), var(--button-highlight-foreground)), disabled: (var(--button-disabled-background), var(--button-disabled-foreground)) );

    $default-button-light-config: ( off: (var(--button-disabled-background), var(--button-disabled-foreground)), on: (var(--button-tertiary-background), var(--button-tertiary-foreground)), focus: (var(--button-tertiary-background), var(--button-tertiary-foreground)), disabled: (var(--button-disabled-background), var(--button-disabled-foreground)) );