﻿/// <reference path="../entries/main">
.directory-listing {
    > .content-container {
        display: grid;
        grid-template-columns: minmax(300px,1fr) 3fr;
        gap: $gap;

        @include media(medium) {
            grid-template-columns: minmax(100px, 1fr);
        }

        .loader {
            grid-column: span 2;
        }
    }

    &-search {
        display: flex;
        flex-flow: row;
        margin-bottom: $gap-sm;

        input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
            max-width: 100%;
        }
    }

    .sort-select {
        max-width: 15rem;
        flex-grow: 1;

        @include media (small) {
            max-width: 100%;
            width: auto;
        }
    }

    .sort-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        gap: $gap-sm;
    }

    &-cards {
        display: flex;
        flex-flow: column;
        gap: $gap-md-lg;
    }

    &-card {
        &-cell {
            word-wrap: break-word;

            img {
                max-width: 6rem;
            }

            ul {
                list-style-type: disc;
                margin-left: $gap-md;
            }

            + .directory-listing-card-cell {
                margin-top: $sliver;
            }
        }

        &-wrap {
            border: solid 1px var(--button-primary-foreground);
            padding: $gap-md-lg;
            position: relative;
            background: var(--secondary-background);

            &.open {
                border: solid 1px var(--button-disabled-background);
            }

            &.directory-listing-highlight {
                background-color: var(--jewel-primary);
                border-color: var(--jewel-primary);

                .directory-listing-card-modal-content {
                    background-color: var(--jewel-primary);
                    border-color: var(--jewel-primary);
                }
            }
        }

        &-modal {
            position: fixed;
            background: var(--button-disabled-background);
            width: 100%;
            left: 0;
            top: 0;
            z-index: 8;
            height: 100%;
            overflow-y: scroll;

            &-content {
                position: relative;
                background: var(--secondary-background);
                margin-inline: auto;
                top: 20%;
                max-width: 80vw;

                > .btn-primary {
                    position: absolute;
                    right: $gap-sm;
                    top: $gap-sm;
                    z-index: 3;
                }

                .primary-data {
                    &-header {
                        max-width: calc(100% - 5rem);
                    }
                }
            }
        }

        &-toggle {
            justify-self: flex-end;

            &-mobile {
                width: 100%;
                margin-top: $gap-sm;
            }
        }
    }

    &-context {
        &:has(.sort-container) {
            + .directory-results .result-count {
                margin-top: -2.875rem;

                @include media(small) {
                    margin-top: 0;
                }
            }
        }
    }

    &-sidebar {
        overflow: hidden;
    }
}
.directory-results {
    .result-count {
        margin-bottom: $gap;
        line-height: 2.875rem;
        max-width: 50%;

        @include media(small) {
            max-width: 100%;
        }
    }

    &-message {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-top: $gap;
        padding: $gap;
        gap: $gap;

        @include media(medium) {
            margin-top: $gap-md;
            padding: $gap-sm;
            gap: $gap-sm;
        }

        .ph-info {
            width: 40px;
            height: 40px;
            font-size: 40px;
            color: var(--jewel-tertiary);
        }
    }
}
.primary {
    &-data {
        &-header {
            display: grid;
            margin-bottom: $gap-md-lg;

            &:has(.primary-data-header-title) {
                grid-template-columns: 3fr 1fr;
                gap: $gap-md-lg;
                align-items: end;

                @include media(small) {
                    grid-template-columns: 1fr;
                }
            }
        }

        &-flex {
            display: flex;
            flex-flow: row wrap;
            gap: $gap-md-lg;
            column-gap: $gap;


            @include media(medium) {
                column-gap: $gap-md-lg;
            }
        }
    }

    &-left, &-right {
        &-data {
            max-width: 100%;
        }
    }
}

.secondary {
    &-data {
        margin-top: $gap-md-lg;
        display: flex;
        flex-flow: row wrap;
        gap: $gap-md-lg;

        &:has(.secondary-left-data):has(.secondary-right-data) {
            .secondary-left, .secondary-right {
                &-data {
                    width: calc(50% - .75rem);

                    @include media(small) {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-left, &-right {
        &-data {
            width: 100%;
            word-wrap: break-word;
        }
    }
}
.directory-top-content {
    .wysiwyg-block {
        padding-block: $gap-md-lg;
    }

    + .directory-listing-page-app {
        .content-container {
            padding-top: $gap-sm;
        }
    }
}