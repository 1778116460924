/// <reference path="../../entries/main">
// react big calendar v 1.11.2 variable overrides

$out-of-range-color: var(--calendar-range-color);
$out-of-range-bg-color: var(--calendar-range-bg-color);

$calendar-border: $white;
$cell-border: $white;

$border-color: $secondary-background;

// Each calendar segment is 1/7th.
$segment-width: 0.14286% !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
$date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;

$event-bg: $white !default;
$event-border: $white !default;
$event-outline: #fff !default;
$event-color: $black !default;
$event-border-radius: 0 !default;
$event-padding: 6px 12px !default;
$event-zindex: 4 !default;

$btn-color: var(--button-primary-foreground);
$btn-bg: var(--button-primary-background);
$btn-border: var(--button-primary-background);

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: var(--classic-tertiary);
