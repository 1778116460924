﻿/// <reference path="../../entries/main">

@mixin visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

@mixin hide {
    display: none;
    visibility: hidden;
}