﻿/// <reference path="../../entries/main">

body {
    @extend %body;
    font-size: 100%;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cpsp' on, 'zero' on, 'cv10' on;
}

.large {
    @extend %large;
}
.regular {
    @extend %regular;
}

.small {
    @extend %small;
}

.accent {
    @extend %accent;
}

a,
.hyperlink {
    @extend %hyperlink;
}

.unlink {
    text-decoration: none;
}

b,
strong,
.bold {
    font-weight: $font-weight-bold;
}
li::marker {
    font-size: small;
}
.uppercase {
    text-transform: uppercase;
}

.medium {
    font-weight: $font-weight-semibold;
}

sup {
    vertical-align: super;
    font-size:.875em;
}
sub {
    vertical-align: sub;
    font-size: .875em;
}
s {text-decoration:line-through;}
em,
i,
.italic {
    font-style: italic;
}

.align {
    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }
}

.header + * {
    @extend %text-top-margin;
}

%light,
.light {
    color: rgba($black, 0.72);
}

%tag, .tag {
    @extend %chip-outline;
    font-weight: $font-weight-bold;
    cursor: auto;

    &a:hover, &a:active, &a:focus {
        background: var(--button-primary-background);
        color: var(--button-primary-foreground);
    }
}

%category, .category {
    @extend %chip-outline;
    font-family: $body-font;
    font-weight: $font-weight-normal;
    border-width: 1px;
    cursor: default;

    &a:hover, &a:active, &a:focus {
        background: var(--button-primary-background);
        color: var(--button-primary-foreground);
    }
}
blockquote {
    @extend %blockquote;
    font-style: italic;
}
::selection {
    background-color: var(--foreground) !important;
    color: var(--background-color) !important;
}
%icon-link {
    &::selection {
        background-color: var(--background) !important;
        color: var(--foreground) !important;
    }
}
button {
    font-family: $body-font;
}