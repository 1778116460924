﻿/// <reference path="../entries/main">
.counter {
    &-container {
        display: grid;

        @include responsive-context($gutter-map-v) {
            margin-top: $responsive-variable;
        }

        &-block {
            position: relative;

            .content-container {
                z-index: 3;
                position: relative;
            }
        }


        @include responsive-context($gutter-map-v) {
            gap: $responsive-variable;
        }

        &-4 {
            grid-template-columns: repeat(4, minmax(100px, 1fr));
            grid-template-rows: repeat(1, auto);

            @include media(medium) {
                grid-template-columns: repeat(2, minmax(100px, 1fr));
                grid-template-rows: repeat(2, auto);
            }

            @include media(small) {
                grid-template-columns: repeat(1, minmax(100px, 1fr));
                grid-template-rows: repeat(4, auto);
            }

            &:has(.highlighted) {
                grid-template-columns: repeat(3, minmax(100px, 1fr));
                grid-template-rows: repeat(3, auto);

                @include media(medium) {
                    grid-template-columns: repeat(3, minmax(100px, 1fr));
                    grid-template-rows: repeat(2, auto);
                }

                @include media(small) {
                    grid-template-columns: repeat(1, minmax(100px, 1fr));
                    grid-template-rows: repeat(4, auto);
                }
            }

            .highlighted {
                grid-column: 1 / span 2;
                grid-row: 1 / span 3;

                @include media(medium) {
                    grid-column: 1 / span 3;
                    grid-row: 1;
                }

                @include media(small) {
                    grid-column: 1;
                    grid-row: 1;
                }

                .counter-block-value {
                    @include media-min(medium) {
                        --x-large-text: 7.625rem;
                    }

                    @extend .h1-style;
                }
            }
        }

        &-3 {
            grid-template-columns: repeat(3, minmax(100px, 1fr));
            grid-template-rows: auto;

            @include media(medium) {
                grid-template-columns: repeat(2, minmax(100px, 1fr));
                grid-template-rows: repeat(2, auto);

                .counter-item:last-child {
                    grid-column: 1 / span 2;
                }
            }

            @include media(small) {
                grid-template-columns: repeat(1, minmax(100px, 1fr));
                grid-template-rows: repeat(3, auto);

                .counter-item:last-child {
                    grid-column: 1;
                }
            }
        }

        &-2 {
            grid-template-columns: repeat(2, minmax(100px, 1fr));
            grid-template-rows: auto;

            .counter-item:first-child {
                width: 100%;
                justify-self: end;
            }

            @include media(medium) {
                grid-template-columns: repeat(2, minmax(100px, 1fr));
                grid-template-rows: auto;
            }

            @include media(small) {
                grid-template-columns: repeat(1, minmax(100px, 1fr));
                grid-template-rows: repeat(2, auto);
            }

            .counter-item {
                max-width: 39.5rem;
            }
        }

        &-1 {
            grid-template-columns: auto;
            grid-template-rows: auto;

            .counter-item {
                width: 100%;
                max-width: 81rem;
                justify-self: center;

                .counter-block-value {
                    @include media-min(medium) {
                        --x-large-text: 7.625rem;
                    }

                    @extend .h1-style;
                }
            }
        }
    }

    &-item {
        background-color: var(--counter-background);

        @include responsive-context($gutter-map-v) {
            padding: $responsive-variable;
        }
    }

    &-image-background {
        @extend %image-background;
    }

    &-gradient {
        @extend %gradient;
    }

    &-block {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: $gap-sm;
        width: 100%;
        height: 100%;

        &-value, &-label {
            word-break: break-word;
        }

        &-icon {
            width: 4rem;
            height: 4rem;
            margin-inline: auto;
        }
    }
}