﻿/// <reference path="../entries/main">
.subscription-page {
    &-block {
        display: flex;
        flex-flow: column;
        gap: 1rem;

        @include responsive-context($gutter-map-v) {
            margin-block: $responsive-variable;
        }
    }

    &-header {
        display: flex;
        gap: $gap-md;
        flex-flow: column;

        h1 {
            overflow-wrap: break-word;
        }
    }

    &-form {

        button {
            @include media(small) {
                width: 100%;
            }

            + button {
                margin-left: $gap-sm;

                @include media(small) {
                    margin-top: $gap-sm;
                    margin-left: 0;
                }
            }
        }
    }

    &-error, &-user {
        padding: $gap-md-lg;
        margin-top: $gap-md-lg;
    }
}

.learn-upon-content {
    .form-element {
        margin-block: $gap-md;
    }
}