﻿/// <reference path="../entries/main">


.search-results {
    @include media-min(medium) {
        grid-column: 2;
        min-height: 100vh;
    }

    &-sidebar {
        overflow: hidden;

        @include media-min(medium) {
            grid-column: 1;
            grid-row: 1 / span 3;
        }

        > .mobile-filter-accordion {
            > .accordion-trigger {
                background-color: var(--dark-primary);
                border-color: var(--dark-primary);
                color: $white;
            }
        }
    }

    &-pagination {
        @include media-min(medium) {
            grid-column: 2;
        }

        .pagination {
            justify-content: center;
        }
    }

    &-header {
        display: flex;
        flex-flow: row wrap;
        row-gap: $gap-md-lg;

        @include media-min(medium) {
            grid-column: 2;
        }

        &-sort {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            gap: $gap-md;
        }

        &-desc {
            @include media(small) {
                width: 100%;
            }
        }

        .header-search-button, .header-search-button i:before {
            background-color: var(--dark-primary);
            color: $white;
        }

        .header-search-button {
            max-height: 2.875rem;
        }

        .search-input:not([type=checkbox]):not([type=radio]):not([type=reset]):not([type=submit]) {
            max-width: 100%;
            background: $search-input;
            max-height: 2.875rem;
        }

        .search-autocomplete {
            display: flex;
            flex-flow: row;
            flex-grow: 1;
            width: 100%;
            max-width: 75%;

            @include media(small) {
                max-width: 100%;
            }
        }

        .sort-container {
            display: flex;
            flex-flow: row;
            flex-grow: 1;
            align-items: center;
            justify-content: flex-end;
            gap: $space;
        }

        .sort-select {
            flex-grow: 1;
            max-width: 10rem;

            @include media(small) {
                max-width: 100%;
            }
        }
    }

    &-wrap {
        display: grid;
        grid-template-columns: minmax(300px, 1fr) minmax(100px, 3fr);
        gap: $gap-md-lg;
        column-gap: $gap;

        @include media(medium) {
            grid-template-columns: minmax(100px, 1fr);
        }
    }
}


.search-listing {
    &-card {
        display: flex;
        flex-flow: row wrap;
        column-gap: 1.5rem;
        padding: $gap-xs;
        border: solid 1px var(--card-border);

        + .search-listing-card {
            margin-top: 1.38rem;
        }

        &:has(.search-listing-img) .search-listing-content {


            @include media-min(small) {
                max-width: calc(100% - 13.15rem);
            }
        }

        &:has(a:hover) {
            background-color: var(--secondary-background);
        }
    }

    &-img {
        max-width: 11.65rem;

        @include media-min(small) {
            width: 11.65rem;
        }

        @include media(small) {
            display: none;
        }
    }

    &-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: $gap-sm;
        margin-bottom: $gap-xs;
        max-width: 100%;

        @include media(small) {
            flex-flow: column-reverse;
        }
    }

    &-content {
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        flex-flow: column;
        gap: $gap-xs;
        justify-content: space-between;
    }

    &-title {
        width: 100%;
        word-wrap: break-word;

        @include media(small) {
            max-width: 100%;
        }
    }

    &-categories {
        display: flex;
        flex-flow: row wrap;
        gap: $space;
    }

    &-category {
        cursor: pointer;
        @include font-size($label-font-sizes);

        &:hover {
            text-decoration: underline;
        }

        &-restricted {
            display: flex;
            flex-flow: row;
            gap: $sliver;
            align-items: center;
            line-height: normal;
            background: var(--light-primary);
            color: var(--dark-primary);
            border-color: var(--dark-primary);
            text-decoration: none;
            cursor: default;
            font-weight:$font-weight-bold;

            &:hover {
                cursor: default;
                text-decoration: none;
            }
        }
    }
}

.popular-searches {
    margin-block: $gap;

    @include media(medium) {
        display: none;
    }

    &-list {
        display: flex;
        gap: $gap-xs;
        flex-flow: column;
    }
}
.search-autocomplete {
    position: relative;

    &-results {
        position: absolute;
        top: 100%;
        right:2.625rem;
        background: $white;
        border: solid 1px var(--input-background);
        z-index: 9;
        padding: $gap-sm;
        width: calc(100% - 2.625rem);
        display: none;
        
        &.visible {
            display: block;
            button {
                width:100%;
                &:hover, &:focus{
                    background-color: var(--input-background);
                }
            }
        }
    }
}
.search-context-selector {
    width: 25%;

    @include media(small) {
        width: 100%;
    }

    .sort-select {
        width: 100%;
        max-width: 100%;
        background-color: var(--input-background);
    }
}