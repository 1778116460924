﻿/// <reference path="../../entries/main">
.svg-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    &-svg {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: -500px;
        left: 40%;
        overflow: visible;
    }

    &.svg-two-tone-swoop {
        .svg-background-svg {
            top:-500px;
        }
    }
    &.svg-egg {
        .svg-background-svg {
            top:0;
        }
    }
}