﻿/// <reference path="../entries/main">
@use 'sass:math';
%block-sizing {
    width: 100%;

    @include media-min(small) {
        @include responsive-context($gutter-map-h) {
            width: calc((50% - #{math.div(($responsive-variable), 2)}));
        }
    }

    @include media-min(medium) {
        @include responsive-context($gutter-map-h) {
            width: calc((25% - #{math.div(($responsive-variable * 3), 4)}));
        }
    }
}
.download {
    &-container {
        &-filters {
            display: flex;
            flex-flow: row wrap;
            gap: $gap-sm;

            @include responsive-context($gutter-map-v) {
                margin-top: $responsive-variable;
            }

            [data-filter-state="active"] {
                background-color: var(--inactive-button);

                i {
                    display: none;
                }
            }
        }

        &-items {
            @include responsive-context($gutter-map-v) {
                margin-top: $responsive-variable;
            }
        }

        &-more {
            margin-inline: auto;
            display: block;
        }

        &-filter {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            gap: $sliver;
        }
    }

    &-block {
        @extend %block-sizing;
        padding: $gap-sm;
        display: flex;
        flex-flow: column;
        gap: $gap-sm;
        background-color: var(--card-background);
        border-color: var(--card-border);
        border-width: 1px;
        border-style: solid;

        @include responsive-context($gutter-map-v) {
            margin-bottom: $responsive-variable;
        }

        &-image {
            width: 100%;
            height: 16.75rem;
            background-color: var(--download-card);

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .category {
            &-pill {
                display: inline-block;
            }
        }

        .categories {
            display: flex;
            flex-flow: row wrap;
            gap: $sliver;
        }

        &-button {
            a {
                @extend %icon-link;
            }
        }
    }

    &-masonry {
        &-sizer {
            @extend %block-sizing;
        }

        &-gutter {
            @include responsive-context($gutter-map-h) {
                width: $responsive-variable;
            }
        }
    }
}