﻿/// <reference path="../entries/main">
@use "sass:math";

.accordion {
    margin-left: auto;
    margin-right: auto;

    &-panel {
        overflow: hidden;
        @include responsive-property('padding-inline', $small-gutter-map-h);

        > :last-child {
            margin-bottom: $gap-xs;
        }

        &[aria-hidden="true"] {
            height: 0 !important;
        }
    }

    &-container.grid {
        @include media(medium) {
            grid-template-columns: 1fr
        }
    }

    &-list {
        padding-left: 0;
        margin-top: $gap-sm;

        @include media-min(medium) {
            margin-top: $gap-md;
        }

        &-item {

            & + & {
                margin-top: $gap-xs;
            }
        }
    }

    &-icon {
        height: $gap-md;
        width: $gap-md;
        font-size: 1.375rem;
        text-align: center;
        place-content: center;
        display: flex;
        background-color: var(--accordion-icon-background);
        color: var(--accordion-icon-color);
    }

    &-image {
        height: $gap-md;
        width: $gap-md;
        margin-right: $space;
    }

    &-trigger {
        background: transparent;
        display: flex;
        align-items: center;
        @include responsive-property('padding-inline', $small-gutter-map-h);
        padding-block: $gap-xs;
        text-align: left;
        width: 100%;
        text-transform: none;
        background-color: var(--accordion-background);
        border-color: var(--accordion-border);

        &:focus {
            box-shadow: none;
        }

        & > * {
            pointer-events: none;
        }

        &-title {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            flex-grow: 1;
            align-items: center;
            gap: $sliver;
        }
    }

    &-panel {
        transition: $transition height;
        background-color: var(--accordion-background-active);

        &-content {
            padding: $gap;
        }
    }
}


// ARROW/INDICATOR
.accordion {
    &-trigger {
        @extend %un-button;
        position: relative;

        &:hover, &:focus {
            background-color: var(--accordion-background-active);
            border-color: var(--accordion-border-active);
        }

        &[aria-expanded="true"] {
            background-color: var(--accordion-background-active);
            border-color: var(--accordion-border-active);

            i {
                transform: rotate(180deg);
            }
        }
    }
}
