﻿/// <reference path="../entries/main">
.survey-form {
    &-question {
        margin-bottom: $gap;
        @extend .h4-style;
        width: 100%;
    }

    &-answer {

        @extend %large;

        &-list {
            display: flex;
            flex-flow: row wrap;
            gap: $gap-sm;
            align-items: center;
            margin-bottom: $gap-sm;
            row-gap: $sliver;
        }
    }

    &-percent {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 10%;
        width: 100%;
        font-weight: $font-weight-bold;
        @include font-size($large-reg-font-sizes);

        &-largest {

            .survey-form-percent {
                color: var(--svg-fill-primary);

                &-line.animate:before {
                    background-color: var(--svg-fill-primary);
                }
            }
        }

        &-line {
            width: 200px;
            display: block;
            position: relative;
            height: 1.125rem;
            flex-grow: 1;
            background-color: transparent;
            margin-bottom: $sliver;

            &:before {
                position: absolute;
                content: "";
                background: var(--alert-green);
                left: 0;
                width: 0;
                transition: all .5s ease;
                height: 100%;
            }

            &.animate:before {
                width: var(--survey-answer-percent);
                background-color: var(--button-primary-background);
            }
        }
    }

    &-results {
        margin-top: $gap-sm;
        padding: $gap;

        @include media(small) {
            padding: $gap-sm;
        }

        @include media(mobile) {
            padding: 0;
        }

        ul {
            width: 100%;
            margin-bottom: $gap;
        }

        &-container {
            padding: $gap-md;
            display: none;

            &.active {
                display: block;
            }

            &.open {
                .loader {
                    display: none;
                }
            }
        }
    }


    &-result-container {
        display: flex;
        flex-flow: row wrap;

        > ul {
            ///individual question color coded option
            /* 
            li:nth-child(1) {
                .survey-form-percent-line {
                    &:before {
                        background: $event-type-primary;
                    }
                }
            }

            li:nth-child(2) {
                .survey-form-percent-line {
                    &:before {
                        background: $event-type-secondary;
                    }
                }
            }

            li:nth-child(3) {
                .survey-form-percent-line {
                    &:before {
                        background: $event-type-tertiary;
                    }
                }
            }

            li:nth-child(4) {
                .survey-form-percent-line {
                    &:before {
                        background: $event-type-quaternary;
                    }
                }
            }

            li:nth-child(5) {
                .survey-form-percent-line {
                    &:before {
                        background: $event-type-quinary;
                    }
                }
            }

            li:nth-child(6) {
                .survey-form-percent-line {
                    &:before {
                        background: $event-type-senary;
                    }
                }
            }

            li:nth-child(6) {
                .survey-form-percent-line {
                    &:before {
                        background: $event-type-septenary;
                    }
                }
            }*/
        }
    }
}
.EPiServerSurveyForm {
    div.Form__Status .Form__Success__Message {
        background-color: var(--background-color);
        color: var(--foreground);
    }
}