﻿/// <reference path="../../entries/main">
// ------------- FONTS ------------- //

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-BoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-RegularItalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-UltraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusGrotesque';
    src: url('/wwwroot/Static/fonts/AcherusGrotesque-UltraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Jubilat';
    src: url('/wwwroot/Static/fonts/Jubilat.ttf') format('truetype');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
    font-style:normal;
}

@font-face {
    font-family: 'Jubilat';
    src: url('/wwwroot/Static/fonts/Jubilat-Italic.ttf') format('truetype');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
    font-style:italic;
}