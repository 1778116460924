﻿/// <reference path="../entries/main">
.pagination {
    display: flex;
    flex-flow: row wrap;
    margin-top: 2rem;
    gap: 1rem;
    justify-content: center;

    &-pages {
        display: flex;
        flex-flow: row wrap;
        gap: 5px;
        align-items: baseline;

        @include media(small) {
            order: 1;
            width: 100%;
            justify-content: center;
            gap:$gap-xs;
        }
    }

    &-buttons {
        display: flex;
        flex-flow: row wrap;
        gap: 6px;

        @include media(small) {
            order: 2;
            width: calc(50% - .5rem);
            gap:$gap-xs;
            &:last-child {
                justify-content: flex-end;
            }
        }
    }

    &-page-button {
        --button-primary-background: var(--input-background);
        --button-primary-foreground: var(--foreground);

        @include media(small) {
            padding: $space $gap-xs;
        }

        &.selected {
            --button-disabled-background: var(--button-selected);
            --button-disabled-foreground: var(--foreground);
            border: solid 1px currentcolor;
        }
    }
}