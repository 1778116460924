﻿/// <reference path="../entries/main">
.event-detail {
    &-hero {
        &-image {
            width: 100%;
            aspect-ratio: 8 / 3;
            overflow: hidden;

            @include media(small) {
                aspect-ratio: 16 / 9;
            }

            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
                object-position: 50% 50%;
                object-position: var(--focal-point-h) var(--focal-point-v);
            }
        }

        &-video {
            width: 100%;
            aspect-ratio: 16/9;
            position: relative;
        }

        &-vimeo {
            &-background {
                position: relative;
                z-index: -1;

                iframe {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: none;
                    z-index: -1;
                }
            }

            &-controls {
                position: absolute;
                bottom: $gap;
                right: $gap;
                z-index: 9;

                @include media(medium) {
                    bottom: $gap-sm;
                    right: $gap-sm;
                }
            }
        }
    }

    &-header {
        background-color: var(--secondary-background);

        &-wrap {
            display: flex;
            flex-flow: row wrap;
            gap: $gap;
            justify-content: space-between;
        }

        &-info {
            display: flex;
            flex-flow: column wrap;
            gap: $gap-sm;
            align-self: flex-end;
            text-align: right;
            place-items: flex-end;

            @include media(medium) {
                flex-flow: row wrap;
                justify-content: space-between;
                width: 100%;
            }
        }

        &-content {
            flex-grow: 1;
            max-width: calc(100% - 200px);

            h1 {
                margin-top: .25rem;
                margin-bottom: 1.5rem;
            }
        }

        &-img {
            max-width: 15.75rem;
            width: 20%;

            @include media(small) {
                display: none;
            }
        }
    }

    &-subnav {
        background-color: var(--jewel-primary);

        &-link {
            @include media(medium) {
                display: block;
                width: 100%;
                padding: $gap-sm;
                text-align: center;
                background-color: var(--accordion-background-active);
            }
        }

        &-list {
            display: flex;
            flex-flow: row wrap;
            gap: $gap;
            align-items: center;

            @include media(medium) {
                flex-flow: column;
                gap: 2px;
            }
        }

        .content-container {
            padding-block: $gap-md;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            gap: $gap-sm;

            @include media(medium) {
                flex-flow: row wrap;
            }
        }

        &-cta {
            @include media(small) {
                width: 100%;
            }

            a {
                @extend %btn-primary;

                @include media(small) {
                    width: 100%;
                }
            }
        }
    }

    &-date {
        display: flex;
        flex-flow: column;
    }
}
.sub-nav-accordion {
    @include media-min(medium) {
        height: 100%;
    }


    &-button {
        padding-block:0.55rem;
        @include media-min(medium) {
            display: none;
        }
    }

    &-list {
        margin-top: 0;
        flex-grow:1;
        @include media(small) {
            width: 100%;
        }

        .event-detail-subnav-list {
            margin-bottom: 0;


            @include media-min(medium) {
                height: 100%;
            }
        }
    }

    &-panel{
        &[aria-hidden=true] {
        @include media-min(medium) {
            display: block;
            height: 100% !important;
            background-color: transparent;
        }
    }
        padding-inline:0;
    }
}

.progress-block {
    &-grid {
        display: flex;
        flex-flow: row wrap;
        gap: $gap;
        margin-top: 3.88rem;
        justify-content: center;

        @include media(large) {
            gap: $gap-md;
        }
    }

    &-item {
        width: 100%;
        max-width: 29.5rem;

        @include media-min(small) {
            max-width: calc(50% - 1.5rem);
        }

        @include media-min(large) {
            max-width: calc(25% - 1.5rem);
        }




        &-content {
            display: flex;
            flex-flow: column;
            gap: $gap-sm;

            @include media(small) {
                text-align: left;
                max-width: calc(60% - .5rem);
            }
        }
    }

    &-title {
        text-decoration: underline;
    }

    &-link {
        display: flex;
        flex-flow: column;
        gap: $gap-sm;
        align-items: center;
        text-align: center;
        text-decoration: none;

        @include media(small) {
            flex-flow: row wrap;
        }

        &:hover, &:active, &:focus {
            .progress-block-title {
                text-decoration: underline;
            }
        }

        &:hover .progress-block-image {
            background-color: var(--jewel-primary);
            transform: scale(1.1);

            svg, img {
                fill: white;
            }

            &-hover {
                display: block;
            }

            &:has( .progress-block-image-hover) > img {
                display: none;
            }

            &:has( .progress-block-image-hover) > picture {
                display: none;
            }
        }
    }

    &-content {
        text-decoration: none;
    }

    &-image {
        border-style: solid;
        border-width: 1px;
        border-color: var(--jewel-primary);
        display: flex;
        place-content: center;
        max-width: 8.75rem;
        padding-inline: $gap-sm;
        padding-block: $gap;
        background-color: var(--secondary-background);
        transition: all 0.05s ease;

        @include media(small) {
            max-width: calc(40% - .5rem);
        }

        img {
            max-width: 3rem;
            max-height: 3rem;
            width: 100%;
            height: 100%;
            display: block;
            margin-inline: $gap;
            fill: var(--jewel-primary);
        }

        svg {
            fill: var(--jewel-primary);
        }

        &-hover {
            display: none;
        }
    }

    .global-heading-block {
        &.component-header {
            text-align: center;
            justify-content: center;
        }

        .component-header-wrapper {
            flex-flow: column;
            align-items: center;
        }
    }
}

