﻿/// <reference path="../entries/main">
$z-modal: 1000;

.float {
    &-block {
        &-open {
            color: var(--button-primary-foreground);
            padding: 6px;
            border-radius: 2.625rem;
            display: block;
            background: var(--button-primary-foreground);
            border: solid 1px var(--tab-border);
            position: fixed;
            z-index: 4;
            bottom: 2rem;
            cursor: pointer;

            @include responsive-context($gutter-map-h) {
                right: $responsive-variable;
            }

            i {
                display: block;
                font-size: 1.5rem;
                line-height: 1.5rem;
                padding: 10px 14px 8px;
                background: var(--button-primary-background);
                border-radius: 2.625rem;
            }
        }

        .content-container {
            width: 100%;
            height: 100vh;
        }

        &-modal {
            &-content {
                border: solid 1px var(--black);

                @include responsive-context($gutter-map-h) {
                    padding: $responsive-variable;
                }

                overflow-y: auto;
                height: 100%;

                > * {
                    max-width: calc(100% - 100px);
                }
            }

            &-collapse {
                color: var(--button-primary-foreground);
                padding: 6px;
                border-radius: 2.625rem;
                display: block;
                top: 2rem;
                border: none;
                background-color: var(--background);

                i {
                    display: block;
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                    padding: 10px 14px 8px;
                    background: var(--button-primary-background);
                    border-radius: 2.625rem;
                }
            }
        }

        .modal-dialog {
            height: 100%;
        }
    }
}
// STRUCTURE & LAYOUT
.modal {
    &-container {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            display: none;
        }

        z-index: $z-modal;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        opacity: 1;
        display: flex;
        justify-content: center;
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &::after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        pointer-events: none;
        height: 100%;
        overflow: auto;
    }

    &-inner {

        &-cta-links {
            display: flex;
            justify-content: flex-end;
            margin-top: $gap-sm;
        }

        &-survey {
            max-width: 40rem;
            margin-inline: auto;

            .interstitial-block-inner {
                padding: 1.5rem;
            }

            .theme-light-primary {
                --button-primary-background: var(--jewel-primary);
                --background-color: var(--alert-grey);
                background-color: var(--alert-grey);
            }

            .interstitial-block-form {
                width: 100%;
            }

            .interstitial-block-close .modal-close {
                top: 0;
                right: 0;
                @extend %icon-link;
            }
        }
    }
}


// STYLING
.modal {
    &-container {
        background-color: var(--tab-background);
        transition: 0.3s opacity;
        &-survey {
            background-color: rgba(0, 0, 0, 0.68);
        }
    }

    &-inner {
        background-color: var(--background);
        pointer-events: auto;

        &-full-bleed {
            padding: 0;
        }
    }
}

// CLOSE BUTTON
.modal {
    &-close {
        top: $space;
        right: $gap-sm;
        pointer-events: auto;
        position: absolute;
        cursor:pointer;
    }
}
