﻿/// <reference path="../../entries/main">

*:not(label) > input[type="checkbox"],
*:not(label) > input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    opacity: 0;

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

input[type="checkbox"],
input[type="radio"] {
    &:focus {
        & + label,
        & + input[type="hidden"] + label {
            @include focused;
        }
    }

    & + label,
    & + input[type="hidden"] + label {
        @include focus;
        position: relative;
        display: inline-block;
        margin-left: 0;

        &::before {
            display: inline-block;
            position: absolute;
            left: 0;
            top: $radiocheck-offset;
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::after {
            position: absolute;
            display: inline-block;
        }
    }

    &:hover,
    &:focus,
    &:checked {
        & + label,
        & + input[type="hidden"] + label {
            &::before {
                color: var(--foreground);
            }
        }
    }

    &:hover,
    &:focus {
        & + label,
        & + input[type="hidden"] + label {
            color: var(--foreground);
        }
    }

    &[disabled] {
        & + label,
        & + input[type="hidden"] + label {
            color: var(--button-disabled-foreground);

            &::before {
                border-color: var(--button-disabled-foreground);
            }
        }
    }
}
// SIZING
input[type="radio"],
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        padding-left: $radiocheck-size + $radiocheck-padding;

        &::before {
            font-size: $radiocheck-size;
            font-family: "Phosphor-Light" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::before {
            color: var(--foreground);
        }
    }
}
// CHECKBOX
//ph-square
//ph-check-square
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {

        &::before {
            content: "\e45e";
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::before {
            content: "\e186";
        }
    }
}
// RADIO
//ph-circle
//ph-check-circle
input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::before {
            content: "\e18a";
            
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::before {
            content: "\e184";
        }
    }
}

.radiocheck {
    &-offset {
        margin-left: $radiocheck-offset;
    }
}
