﻿/// <reference path="../../entries/main">
.quick-nav {
    position: relative;
    border-left-color: var(--input-background);
    border-left-style: solid;
    border-left-width: 1px;
    padding-left: 1rem;

    &.utility-nav-link-quick-tablet {
        border-left: 0;
        padding-left: 0;
    }

    &:hover {
        .quick-nav-links {
            display: block;
        }

        .quick-nav-collapsed {
            display: none;
        }
    }

    &-button {
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: $sliver;
        @extend %sister-nav-button;
        color: var(--dark-primary);
        text-decoration: none;
        font-weight: 700;
        @extend %regular;
    }

    &-link {
        @include font-size($x-small-font-sizes);
        font-family: $body-font;
        font-weight: $font-weight-medium;

        &-item {
            + .quick-nav-link-item {
                margin-top: $space;
            }
        }
    }

    &-links {
        display: none;
        position: absolute;
        z-index: 1000;
        background: var(--classic-primary);
        padding: $gap-sm;
        min-width: 200px;
        right: 0;
        color: var(--white);
        border-top: solid $space;

        &.quick-nav-expanded {
            display: block;
        }

        svg {
            position: absolute;
            right: $gap-sm;
            bottom: 100%;
            fill: var(--classic-primary);
        }


        &-mobile {
            display: none;
            padding-inline: $gap-sm;
            padding-block: 1.375rem;
            background-color: var(--classic-primary);
            flex-flow: column;
            gap: $gap-xs;
            color: var(--white);

            &.quick-nav-expanded {
                @include media(small) {
                    display: flex;
                }
            }
        }
    }

    &-container {
        &-mobile {
            display: flex;
            flex-flow: row wrap;
            align-items: baseline;
            gap: $gap-sm;
            margin-bottom: $gap-xs;

            &:has(.utility-nav-link-sister-mobile) {
                width: 100%;
            }

            .quick-nav {
                border-left: 0;
                padding-left: 0;

                &-button {
                    color: var(--jewel-primary);
                }
            }
        }
    }
}