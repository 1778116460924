﻿/// <reference path="../../entries/main">

@mixin generate-focus-box-shadow($_inner-color: $white, $_outer-color: $default-focus-ring, $_weight: 'medium') {
    $_base-weight: $focus-weight;

    @if $_weight == 'small' {
        $_base-weight: 1px;
    }

    box-shadow: $_inner-color 0 0 0 $_base-weight, $_outer-color 0 0 0 ($_base-weight * 2);
    box-shadow: var(--background-color, #{$_inner-color}) 0 0 0 $_base-weight, var(--focus-ring-color, #{$_outer-color}) 0 0 0 ($_base-weight * 2);
}

@mixin focused() {
    @include generate-focus-box-shadow();
}

@mixin focus() {
    outline: none;
    transition: $transition all;

    &:focus {
        @include focused;
    }
}

@mixin round-focus() {
    &:focus {
        @include generate-focus-box-shadow();
        outline-color: transparent;
    }
}
