﻿/// <reference path="../entries/main">

.title-block {
    &-content {
        display: flex;
        flex-flow: row wrap;
        gap: $gap;

        @include media(small) {
            gap: $gap-sm;
        }
    }

    &-header {
        width: calc(50% - $gap-sm);
        display: flex;
        flex-flow: column;
        gap: $space;
        justify-content: center;

        @include media(medium) {
            width: auto;
        }
    }

    &-image {
        width: calc(50% - $gap-sm);

        @include media(medium) {
            width: auto;

            @include media(small) {
                text-align: center;
                width: 100%;
            }
        }
    }

    &-cta {
        a {
            @extend %btn-primary;
        }
    }

    .content-container {
        @include responsive-context($header-map-large) {
            padding-block: $responsive-variable;
        }
    }
}