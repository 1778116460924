﻿/// <reference path="../../entries/main">
$calendar-max-width: 1500px;

.rbc-event.rbc-event-continues-after {
    padding-right: 0;
    position: relative;
    &:after {
        display: block;
        top: 6px;
        content: "";
        position: absolute;
        width: 2px;
        height: calc(100% - 12px);
        left: 100%;
        background-color: $white;
    }
}

.rbc-event.rbc-event-continues-prior {
    padding-left: 0;
    margin-left: -1px;
}

.rbc {
    &-date {
        &-cell {
            &.rbc-now {
                color: $white;
            }
        }
    }

    &-day {
        &-bg {
            &:not(.rbc-today):not(.rbc-off-range-bg) {
                background-color: $secondary-background;
            }
        }
    }

    &-calendar .content-container {
        width: 100%;
    }

    &-month {
        &-row {
            min-height: 300px;
        }
    }

    &-btn-group {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: $gap-sm;
    }

    &-active.btn-primary {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        gap: $space;
    }

    &-event {
        &:hover {
            .rbc-event-content {
                &:has(.event-style-primary) {
                    background-color: $event-type-primary;
                    border-color: $event-type-primary;

                    &:after {
                    }
                }

                &:has(.event-style-secondary) {
                    background-color: $event-type-secondary;
                    border-color: $event-type-secondary;

                    &:after {
                    }
                }

                &:has(.event-style-tertiary) {
                    background-color: $event-type-tertiary;
                    border-color: $event-type-tertiary;

                    &:after {
                    }
                }

                &:has(.event-style-quaternary) {
                    background-color: $event-type-quaternary;
                    color: white;
                    border-color: $event-type-quaternary;

                    &:after {
                    }
                }

                &:has(.event-style-quinary) {
                    background-color: $event-type-quinary;
                    color: white;
                    border-color: $event-type-quinary;

                    &:after {
                    }
                }

                &:has(.event-style-senary) {
                    background-color: $event-type-senary;
                    border-color: $event-type-senary;

                    &:after {
                    }
                }

                &:has(.event-style-septenary) {
                    background-color: $event-type-septenary;
                    border-color: $event-type-septenary;

                    &:after {
                    }
                }

                &:has(.event-style-default) {
                    background-color: $event-type-default;
                    color: white;
                    border-color: $event-type-default;

                    &:after {
                    }
                }
            }
        }
    }

    &-toolbar button {

        &:has(.arrow) {
            padding: 7px 12px;
            display: flex;
            place-items: center;
        }

        i {
            font-size: 1.5rem;
        }

        &:active, &.rbc-active {
            background-color: var(--button-disabled-background);

            &:hover, &:focus {
                background-color: var(--button-disabled-background);
            }
        }
    }

    &-row-content-scrollable {
        overflow: hidden;
    }
}
.rbc-toolbar button
//override the default border width for calendar view
.rbc-row-segment:first-child:nth-last-child(1){ 
    .rbc-event {
    padding-right: 20px;
    }
    .rbc-event.rbc-event-continues-after {
        padding-right: 0;
        position:relative;
        
    }
}

.rbc-event-content {
    background-color: white;
    padding: 6px 12px;
    white-space: normal;
    position: relative;
    border-width: 2px;
    border-style: solid;



    &:has(.event-style-primary) {
        background-color: $event-accent-primary;
        border-color: $event-accent-primary;
        border-left-color: $event-type-primary;

        &:has(.is-highlighted) {
            border-color: $event-type-primary;
        }
    }

    &:has(.event-style-secondary) {
        background-color: $event-accent-secondary;
        border-color: $event-accent-secondary;
        border-left-color: $event-type-secondary;

        &:has(.is-highlighted) {
            border-color: $event-type-secondary;
        }
    }

    &:has(.event-style-tertiary) {
        background-color: $event-accent-tertiary;
        border-color: $event-accent-tertiary;
        border-left-color: $event-type-tertiary;

        &:has(.is-highlighted) {
            border-color: $event-type-tertiary;
        }
    }

    &:has(.event-style-quaternary) {
        background-color: $event-accent-quaternary;
        border-color: $event-accent-quaternary;
        border-left-color: $event-type-quaternary;

        &:has(.is-highlighted) {
            border-color: $event-type-quaternary;
        }
    }

    &:has(.event-style-quinary) {
        background-color: $event-accent-quinary;
        border-color: $event-accent-quinary;
        border-left-color: $event-type-quinary;

        &:has(.is-highlighted) {
            border-color: $event-type-quinary;
        }
    }

    &:has(.event-style-senary) {
        background-color: $event-accent-senary;
        border-color: $event-accent-senary;
        border-left-color: $event-type-senary;

        &:has(.is-highlighted) {
            border-color: $event-type-senary;
        }
    }

    &:has(.event-style-septenary) {
        background-color: $event-accent-septenary;
        border-color: $event-accent-septenary;
        border-left-color: $event-type-septenary;

        &:has(.is-highlighted) {
            border-color: $event-type-septenary;
        }
    }

    &:has(.event-style-default) {
        background-color: $event-accent-default;
        border-color: $event-accent-default;
        border-left-color: $event-type-default;

        &:has(.is-highlighted) {
            border-color: $event-type-default;
        }
    }
}

.rbc-row-segment .rbc-event-content {
    white-space: normal;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    background: none;
}

.rbc-row-segment:not(:first-child) {
    padding: 0;
    padding-left: 5px;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background: none;

    .rbc-event-content {

        &:has(.event-style-primary) {
                background-color: $event-type-primary;
        }

        &:has(.event-style-secondary) {
                background-color: $event-type-secondary;
        }

        &:has(.event-style-tertiary) {
                background-color: $event-type-tertiary;
        }

        &:has(.event-style-quaternary) {

                background-color: $event-type-quaternary;
        }

        &:has(.event-style-quinary) {
                background-color: $event-type-quinary;
        }

        &:has(.event-style-senary) {
                background-color: $event-type-senary;
        }

        &:has(.event-style-septenary) {
                background-color: $event-type-septenary;
        }

        &:has(.event-style-default) {
                background-color: $event-type-default;
        }
    }
}

//override the scroll functionality for calendar view

.rbc-month-row {
    overflow: initial;
}

// react calendar custom view styles
.themed-toolbar {
    background-color: var(--jewel-primary);
    margin-bottom: $gap;

    .content-container {
        max-width: $calendar-max-width;

        @include media-min(x-large) {
            max-width: $max-width;
        }
    }

    .rbc-toolbar {
        flex-direction:row;
    }
}
.event-wrapper {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 3fr;
    gap: 2rem;

    @include media(large) {
        gap: $gap;
    }

    @include media(medium) {
        grid-template-columns: 1fr;
        gap: $gap-md;
    }
}
    // list view
.list-event {
    &-item {
        width: 100%;

        + .list-event-item {
            margin-top: $gap;
        }
    }

    &-card {
        &-content {
            cursor: pointer;
            background-color: $event-accent-default;
            padding: $gap-sm;
            margin-top: .375rem;
            display: flex;
            flex-flow: column;
            gap: .375rem;
            position: relative;
            border-style: solid;
            border-width: 2px;



            &.event-style-primary {
                background-color: $event-accent-primary;
                border-color: $event-accent-primary;
                border-left-color: $event-type-primary;


                &.is-highlighted {
                    border-color: $event-type-primary;
                }
            }

            &.event-style-secondary {
                background-color: $event-accent-secondary;
                border-color: $event-accent-secondary;
                border-left-color: $event-type-secondary;

                &.is-highlighted {
                    border-color: $event-type-secondary;
                }
            }

            &.event-style-tertiary {
                background-color: $event-accent-tertiary;
                border-color: $event-accent-tertiary;
                border-left-color: $event-type-tertiary;

                &.is-highlighted {
                    border-color: $event-type-tertiary;
                }
            }

            &.event-style-quaternary {
                background-color: $event-accent-quaternary;
                border-color: $event-accent-quaternary;
                border-left-color: $event-type-quaternary;

                &.is-highlighted {
                    border-color: $event-type-quaternary;
                }
            }

            &.event-style-quinary {
                background-color: $event-accent-quinary;
                border-color: $event-accent-quinary;
                border-left-color: $event-type-quinary;

                &.is-highlighted {
                    border-color: $event-type-quinary;
                }
            }

            &.event-style-senary {
                background-color: $event-accent-senary;
                border-color: $event-accent-senary;
                border-left-color: $event-type-senary;

                &.is-highlighted {
                    border-color: $event-type-senary;
                }
            }

            &.event-style-septenary {
                background-color: $event-accent-septenary;
                border-color: $event-accent-septenary;
                border-left-color: $event-type-septenary;

                &.is-highlighted {
                    border-color: $event-type-septenary;
                }
            }

            &.event-style-default {
                background-color: $event-accent-default;
                border-color: $event-accent-default;
                border-left-color: $event-type-default;

                &.is-highlighted {
                    border-color: $event-type-default;
                }
            }
        }

        .event {
            &-details {
                width: 100%;
                gap: $gap-sm;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }
        }
    }
}

// calendar modal

.event-calendar {
    &-wrapper {
        position: relative;
    }
    &-cta {
        margin-block:$space;
        a{
            @extend %btn-primary;
        }
    }
    &-page{
        &-heading {
            .rich-text {
                margin-block: $space;
            }
        }
    }
}

.event-view-modal {
    position: sticky;
    top: 0;
    background: var(--card-background);
    width: 100%;
    height: 0;
    z-index: 6;

    &-inner {
        display: flex;
        flex-flow: column;
        gap: 2rem;
        justify-content: center;
        align-items: flex-end;
        height: 100vh;
        background-color: var(--accordion-background);
        overflow-y:scroll;
    }

    .content-container {
        width: calc(75% - 2rem);
        background-color: $white;

        @include media(medium) {
            width: 100%;
        }
    }

    &-close {
        display: flex;
        justify-content: flex-end;
    }
}
.event-view-content {
    display: flex;
    flex-flow: row;
    margin-top: $space;
    @include media(small) {
        flex-wrap:wrap;
    }

    &-image {
        max-width: 30%;
        @include media(small) {
            max-width: 100%;
            width:100%;
        }
    }

    &-inner {
        flex-grow: 1;
        display: flex;
        margin-top: 0;
        gap:.5rem;

        .title {
            width: 100%;
        }

        .event-details {
            width: 100%;
        }

        .subtitle-content {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content:space-between;
            gap:1rem;
        }
    }
}