﻿/// <reference path="../entries/main">
td, %table-cell, th, %table-head {
    padding: $gap-sm;
    @include font-size($small-button-font-sizes);
    border: solid 6px;
    border-color: var(--background);
    min-width: 9rem;
    padding:$gap-sm;
}

th, %table-head {
    font-weight: $font-weight-bold;
    background-color: var(--accordion-background);
}
thead {
    td {
        @extend %table-head;
    }
}
table {
    border-width: 7px !important;
    border-color: var(--background);
    a {
        @extend %btn-primary;
    }

}