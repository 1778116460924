﻿/// <reference path="../entries/main">


.audio-player {
    &-container {
        --seek-before-width: 0%;
        --volume-before-width: 100%;
        --buffered-width: 0%;
        position: relative;
        width: 95%;
        max-width: 500px;
        background: var(--accordion-background);
        display: flex;
        flex-flow: row wrap;
        padding: $gap-sm;

        input[type="range"] {
            position: relative;
            -webkit-appearance: none;
            max-width: calc(70% - .5rem) !important;
            margin: 0 !important;
            padding: 0 !important;
            margin: 30px 2.5% 20px 2.5%;
            outline: none;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            width: 100%;
            height: 3px;
            cursor: pointer;
            background: linear-gradient(to right, $border-disabled var(--buffered-width), $border-disabled var(--buffered-width));
        }

        input[type="range"]::before {
            position: absolute;
            content: "";
            top: calc(50% - 1.5px);
            left: 0;
            width: var(--seek-before-width);
            height: 3px;
            background-color: var(--white);
            cursor: pointer;
        }

        input[type="range"]::-webkit-slider-thumb {
            position: relative;
            -webkit-appearance: none;
            box-sizing: content-box;
            border: 1px solid var(--white);
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: var(--white);
            cursor: pointer;
            margin: -7px 0 0 0;
        }

        input[type="range"]:active::-webkit-slider-thumb {
            transform: scale(1.2);
            background: var(--white);
        }

        input[type="range"]::-moz-range-track {
            width: 100%;
            height: 3px;
            cursor: pointer;
            background: linear-gradient(to right, $border-disabled var(--buffered-width), $border-disabled var(--buffered-width));
        }

        input[type="range"]::-moz-range-progress {
            background-color: var(--white);
        }

        input[type="range"]::-moz-focus-outer {
            border: 0;
        }

        input[type="range"]::-moz-range-thumb {
            box-sizing: content-box;
            border: 1px solid var(--white);
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
        }

        input[type="range"]:active::-moz-range-thumb {
            transform: scale(1.2);
            background: var(--white);
        }

        input[type="range"]::-ms-track {
            width: 100%;
            height: 3px;
            cursor: pointer;
            background: transparent;
            border: solid transparent;
            color: transparent;
        }

        input[type="range"]::-ms-fill-lower {
            background-color: var(--white);
        }

        input[type="range"]::-ms-fill-upper {
            background: linear-gradient(to right, $border-disabled var(--buffered-width), $border-disabled var(--buffered-width));
        }

        input[type="range"]::-ms-thumb {
            box-sizing: content-box;
            border: 1px solid #007db5;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
        }

        input[type="range"]:active::-ms-thumb {
            transform: scale(1.2);
            background: var(--white);
        }


        .time {
        }

        output {
            display: inline-block;
        }

        input[type="range"].volume-slider {
            margin: 10px 2.5%;
            width: 58%;

            &::-webkit-slider-runnable-track {
                background: $border-disabled;
            }

            &:-moz-range-track {
                background: $border-disabled;
            }

            &::-ms-fill-upper {
                background: $border-disabled;
            }

            &:before {
                width: var(--volume-before-width);
            }
        }

        button {
            @extend %un-btn;
            background-color: var(--white);
            width: 3.25rem;
            height: 3.25rem;
            text-align: center;
            max-width: calc(15% - .5rem) !important;

            &:hover, &:focus {
                background-color: var(--black);

                i {
                    color: var(--white);
                }
            }

            @include media(tablet) {
                width: 2.75rem;
                height: 2.75rem;
            }

            i {
                line-height: 3.25rem;
                font-size: $icon-font-size;
                color: var(--black);

                @include media(tablet) {
                    line-height: 2.75rem;
                    font-size: 1rem;
                }
            }
        }

        .mute-icon {

            .ph-speaker-x {
                display: none;
            }

            &.muted {
                .ph-speaker-high {
                    display: none;
                }

                .ph-speaker-x {
                    display: block;
                }
            }
        }

        .play-icon {
            .ph-play {
                display: none;
            }

            &.pause {
                .ph-play {
                    display: block;
                }

                .ph-pause {
                    display: none;
                }
            }
        }
    }

    &-bottom, &-top {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
        gap: $gap-xs;
    }

    &-bottom {
        margin-top: $gap-sm;
    }

    &-time {
        max-width: calc(15% - .5rem) !important;
    }
}


