﻿
/// <reference path="../entries/main">
@use "sass:math";

%standard-padding-h {
    @include responsive-context($standard-padding-map) {
        padding-inline: $responsive-variable;
    }
}

%standard-padding-v {
    @include responsive-context($standard-padding-map) {
        padding-block: $responsive-variable;
    }
}

%standard-margin-v {
    @include responsive-context($block-margin-map) {
        margin-block: $responsive-variable;
    }
}

%medium-margin-v {
    @include responsive-context($gutter-map-v) {
        margin-block: $responsive-variable;
    }
}

%responsive-column-gap {
    @include responsive-context($gutter-map-h) {
        column-gap: $responsive-variable;
    }
}

%responsive-grid-gap {
    @include responsive-context($gutter-map-v, $gutter-map-h) {
        gap: $responsive-variable $responsive-variable-2;
    }
}

%full-cover {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

// Pattern for a simple 2 column layout that has responsive margins and collapses to 1 column at the medium breakpoint
%simple-2-col-container {
    @include media-min(tablet) {
        display: flex;
        flex-wrap: wrap;
    }
}

%simple-2-col-item {
    @include responsive-context($grid-spacing-map, $block-margin-map) {
        margin-right: $responsive-variable;
        margin-bottom: $responsive-variable-2;
        width: calc(50% - #{math.div($responsive-variable, 2)});
    }

    @include media-min(tablet) {
        flex: 0 0 auto;

        &:nth-child(even) {
            margin-right: 0;
        }

        &:last-child, &:nth-last-child(2):nth-child(odd) {
            margin-bottom: 0;
        }
    }

    @include media(tablet) {
        width: 100%;
        margin-right: 0;
    }
}


%card-padding-h {
    @include responsive-context($gutter-map-h) {
        padding-inline: $responsive-variable;
    }
}

%card-padding-v {
    @include responsive-context($gutter-map-v) {
        padding-block: $responsive-variable;
    }
}

%card-padding {
    @include responsive-context($gutter-map-h, $gutter-map-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }
}

%max-width {
    @extend %standard-padding-h;
    max-width: $max-width;
    margin-inline: auto;
}

// Flexbox helpers
%flex-between {
    display: flex;
    justify-content: space-between;

    & > * {
        margin-top: 0;
        flex: 0 1 auto;

        &:first-child {
            margin-right: $space;
        }
    }
}

%flex-fiftyfifty {
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex: 0 1 auto;
        max-width: 100%;
        margin-top: 0;
        width: calc(50% - #{math.div($gap-md, 2)});
        margin-right: $gap-md;

        &:nth-child(even) {
            margin-right: 0;
        }

        &:nth-child(2) ~ * {
            margin-top: $gap-md;
        }

        @include media(mobile) {
            width: 100%;

            &:nth-child(n) {
                margin-right: 0;
            }

            &:first-child ~ * {
                margin-top: $gap-md;
            }
        }
    }

    & > span {
        display: inline-block;
    }
}
