﻿/// <reference path="../entries/main">
.author-block {
    

    display: flex;
    flex-flow: row wrap;
    gap: $gap-sm;

    &-image {
        max-width: 3.4375rem;

        img {
            width: 100%;
            border-radius: 3.4375rem;
        }
    }
}

.sponsor-block {
    display: flex;
    flex-flow: row wrap;
    gap: $gap;

    &-image {
        max-width: 2.625rem;

        img {
            width: 100%;
            border-radius: 2.625rem;
            aspect-ratio: 1;
        }
    }
}