﻿/// <reference path="../entries/main">
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -2.1875rem;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -7.75rem;
    }
}

@keyframes color {
    100%, 0% {
        stroke: currentColor;
    }

    40% {
        stroke: currentColor;
    }

    66% {
        stroke: currentColor;
    }

    80%, 90% {
        stroke: currentColor;
    }
}

.loader {
    margin-block:$gap-md;
    margin-inline:auto;
    &-circle {
        position: relative;
        margin: 0 auto;
        width: 6.25rem;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        .spin, .base {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .spin {
            z-index: 20;
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;

            .path {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                stroke-linecap: round;
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -17.75rem;
                stroke: currentColor;
            }
        }

        .base {
            z-index: 10;

            .path {
                stroke: currentColor;
            }
        }

        .spin {
            color: var(--light-tertiary);
        }

        .base {
            color: var(--classic-primary);
        }
    }
}

.button-loader {
    span {
        color: currentColor;
    }

    .loader {
        display: none;

        &.loading {
            display: block;
        }

        &-circle {
            width: $gap-sm;
            margin-left: $gap-sm;
        }

        &-circle .spin .path {
            stroke-width: 5;
        }

        &-circle .base .path {
            stroke-width: 5;
        }
    }
}
