﻿/// <reference path="../../entries/main">

%sister-nav-button {
    display: flex;
    align-items: center;
    gap: $sliver;
    padding-inline: $sliver;
    &[aria-expanded="true"] {
        i{
            transform: rotate(180deg);

        }

    }
}
.utility-nav {
    &-links {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: $gap-sm;
        align-items: center;

        .quick-nav {
            @include media(medium) {
                display: none;
            }
        }
    }

    &-tablet {
        &-container {
            display: none;
            position: relative;

            @include media-range(small, medium) {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: $gap-sm;
                margin-bottom: $space;
            }
        }
    }

    &-link {
        text-decoration: none;
        font-weight: 700;
        @extend %regular;
        padding-block: $sliver;
        display: flex;
        flex-flow: row;
        gap: $sliver;
        align-items: center;

        > svg {
            max-width: 20px;
            max-height: 20px;
        }



        &-sister {
            color: var(--dark-primary);
            position: relative;
            margin-left: $space;



            button {
                @extend %sister-nav-button;
                color: var(--dark-primary);
            }

            @include media(medium) {
                display: none;
            }

            &-tablet {
                display: none;
                color: var(--dark-primary);

                @include media-range(small, medium) {
                    display: flex;
                    justify-content: flex-end;
                }

                button {
                    @extend %sister-nav-button;
                    color: var(--dark-primary);
                }
            }

            &-mobile {
                display: none;
                color: var(--jewel-primary);

                @include media(small) {
                    display: block;
                }

                button {
                    @extend %sister-nav-button;
                    color: var(--jewel-primary);
                }
            }
        }
    }
}
