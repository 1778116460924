﻿/// <reference path="../entries/main">

.bio {
    &-block {
        &-content {
            gap: $gap-sm;
            display: flex;
            flex-flow: row wrap;
            gap: $gap-md;

            @include responsive-context($gutter-map-v) {
                margin-top: $responsive-variable;
            }

            @include media-min(small) {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: $gap-md;
            }

            @include media-min(medium) {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                gap: $gap;
            }
        }

        &-item {
            text-align: center;

            @include media(small) {
                width: 100%;
            }
        }

        &-image {
            width: 100%;

            img {
                width: auto;
                display: block;
                object-fit: contain;
                margin-inline: auto;
                object-position: 50% 50%;
                object-position: var(--focal-point-h) var(--focal-point-v);
            }
        }

        &-button {
            &-container {
                display: flex;
                justify-content: center;

                @include responsive-context($gutter-map-v) {
                    margin-top: $responsive-variable;
                }
            }
        }
    }
}
