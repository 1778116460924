﻿/// <reference path="../entries/main">
@use "sass:math";

.listing {
    &-item {
        padding: $gap-md-lg;
        flex-grow: 1;
        min-width: 17.5rem;
        background-color: var(--card-background);
        border: solid 1px var(--card-border);
        display: flex;
        flex-flow: column;
        gap: $gap-xs;
        width: calc(25% - #{$gap-xs * 4});

        @include media(small) {
            width: 100%;
        }

        &-category {
            @extend %chip-outline;
            display: inline-block;

            + .listing-item-category {
                display: none;
            }
        }

        &-categories {
            display: flex;
            flex-flow: row wrap;
            gap: $gap-xs;
        }

        &-info {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: $gap-sm;
        }
    }

    &-container-block-content {
        display: flex;
        flex-flow: row wrap;

        @include responsive-context($gutter-map-v) {
            gap: $responsive-variable;
            margin-top: $responsive-variable;
        }
    }
}