﻿/// <reference path="../entries/main">

.component-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;

    &-description {
        margin-top: $gap-md;
    }

    &-subtitle {
        @extend %chip-subtitle;
    }

    &-wrapper {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        width: 100%;

        @include responsive-context($component-header-map) {
            gap: $responsive-variable;
        }

        @include media-min(large) {
            max-width: 60%;
        }
    }

    &.right {
        @include media-min(large) {
            justify-content: flex-end;
        }



        .component-header-wrapper {
            width: auto;
        }
    }

    &.center {
        text-align: center;
        justify-content: center;

        .component-header-wrapper {
            flex-flow: column;
            align-items: center;
        }
    }
    &-title {
        @include media(mobile) {
            overflow-wrap:anywhere;
        }
    }
}
.static-header {
    position: relative;

    .svg-background {
        z-index: 0;
    }

    .content-container {
        z-index: 2;
        position: relative;
    }
}