﻿/// <reference path="../entries/main">
.upcoming-events {
    &-filters {
        margin-block: $gap;
        display: flex;
        flex-flow: row wrap;
        gap: $gap-sm;



        &-item {
            display: flex;
            flex-flow: row wrap;
            gap: $sliver;

            &[data-filter-state="active"] {
                background-color: var(--inactive-button);

                i {
                    display: none;
                }
            }
        }
    }

    &-item {
        display: grid;
        grid-template-columns: 1fr 4fr;


        @include responsive-context($gutter-map-h) {
            padding-block: $responsive-variable;
            gap: $responsive-variable;
        }

        border-top: solid 2px var(--scrollbar-background);

        @include media(medium) {
            display: flex;
            flex-flow: column;
        }

        &.hide {
            display: none;
        }

        &-container {
            &-center {
                display: flex;
                flex-flow: row;
                width: 100%;

                @include responsive-context($gutter-map-h) {
                    gap: $responsive-variable;
                }

                @include media(medium) {
                    flex-wrap: wrap;
                }
            }

            &-left {
                display: flex;
                flex-flow: column;
                justify-content: space-between;

                @include media(medium) {
                    flex-flow: row;
                    gap:$gap-sm;
                }
            }
        }

        &-image {
            max-width: 55%;

            @include media(medium) {
                max-width: 100%;
                width: 100%;
            }

            img {
                width: 100%;
            }
        }

        &-subtitle {
            text-transform: uppercase;
        }

        &-content {
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            gap: $gap-sm;
            justify-content: space-between;

            &-header, &-lower {
                display: flex;
                flex-flow: column;
                gap: $gap-sm;
            }

            &-info {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                justify-content: space-between;
                gap: $gap-sm;
            }
        }
    }
}
