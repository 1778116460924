﻿/// <reference path="../entries/main">
.main-content > .row, .tabs-panel, .content-detail-page-main-content, .event-detail-top-content-area, .event-detail-bottom-content-area, .article-detail-content-bottom > .row, .article-detail-content-top > .row, .directory-top-content, .directory-bottom-content {
    > .wysiwyg-block, > form {
        @extend %max-width;

        @include responsive-context($standard-padding-map-v) {
            padding-block: $responsive-variable;
        }
    }
}
.wysiwyg-block {
    .rich-text {
        &:has(table) {
            max-width: 100%;
            overflow-x: scroll;

            table {
                margin-bottom: $gap-md;
            }
        }
    }


    &-full {
        .row, .wysiwyg-block {
            width: 100%
        }
    }

    .rich-text {
        ul, ol {
            margin-left: $gap-sm;
        }

        .btn-primary, .btn-secondary {
            + .btn-primary, + .btn-secondary {
                margin-left: $gap-md;

                @include media(small) {
                    margin-top: $gap-sm;
                }
            }
        }
    }
}