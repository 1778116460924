﻿/// <reference path="../entries/main">
.media-carousel {
    &-block {
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column;
        gap: $gap-sm;
        flex-grow: 1;
        max-width: 85%;

        @include media-min(large) {
            width: 33%;
        }

        &-video {
            width: 100%;

            iframe {
                aspect-ratio: 16/9;
                height:auto;
                width: 100%;
            }
        }

        &-image-audio, &-video {
            background-color: var(--carousel-background);
            display: flex;
            flex-flow: row wrap;
            place-content: center;
            flex-grow: 1;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }

    &-container-block {
        .content-container {

            @include responsive-context($null-map) {
                padding-bottom: $responsive-variable;
            }

            @include responsive-context($small-gutter-map-v) {
                margin-bottom: $responsive-variable;
            }
        }

        .swiper-navigation {
            &-container {
                @extend %max-width;

                @include responsive-context($small-gutter-map-v) {
                    padding-top: $responsive-variable;
                }

                @include responsive-context($standard-padding-map-v) {
                    padding-bottom: $responsive-variable;
                }
            }
        }
    }
}
.swiper-navigation {

    &-buttons {
        display: flex;
        flex-flow: row wrap;
        gap: $gap-sm;

        @include media(small) {
            justify-content: center;
            width: 100%;
        }
    }

    &-container {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include media(small) {
            gap: $gap-sm;
        }
    }
}

.swiper-button-next, .swiper-button-prev {
    position: relative;
    left: auto;
    right: auto;
    width: auto;
    height: auto;
    display: flex;
    margin-top: 0;
    font-size: $icon-font-size;
    line-height: $icon-font-size;
    background-color: var(--accordion-icon-background);
    color: var(--accordion-icon-color);
    padding: $sliver $gap-xs;

    &:after {
        content: none;
        display: none;
    }
}
.swiper {
    .swiper-pagination {
        flex-grow: 1;
        display: block;
        position: relative;
        width: auto;
        bottom: auto;
        top: auto;
        left: auto;
        --swiper-pagination-bullet-inactive-color:var(--button-disabled-background);
        &-bullet {
            width: 2rem;
            height: 3px;
            border-radius: 0;
        }
    }
}
