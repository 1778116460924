﻿/// <reference path="../../entries/main">

::-webkit-scrollbar {
    width: 10px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background-color:$input-focus;
   /// background-color: var(--scrollbar-background);
}

::-webkit-scrollbar-thumb {
    background-color: $black;
    ///background-color: var(--scrollbar-foreground);
    border-radius: 50px;
}