﻿/// <reference path="../entries/main">
$caret-width: 12px;
$box-offset: .15rem;
.tour {
    &-element {
        position: absolute;
        background-color: var(--jewel-primary);
        padding: .5rem .56rem .44rem;
        display: flex;
        align-items: flex-start;
        flex-flow: row;
        gap: $space;
        width: max-content;
        max-width: 50vw;
        color: $black;
        @include font-size($x-small-font-sizes);
        font-weight: $font-weight-bold;
        font-family: $body-font;

        @include media(small) {
            width: auto;
            max-width: 80vw;
        }

        &.top {
            bottom: calc(100% - #{$box-offset});

            .tour-element {
                &-caret {
                    top: calc(100% - 1px);
                    transform: rotate(180deg);
                }
            }
        }

        &.bottom {
            top: calc(100% - #{$box-offset});

            .tour-element {
                &-caret {
                    bottom: calc(100% - 1px);
                }
            }
        }

        &.side {
            .tour-element {
                &-caret {
                    left: $gap-sm;
                }
            }
        }

        &.middle {
            left: 50%;
            transform: translateX(-50%);

            .tour-element {
                &-caret {
                    left: calc(50% - 6px);
                }
            }
        }

        button {
            @extend %un-button;
            color: $black;
        }

        &-caret {
            position: absolute;
            width: $caret-width;
        }
    }

    &-wrapper {
        width: 100%;
        height: 0;
        position: relative;
        z-index:998; //just below interstitials
    }
}
